.wrapperForExecution {
  padding: 5px;
  .contentDataStyle_custom_fields {
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      color: #3c3838;
      font-size: 14px;
      font-family: "poppins-Medium";
      position: absolute;
      left: -10px;
    }
    
  }
  .customAccordian {
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px #888888;
    border-radius: 5px;
    padding: 10px;
    font-family: "Poppins-Medium";
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
    overflow-x: hidden;
    .css-yw020d-MuiAccordionSummary-expandIconWrapper {
      position: absolute;
      top: -30px;
    }
    .css-1fx8m19{
      position: absolute;
      top: -30px;

    }
    .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
      margin: 0px;
    }
    .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
      box-shadow: none;
      background-color: none;
      position: unset;
    }
    .iconGroups {
      position: relative;
      // left: 760px;
      top: 41px;
      z-index: 1;
    }
    .testStepTable {
      border: 0.05rem solid rgba(197, 197, 197, 0.486);
      border-spacing: 0rem;
    }
    .exe_header {
      position: relative;
      left: 11px;
      font-size: 16px;
      font-family: "poppins-Medium";
    }
    .testcase_exe_header{
      position: relative;
      left: 16px;
      font-size: 16px;
      font-family: "poppins-Medium";
    }
  }
  .platFormDetailsDiv {
    width: 100%;
    padding: 18px;
    .label {
      color: #434db8;
      font-size: 14px;
      font-family: "poppins-Medium";
    }
    .value {
      color: #3c3838;
      font-size: 14px;
      font-family: "poppins-Medium";
    }
  }
  .labelInputs {
    .custom_labels {
      color: #434db8;
      font-size: 14px;
      font-family: "poppins-Medium";
    }
  }
  .responsiveTestDetails {
    .accordionDetails {
      width: 100%;
      position: relative;
      left: -16px;
      height: auto;
      overflow: hidden;
    }
    .testcase_accordionDetails{
      width: 100%;
      position: relative;
      left: -12px;
      height: auto;
      overflow: hidden;
    }
  }
  .tc_result_executionDetails {
    .css-wmy1p7-ReactDropdownSelect {
      border: none !important;
    }
    .dropdown-styles_select-dropdown__RDsi {
      border: none !important;
    }
    div div[aria-label="Dropdown select"] {
      min-height: 0px;
      height: 25px;
      width: 106px;
    }
  }
}
.noExeutionsMsg{
  font-size: 20px;
  font-family: OpenSans-SemiBold;
  color: #808080;
}
.paginationContainer{
  max-height: 495px;
  overflow: scroll;
}
.css-67l5gl {
  box-shadow: none !important;
}
.noExeutionsreportsMsg{
  width: 54%;
  margin: 10% 39%;
  .text_msg{
    font-size: 14px;
  font-family: "Poppins-Medium";
  color: #333333;
  margin-left: -3%;
  }
}
.execution_expand_module_msg{
  font-size: 14px;
  font-family: "Poppins-Medium";
  color: #333333;
}
.exectionlist_dropdown{
  position: absolute;
    top: 13%;
    right: 0%;
    .filter_icon{
      svg{
        path{
          stroke:#434db8
        }
        
      }
    }
}
.exectionlist_filter_container{
  position: absolute;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  height: 18%;
  width: 245px;
  border-radius: 10px;
  z-index: 1;
  padding: 14px;
  &.popup_modal{
    left: 76%;
    bottom:75%;
  }
  &.screen_modal{
    left: 80%;
    bottom: 64%;
  }
  .selected_value{
    font-family: "Poppins-SemiBold";
    color: #434db8;
    font-size: 14px;
  }
  .unselected_value{
    font-family: "Poppins-SemiBold";
    color: #646566;
    font-size: 14px;
  }
  .filter_divider_line{
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 16px;
    margin-bottom: 11px;
  }
  .fliter_btn{
    display: flex;
    justify-content: center;
    .apply_btn{
      border: 1px solid;
      font-size: 12px;
      width: 66px;
      height: 24px;
      font-family: 'Poppins-Semibold';
      color: #434db8;
    }
  }
  
}
.executionListPopup{
  .header{
    height: 38px;
    background: #434db8;
    padding-top: 5px;
    color: #ffffff;
    border-top-left-radius: 15px;
    .heading{
    font-family: "Poppins-SemiBold";
    font-size: 16px;
    }
    .close_btn{
      position: absolute;
      right: 0.5%;
    }
    .filter_icon{
      position: absolute;
      right: 5%;
      background: #434db8;
      width: 27px;
      height: 26px;
      border-radius: 15px;
      padding: 4px 5px;
      cursor: pointer;
      svg{
        height: 18px;
        
        path{
           stroke:#ffffff
        }
      }
      &:hover{
        background: #ffffff;
        svg{
          height: 18px;
          
          path{
             stroke:#434db8
          }
        }
      }
    }
  }
  .body{
      height: 90vh;
      .paginationContainer{
        max-height: 89vh;
        overflow: scroll;
      }
   
  }
}