// screen size

// $sm:780px;
// $md:1024px;
// $lg:1280px;  //no need to add media qry for this
// $xl:1536px;
// $xxl:1920px;
$xs: 0px;
$sm: 780px;
$md: 1024px;
$lg: 1280px; //no need to add media qry for this
$xl: 1536px;
$xxl: 1920px;

//color paletts

$blue :#2b71f7;
$blue_ultra_light: #8ba4e3;
$light_blue : #5580ec;
$blue_dark :#434DB8;
$tag_blue:#436BD0;
$whitish_blue:#d7dae1;
$primary : #3A62C9;

$skyblue:#d8d9db;
$skyblue_light :#F6F7F9;
$skyblue_dark :#F0F3F9;

$grey :#525252;
$grey_dark:#727171;
$grey_darker :#555555;
$grey_darkest:#3C3838;
$grey_light:#D2D2D2;
$grey_light_white:#a9a9a9;

$white:#EEEDED;
$white_original:#FFFFFF;
$grey_white:#e1dfdf;
$white_light :#FBFCFD;
$white_dark:#E5ECFC;
$white_accordion:#F8F9F9;
$light_grey_white:#dad5d5;

$black:#0A0A0A;
$black_original:#000;
$shadow_black:#00000029;
$shodow_light_black :#0000001a;

$green:#79B62F;
$red:#C50303;
$red_light:#db2222;
$yellow:#D9A903;
$red_dark:#FF0000;
$red_darkest:#EA0322;
$alice_blue:#F0F8FF;
$error_red:#f44336;
$warning : #E87807;
$border-gray: #989797;
$border-muted: #00000029;
$sky-blue-light: #fbfcfd;
$danger: #ea0322;
$dark: #3c3838;
$muted: #727171;


//Table colors
$clickable_feild_color :$blue;
$non_clickable_feild_color :$grey_dark;
$modal_non_clickable_feild_color :$grey_darkest;

// btn colors

$submit_btn_background_color:$blue_dark;
$submit_btn_border_color:$blue_dark;
$submit_btn_font_color:$skyblue_light;

// ---------------------------------------
$cancel_btn_background_color:$grey_dark;
$cancel_btn_border_color:$white;
$cancel_btn_font_color:$white;

// ---------------------------------------

$clear_btn_border_color:$grey;
$clear_btn_font_color:$grey_dark;


// tab colos

$tab_font_color :$blue_dark;


//header
$left_nav_color:$grey_dark;
$left_nav_color_dark:$grey_darker;


//pagination
$current_page_num_box_color:$light_blue;
$current_page_count_color:$white_original;

//content area header 
$content_area_header_font_color:$black_original;

//radio btn style

$radio_btn_label_color:$grey_dark;
$selected_radio_label_color:$grey_darkest;

//result status colors
$result_passed_color:$green;
$result_failed_color:$red;
$result_warning_color:$yellow;
$result_skipped_color:$grey_dark;


