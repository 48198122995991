.lds-roller {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgb(12, 131, 230);
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
.table-search {
  width: 25px;
  height: 25px;
  border-radius: 30px;
  border-style: none;
  background-color: #ecedf8;
  outline: none;
  transition-duration: 500ms;
  padding: 16px;
  // position: absolute;
  // top: 60px;
  // right: 10px;
  cursor: pointer;
  background-position-y: center;
  background-size: 32px 37px;
  background-image: url('../../assets/searchIcon.svg');
  background-repeat: no-repeat;
}
.expanded-table-search {
  width: 200px;
  // right: 10px;
  // width: 25px;
  height: 25px;
  border-radius: 30px;
  border-style: none;
  margin-left: -164px;
  margin-top: 2px;
  background-color: #ecedf8;
  outline: none;
  transition-duration: 500ms;
  padding: 16px;
  background-position-y: center;
  background-size: 32px 37px;
  background-image: url('../../assets/searchIcon.svg');
  background-repeat: no-repeat;
  font-size: 12px;
  font-family: 'Poppins-Regular';
  cursor: text;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #ecedf8;
  border: 1px solid #4169e1;
  // color: rgb(46, 135, 177);
}
.search_icon_close {
  float: left;
  margin-top: 8px;
  margin-right: -30px;

  // margin-top: -30px;
  background: #4169e1;
  border-radius: 12px;
  position: relative !important;
  z-index: 8;
  color: #fff;
  // transition-duration: 300ms;
  display: block;
  font-size: 12px;
  height: 22px;
  width: 22px;
  svg {
    height: 17px;
    width: 22px;
  }
}
.breadcrumbsclass{
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #434DB8;
  opacity: 1;
  font-family: "Poppins-Regular";
  &:hover{
    border-bottom: 1px solid #434DB8;
  }
}
.selectedLink{
  text-align: left;
  font-size: 12px !important;
  letter-spacing: 0px !important;
  color: #808080;
  opacity: 1;
  font-family: "Poppins-Regular" !important;
}

//new loader
.spinner {
  /* Spinner size and color */
  width: 1.5rem;
  height: 1.5rem;
  border-top-color: #444;
  border-left-color: #444;
  /* Additional spinner styles */
   animation: spinner 400ms linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;  
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}
/* Animation styles */
@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.spinner-blue {
  width:3rem;
  height: 3rem;
  border-top-color: #09d;
  border-left-color: #09d;
}