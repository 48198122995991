// @import "@styles/variables";
$danger: #ea0322;
$muted: #727171;
$labels:#3C3838;
.label {
  color: $labels;
  font-size: 12px;
  font-family: "Poppins-Medium";
  &.required {
    &:before {
      content: "*";
      color: $danger;
      margin-right: 4px;
    }
  }

  &.error {
    color: $danger;
  
  }
}


.select-box-wrapper {
  min-height: 77px;
  :global {
    .MuiInputBase-root {
      &.Mui-disabled {
        &::before {
          border-style: solid;
        }
      }

      .MuiSelect-select {
        // font-size: 12px;
        // padding-top: 2px;
        // padding-bottom: 0;
        // font-family:Poppins-Regular;
        font-size: 12px;
        padding-top: 8px;
        padding-bottom: 0;
        font-family: "Poppins-Regular";
        height: 1.8rem;
      }
    }
  }
}

.select-box-menu-list-wrapper {
  :global {
    max-height: 300px;
    .MuiMenuItem-root {
      font-size: 14px;
      font-family:Poppins-Regular;
      display: flex;
      justify-content: flex-start;
      padding: 6px 16px;
    }
  }
}

.labeled-input {
  min-height: 77px;
  .form-control {
    :global {
      .MuiInputBase-root {
        .MuiInputBase-input {
          font-size: 14px !important;
          border-bottom: 0 !important;
          min-height: 21px;
        }

        &.Mui-disabled {
          &::before {
            border-style: solid;
          }
        }
      }
    }
  }
}

.number-input-control {
  :global {
    .MuiInput-input {
      &:focus {
        box-shadow: none;
      }

      &::-webkit-inner-spin-button {
        opacity: 1;
        -webkit-appearance: none;
        background: url("../../../assets/number-input-arrows.png") no-repeat 50% 50%;
        padding: 6px;
      }

      &:disabled {
        opacity: 0.8;
      }
    }
  }
}
