.integrationHome-container {
    .integrationHome-header {
        height: 50px;
        background: #ffffff;
        padding-top: 8px;
        font-family: "Poppins-SemiBold";
        font-size: 18px;
        color: #3c3838;
    }

    .integrationHome-body {
        margin: 6px;
        height: 83vh;
        overflow: auto;
        padding-bottom: 5px;

        .integrationHome-cards {
            height: 150px;
            background: #ffffff;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1019607843);
            border-radius: 5px;
            padding: 20px;
            font-family: "Poppins-Medium";
            font-size: 15px;
            margin: 5px;

            .icon_container {
                background: #ffffff;
                width: 100px;
                border-radius: 15px;
                cursor: pointer;

                &:hover {
                    background-color: #F0F3F9;
                }

                .jira_icon {
                    padding-top: 12px;

                    img {
                        height: 60px;
                    }

                }

                .icon_text {
                    font-family: "Poppins-Regular";
                    font-size: 14px;
                    text-align: center;
                    letter-spacing: 0;
                    color: #3c3838;
                    opacity: 1;
                }
            }


        }
    }
}

.integration-Container {
    .integration-Container-header {
        height: 50px;
        background: #ffffff;
        padding-top: 8px;

        .heading-text {
            font-family: "Poppins-SemiBold";
            font-size: 18px;
            color: #3c3838;
        }


    }

    .add_instance_btn {
        width: 121px;
        margin-left: 31%;
        background: #ecedf8;
        font-family: "Poppins-Medium";
        font-size: 14px;
        color: #434db8;
        border-radius: 21px;
        font-size: 12px;
        // height: 41px;
        height: 33px;
        border-bottom: 1px solid #ecedf8;
        border-top: 1px solid #ecedf8;
        border-right: 1px solid #ecedf8;

        &:hover {
            border-bottom: 1px solid #434db8;
            border-top: 1px solid #434db8;
            border-right: 1px solid #434db8;
        }

        .add_instance_btn_icon {
            svg {
                height: 31px;
                width: 31px;
            }
        }

        .instance_text {
            font-family: "Poppins-Medium";
            color: #434db8;
            font-size: 14px;
            padding-left: 20px;
            padding-top: 6px
        }
    }

    .integration-Container-body {
        .nodata_quick_msg {
            margin-top: 20%;

            .quick_msg_btn {
                margin-left: -27px;
                margin-right: 40px;
            }

            .text_msg {
                font-size: 15px;
                color: #3c3838;
                font-family: "Poppins-SemiBold";
                padding-top: 6px;
            }

        }
    }
}

.create_instance {
    height: 100vh;

    .header {
        height: 38px;
        background: #434db8;
        padding-top: 5px;
        color: #ffffff;
        // border-radius: 5px 5px 0px 0px;
        border-top-left-radius: 15px;

        .heading {
            font-family: "Poppins-SemiBold";
            font-size: 16px;
        }
    }

    .body {
        // height: 600px;
        height: 87.5vh;
        background: #ffffff;
        overflow-y: scroll;
    }

    .footer {
        height: 50px;
        background: #fbfcfd;

        // border-radius: 0px 0px 5px 5px;
        padding-top: 11px;

        .btn_close {
            border-radius: 2px;
            background: #707070;
            height: 30px;
            width: 70px;
            text-align: center;
            color: white;
            font-family: OpenSans-Regular;
            font-size: 15px;
        }
    }
}

.encode{
    -webkit-text-security: disc;
    &.apitoken_trimmer{
        overflow: hidden;
        white-space: nowrap;
    }

}
#apiToken{
    -webkit-text-security: disc;
}
.jira-defect-loader{
    position: absolute;
    top:45%;
    left: 50%;
}
.apiToken_values{
    font-family: "Poppins-Medium";
    font-size: 12px;
    color: #525252;
    -webkit-text-security: disc;
    overflow: hidden;
    white-space: nowrap;
}
