.warning-popup {
  background: #fff;
  cursor: default;
  .warning-icon {
    // padding: 9px;
    margin-left: 24px;
    margin-top: 11px;
  }
  .warning_text {
    font-size: 26px;
    font-family: "Poppins-Medium";
    color: #ffc600;
  }
  .delete_text {
    font-size: 26px;
    font-family: "Poppins-Medium";
    color: #3c3838;
  }
  .user_name {
    font-size: 16px;
    font-family: "Poppins-Regular";
    color: #858585;
  }
  .message {
    font-size: 16px;
    font-family: "Poppins-Regular";
    color: #858585;
  }
  .project_name {
    font-size: 16px;
    font-family: "Poppins-SemiBold";
    color: #858585;
  }
}
.warning_popup_btn {
  background: #fbfcfd;
  height: 50px;
  font-size: 14px;
  color: #ffffff;
  font-family: "Montserrat-Medium";
  .btn_close {
    background: gray;
    &:hover{
      color: black;
      opacity: 0.92;
    }
  }
  .btn_back {
    background: #434db8;
  }
}
.user-details_footer{
  background: #fbfcfd;
  height: 46px;
  font-size: 14px;
  color: #ffffff;
  font-family: "Montserrat-Medium";
  border-bottom-left-radius: 15px;
  margin-top: -1%;
  .btn_close {
    background: gray;
    &:hover{
      color: black;
      opacity: 0.92;
    }
  }
  .btn_back {
    background: #434db8;
  }

}
.table_container {
  height: 85vh;
  // height: 600px;
  background: #fff;
  .defect-table-row {
    &:hover {
      background: #f5f6fc;
      cursor: pointer;
    }
  }
  tbody {
    // height: 550px;
    height: 77vh;
  }
}
.left-arrow-icon {
  svg {
    height: 26px;
    width: 26px;
    fill: white;
  }
}
.toast {
  position: absolute;
  bottom: 25px;
  right: 30px;
  border-radius: 10px;
  background: #fff;
  padding: 20px 35px 20px 25px;
  overflow: hidden;
  transform: translateX(calc(100% + 30px));
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
  z-index: 10000;
  &.active {
    transform: translateX(0%);
  }
  &.toast-info {
    box-shadow: 0px 0px 12px #434db8;
  }
  &.toast-success {
    box-shadow: 0px 0px 12px #5ca700;
  }
  &.toast-warning {
    box-shadow: 0px 0px 12px #ffc600;
  }
  &.toast-danger {
    box-shadow: 0px 0px 12px #ff2e2e;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 5px;
    cursor: pointer;
    &.success{
      color: #5ca700;
    }
    &.warning{
      color: #ffc600;
    }
    &.info{
      color: #434db8;
    }
    &.danger{
      color: #ff2e2e;
    }
  }
  .toast-content {
    display: flex;
    align-items: center;
    .toast-icon {
      background: #ecedf8;
      height: 54px;
      width: 52px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      .success,
      .warning,
      .info {
        svg {
          height: 28px;
          width: 51px;
        }
      }
      .danger {
        background-color: #ff2e2e;
      }

      .check {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        min-width: 35px;
        color: #ecedf8;
        font-size: 20px;
        border-radius: 20%;
      }
    }
    .message {
      display: flex;
      flex-direction: column;
      margin: 0 20px;
      .text-2 {
        font-size: 14px;
        color: #858585;
        font-family: "Poppins-Regular";
      }
      .success {
        font-size: 16px;
        font-family: "Poppins-Semibold";
        color: #5ca700;
      }
      .warning {
        font-size: 16px;
        font-family: "Poppins-Semibold";
        color: #ffc600;
      }
      .danger {
        font-size: 16px;
        font-family: "Poppins-Semibold";
        color: #ff2e2e;
      }
      .info {
        font-size: 16px;
        font-family: "Poppins-Semibold";
        color: #434db8;
      }
    }
  }
  .progress,
  .successprogress,
  .warningprogress,
  .dangerprogress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
    }
    &.active {
      &::before {
        animation: progress 5s linear reverse;
      }
    }
  }
  .progress {
    &::before {
      background-color: #434db8;
    }
  }
  .successprogress {
    &::before {
      background-color: #5ca700;
    }
  }
  .warningprogress {
    &::before {
      background-color: #ffc600;
    }
  }
  .dangerprogress {
    &::before {
      background-color: #ff2e2e;
    }
  }
}
@keyframes progress {
  100% {
    right: 100%;
  }
}
@keyframes successprogress {
  100% {
    right: 100%;
  }
}
@keyframes warningprogress {
  100% {
    right: 100%;
  }
}

@keyframes dangerprogress {
  100% {
    right: 100%;
  }
}

.datepicker-input{
  border: 1px solid #A6A6A65E;
  &:hover {
    border: 1px solid #646566;
  }
}
.datepicker {
  width: 100%;
  outline: none;
  height: 33px;
  border-right: 1px solid #A6A6A65E;
  margin-left: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 6px;
  
}

.calendar-icon {
  padding: 6px;
  background: white;
  border-bottom-right-radius: 4px;
  height: 33px;
  border-top-right-radius: 4px;
  svg {
    height: 20px;
    width: 30px;
  }
}

.modal_clos_btn {
  width: 24px;
  border: 2px solid #434db8;
  border-radius: 19px;
  height: 25px;
  // margin: -2px;
 
  &:hover {
    border: 2px solid white;
    cursor: pointer;
    background-color: white;
    color:#434db8
  }
}
.ReactModal__Overlay{
  z-index:1201;
 
  
  
  
}
.roundedText{
  height: 25px;
  width: 25px;
background: #434db8;

text-align: center;
margin: 8px;
}
.historyDetails{
  font-family:Poppins-Regular;
  margin: 8px;
  font-size: 14px;
  letter-spacing: 0px;
color: #3C3838;
opacity: 1;
flex-wrap: wrap;

}



.roundedText:hover .arrow_box{
  display: block;
}
.arrow_box {
  position: relative;
  display: none;
  background: #ffffff;
  padding: 15px;
  border: 1px solid #dddddd;
  margin-top: 20px;
  width: 184px;
    height: 102px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  left:-5px;
  font-size: 12px;
  font-family: Poppins-Regular;
  border-radius: 5px;
  z-index: 1;
  
}

.arrow_box::before,
.arrow_box::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 9px;
  border: 11px solid transparent;
  border-bottom-color: #dddddd;
}

.arrow_box::after {
  left: 9px;
  border: 10px solid transparent;
  border-bottom-color: #ffffff;
}
.noDefects{
  top: 55%;
  left: 57%;
  transform: translate3d(-50%,-50%, 0);
  position: absolute;
 
}
.no_data_text{
 
    font-size: 27px;
    color: #434DB8;
    font-family: "Poppins-Medium";
    position: absolute;
    bottom: 27%;
    left:49.5%;
    &.testcase_text{
      left:48%;
    }
    &.defect_text{
      left:44%;
    }

}
.no_relevant_data{
  top: 55%;
  left: 57%;
  transform: translate3d(-50%,-50%, 0);
  position: absolute;
  .text_msg{
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    margin-left: -9%;
    &.platform_msg{
      margin-left: -15%;
    }
  }
}
.selectDefectDropDown{
  .css-wmy1p7-ReactDropdownSelect{
    width: 432px;
  height: 46px;
  border-radius: 5px;
  box-shadow: 0px 0px 6px #00000000;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  opacity: 1;
  .css-rcmcfx{
    &:hover {
      background: #ECECEC;
      cursor: pointer;
      
    }

  }

  }
 
  
}
.platform_details{
  font-size: 17px;
  color:#3C3838;
  font-family: "Poppins-Semibold";
}
.platform_type_header{
  font-size: 12px;
  color:#3C3838;
  font-family: "Poppins-Semibold";
}
.approve_state{
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #5ea606;
}
.rejected_state{
  font-family: "Poppins-Medium";
    font-size: 12px;
    color: #c50303;
}
.inreview_state{
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #ffa500;
}

.rejectedUsertable_container {
  background: #fff;
  max-height: 500px;
  overflow: auto;
  .defect-table-row {
    &:hover {
      background: #f5f6fc;
      cursor: pointer;
    }
  }
  tbody {
    height: 71vh;
  }
  tr {
    margin-left: 10px;
  }
}
.noExecutionResult{
  top: 55%;
  left: 52%;
  transform: translate3d(-50%,-50%, 0);
  position: absolute;
}