.NDAform-container {
    .NDAform-header {
        height: 50px;
        background: #ffffff;
        padding-top: 8px;

        .header-text {
            font-family: "Poppins-SemiBold";
            font-size: 18px;
            color: #3c3838;
        }

        .edit-nda-form-btn {
            background: #ecedf8;
            color: #434db8;
            border-radius: 21px;
            height: 33px;
            border-bottom: 1px solid #ecedf8;
            border-top: 1px solid #ecedf8;
            border-right: 1px solid #ecedf8;
            cursor: pointer;

            &:hover {
                border-bottom: 1px solid #434db8;
                border-top: 1px solid #434db8;
                border-right: 1px solid #434db8;
            }
        }

        .edit_icon {
            border-radius: 21px;
            background: #434db8;
            width: 34px;
            height: 32px;
            padding: 8px;
        }

        .edit_text {
            font-family: "Poppins-Medium";
            color: #434db8;
            font-size: 14px;
            padding-left: 25px;
            padding-top: 6px;
        }

    }



    .NDAform-body {
        margin: 6px;
        height: 83vh;
        overflow: auto;
        padding-bottom: 5px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 6px #0000001A;
        border-radius: 10px;

        .NDAForm-body-header {
            height: 60px;

            .logo {
                padding: 1%;
                height: 46px;

                svg {
                    height: 26px;
                    margin-left: -4%;
                }
            }
        }

        .form-name {
            font-size: 18px;
            font-family: 'Poppins-SemiBold';
            color: black;
            display: flex;
            justify-content: center;
        }

    }
}

.NDAform-description {
    line-height: 17px;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    text-align: justify;
}

.editNDAform-description {
    line-height: 17px;
    width: 100%;
    overflow: hidden;
    border: 2px solid #e5e7eb;
    border-radius: 5px;
    text-align: justify;

    &:focus {
        //  border: 1px solid #434db8;
        outline: none;
    }
}

.editNDAform-btn {
    width: 85px;
    height: 35px;
    font-size: 14px;
    font-family: "Poppins-Medium";
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: #FFFFFF;

    &.cancle_btn {
        background: #727171;

        &:hover {
            color: #3c3838
        }
    }

    &.save_btn {
        background-color: #434db8;
    }

   
}
.checkbox-label {
    font-size: 12px;
    font-family: 'Poppins-Regular';
    padding:9px;
    
}