@import "./variable.scss";


@mixin fl_button_Style{
  border-radius: 3px;
  text-align: center;
  letter-spacing: 0px;
  padding: 4px 14px;
  font-size: 14px;
  @media (min-width:$xl) {
    // above 1280
        font-size: 14px;
        padding-left: 14px;
        padding-right: 14px;
        padding-bottom: 5px;
        height: 32px;
    }
  
}

.primary-btn{
    @include fl_button_Style;
    background: $submit_btn_background_color;
    border: 1px solid $submit_btn_border_color;
    color: $submit_btn_font_color;
    font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    &:hover {
        opacity: 0.92;
      }
      &:disabled {
        opacity: 0.5;
      }
      &.icon-btn {
        svg {
          position: relative;
          top: 3px;
        }
      }
}

.btn-primary {
  @include fl_button_Style;
  background: #3CC7D6;
  border: 1px solid #3CC7D6;
  color: $submit_btn_font_color;
  font-family: OpenSans-SemiBold, "Segoe UI", Roboto, Helvetica, Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  &:hover {
      opacity: 0.92;
    }
    &:disabled {
      opacity: 0.5;
    }
    &.icon-btn {
      svg {
        position: relative;
        top: 3px;
      }
    }
}

.secondary-btn{
    @include fl_button_Style;
    border: 1px solid $clear_btn_border_color;
    color: $clear_btn_font_color;
    font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    &:hover {
        border-color: $blue_dark;
        color:$blue_dark;
    }
    &:disabled {
      opacity: 0.5;
    }
}

.gray-btn{
    @include fl_button_Style;
    background: $cancel_btn_background_color;
    border: 1px solid $cancel_btn_border_color;
    color: $cancel_btn_font_color;
    font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    // &:hover {
    //     border-color: $grey_light_white;
    //     color: $black_original;
    // }
    &:disabled {
      opacity: 0.5;
    }
}
.danger-btn{
  @include fl_button_Style;
  background: $result_failed_color;
  border: 1px solid $cancel_btn_border_color;
  color: $cancel_btn_font_color;
  font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  &:hover {
      border-color: $grey_light_white;
      color: $black_original;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.select-parent-btn {
  border: 1px solid #434db8;
  border-radius: 5px;
  color: #434db8;
  font-family: "Poppins-Regular";
  background-color: transparent;
  height: 46px;
  // padding-left: 16px;
  // padding-right: 16px;
  font-size: 13px;
}
.select-testcase-parent-btn{
  border: 1px solid #434db8;
  border-radius: 5px;
  color: #434db8;
  font-family: "Poppins-Regular";
  font-size: 13px;
  background-color: transparent;
  height: 46px;
  width: 175px;
  display: flex;
  align-items: center;
  padding-left: 9%;
  padding-right: 9%;
}
