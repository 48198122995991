.client-report {
  top: 1429px;
  left: 260px;
  width: 100%;
  height: auto;
  /* UI Properties */
  // background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  // border: 1px solid var(--unnamed-color-707070);
  // background: #ffffff 0% 0% no-repeat padding-box;
  // border: 1px solid #707070;
  //new reports CSS 
  background: #F8F9FF 0% 0% no-repeat padding-box;
box-shadow: 0px 1px 6px #272D3B1A;
border: 2px solid #FFFFFF;
border-radius: 8px;
  opacity: 1;
  margin-top: 50px;
  .refreshBtn_web{
    display: flex;
    float: right;
    // margin-top: -16px;
    margin-top: -34px;
    position: relative;
    top: 19px;
    right: 15px;
  }
  .noReport_container {
    height: 400px; 
    .noReport_client {
      // top: 260%;
      // left: 57%;
      // transform: translate3d(-50%, -50%, 0);
      // position: absolute;
      top: 36%;
      left: 90%;
      transform: translate3d(-50%, -50%, 0);
      position: relative;
  
    }
  }

  .biYdFZ {
    position: relative;
    width: 83%;
    margin: 0 10px;
  }
  .browserVersionList :hover .customizedLeftArrow {
    position: relative;
    // left: 29px;
    left: 25px;
    z-index: 6;
    visibility: visible;
    .rec-item-wrapper {
      width: 111px !important;
    }
  }
  .customizedLeftArrow {
    visibility: hidden;
  }
  .browserVersionList :hover .customizedRightArrow {
    position: relative;
    right: 34px;
    visibility: visible;
    .rec-item-wrapper {
      width: 111px !important;
    }
  }
  .osVersionList {
    .rec-carousel-wrapper {
      // padding: 2px;
    }
  }
  .osVersionList :hover .customizedRightArrow {
    z-index: 6;
    margin-top: -12px;
    margin-left: -35px;
    position: absolute;
    visibility: visible;
    .rec-item-wrapper {
      width: 111px !important;
    }
  }
  .osVersionList :hover .customizedLeftArrow {
    position: relative;
    left: 35px;
    z-index: 6;
    visibility: visible;
    .rec-item-wrapper {
      width: 111px !important;
    }
  }
  .customizedRightArrow {
    visibility: hidden;
  }
  .client_content_headings {
    .content_subheadings {
      letter-spacing: 0px;
      // color: #6f7075;
      color: #333333;
      font-size: 14px;
      font-family: "poppins-Medium";
      span {
        letter-spacing: 0px;
        // color: #434db8;
        color: #333333;
      }
    }
   
  }
  .osName_browserVersion {
    font-family: "poppins-SemiBold";
    letter-spacing: 0px;
    color: #434db8;
    font-size: 16px;
    margin-top: -3px;
  }
  .header-style {
    width: 200px;
  }
  .heading {
    color: #333333;
    font-size: 18px;
    font-family: "Poppins-Medium";

    .customizeHr {
      // border: 1px solid black;
      // width: 72vw;
      // margin: 9px;
      border: 1px solid #707070;
      // width: 76vw;
      margin: 9px;
      margin-left: -34px;
      right: 42px;
    }
    .refreshBtn {
      position: relative;
      left: 925px;
    }
  }
  .web_versions_container {
    height: auto;
    // background: #ffffff 0% 0% no-repeat padding-box;
    // border: 1px solid #707070;
     // border-radius: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #272D3B1A;
    border: 2px solid #FFFFFF;
    border-radius: 8px;
    opacity: 1;
   
    padding: 10px;
    font-family: "Poppins-Medium";
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;

    .width_custom {
      width: 10.666667%;
    }
    .width_custom_os {
      width: 13.666667%;
    }
    .labels {
      color: #6f7075;
      font-family: "poppins-Regular";
    }
    .width_custom_defect_browser {
      width: 17.666667%;
      font-size: 12px;
      font-family: "poppins-semiBold";
      letter-spacing: 0px;
      color: #434db8;
      opacity: 1;
      // position: relative;
      // left: 10px;
      padding-left: 16px;
    }
    .width_custom_defect_browser_os {
      width: 13.666667%;
      font-size: 12px;
      font-family: "poppins-semiBold";
      letter-spacing: 0px;
      color: #434db8;
      opacity: 1;
      // position: relative;
      // left: 10px;
      padding-left: 10px;
    }
  }

  .tableBody_wrapper {
    overflow-y: scroll;
    height: 165px;
    width: 101.5%;
    .deviceDefectValue{
      width: 13.666667%;
      font-size: 12px;
      font-family: "poppins-semiBold";
      letter-spacing: 0px;
      color: #434db8;
      opacity: 1;
      // position: relative;
      // left: 10px;
      padding-left: 10px;
    }
    .wrapper_Device{
      .lguFiu {
        // width: 118%;
        // position: relative;
        // left: 4px;
    }
    }
    .deviceBrand {
      font-size: 10px;
      letter-spacing: 0px;
      color: #6f7075;
      opacity: 1;
      font-family: "poppins-Regular";
    }
    .kXteup {
      box-sizing: border-box;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      font-size: 1.6em;
      background-color: none !important;
      color: none !important;
      box-shadow: none !important;
      border-radius: 0% !important;
      border: none !important;

      width: 0px !important;
      height: 50px;
      min-width: 0px !important;
      line-height: 50px;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      cursor: pointer;
      outline: none;
    }
    .rec.rec-arrow:hover {
      background-color: inherit !important;
      color: black;
    }

    .rec.rec-arrow:disabled {
      visibility: hidden;
    }
    .lguFiu {
     
      // width: 118%;
      // position: relative;
      // left: -7px;
    }

    .rec-arrow-left {
      position: absolute;
      z-index: 5;
      margin-left: 10px;
    }
    .rec-arrow-right {
      position: absolute;
      z-index: 5;
      right: 75px;
    }
    table {
      tbody {
        height: auto;
        overflow-y: auto;
      }
    }
    .color-palette__item_standard {
      color: #ffffff;
      width: 100%;
      // height: 35px;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_chrome {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #63DA73 0%, #298C36 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin: 2px;
      border-radius: 5px;
      
    }
    .color-palette__item_safari {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #A9A2A2 0%, #6D6D6D 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin: 2px;
      border-radius: 5px;
    }
    .color-palette__item_samsungBrowser{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #7B81D3 0%, #4C53A7 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px


    }
    .color-palette__item_vivoBrowser{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #2E7BDA 0%, #00469C 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_vivoMobile{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #006CB5 0%, #00558E 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_OPPO{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #72BE4F 0%, #257500 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_ONEPLUS{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #EA0029 0%, #B1001F 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_samsung{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #0964AF 0%, #053258 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_huaweimobile{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #EA3A2A 0%, #BB0619 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_MI{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #FFB07A 0%, #FF6700 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_APPLEmOBILE{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #868686 0%, #212121 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_micromax{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #F08519 0%, #AD5700 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_infinix{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #080808 0%, #707070 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_sony{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #585858 0%, #131313 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px

    }
    .color-palette__item_gionee{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #F26304 0%, #AC4500 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_poco{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #ECB614 0%, #B98E00 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_google{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #7BAEFF 0%, #2E579A 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_lenovo{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #0A79C3 0%, #00558E 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_motorola{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #0578D7 0%, #004680 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px

    }
    .color-palette__item_itel{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #E60012 0%, #730009 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_blackberry{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #535252 0%, #000000 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_nokia{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #0F37A7 0%, #001D6D 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_realme{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #FCCF04 0%, #C7A300 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_LG{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #EB0A5F 0%, #A1003D 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_ASUS{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #7BAEFF 0%, #2E579A 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_coolpad{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #587fd9 0%, #022e91 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_tecno{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #0497e6 0%, #0a5a86 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_zte{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #0085CC 0%, #00639A 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px
    }
    .color-palette__item_honor{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #797478 0%, #372F36 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px

    }
    .color-palette__item_iqoo{
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(90deg, #656565 0%, #000000 100%)
      0% 0% no-repeat padding-box;
      height: 26px;
      margin: 2px;
      border-radius: 5px

    }
    .color-palette__item_fox {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(270deg, #a53e0f 0%, #eb5b1a 100%)
        0% 0% no-repeat padding-box;
      opacity: 1;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin: 2px;
      border-radius: 5px;
    }
    .color-palette__item_opera {
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(270deg, #F36B6F 0%, #B3060B 100%)
        0% 0% no-repeat padding-box;
        height: 26px;
        margin: 2px;
        border-radius: 5px
    }
    .color-palette__item_huawei {
      @extend .color-palette__item_standard;
      background: transparent linear-gradient(270deg, #63A5FF 0%, #1577F6 100%)
        0% 0% no-repeat padding-box;
        height: 26px;
        margin: 2px;
        border-radius: 5px
    }
    .color-palette__item_windows {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
     
      background: transparent
        linear-gradient(90deg, #58b5ff 0%, #2d84ca 49%, #005193 100%) 0% 0%
        no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_mac {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #c6cddf 0%, #9da9c0 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_linux {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #d9a501 0%, #b48204 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_ubuntu {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #982e09 0%, #cb4213 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_edge {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(270deg, #0f719e 0%, #63bdf3 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
  }
  .totalmobile_BrowserDefect{
    display: flex;
    justify-content: center;
    margin-top: 28px;
    top: 10px;
    font-size: 16px;
    font-family: "poppins-Medium";
    letter-spacing: 0px;
    color: #6f7075;

    span {
      color: #434db8;
      font-size: 16px;
    }
  }
  .totalBrowserDefect {
    display: flex;
    justify-content: center;
     margin-top: 26px;
    top: 10px;
    font-size: 16px;
    font-family: "poppins-Medium";
    letter-spacing: 0px;
    color: #6f7075;

    span {
      color: #434db8;
      font-size: 16px;
    }
  }
  .totalBrowserversionDefect {
    display: flex;
    justify-content: center;
     margin-top: 14px;
    top: 10px;
    font-size: 16px;
    font-family: "poppins-Medium";
    letter-spacing: 0px;
    color: #6f7075;

    span {
      color: #434db8;
      font-size: 16px;
    }
  }
  .browserVersion_Defect{
    display: flex;
    justify-content: center;
    //  margin-top: 10px;
    // top: 10px;
    height: 27px;
    font-size: 16px;
    font-family: "poppins-Medium";
    letter-spacing: 0px;
    color: #6f7075;

    span {
      color: #434db8;
      font-size: 14px;
    }
  }
  .customizeHrMobileSection {
    border: 1px solid #707070;
    // width: 75vw;
    margin: 9px;
    margin-left: -22px;
 
  }
  .refreshBtnMobileSection {
    @extend .refreshBtn_web
   
  }
  .mobile_versions_container {
    // height: 546px;
    height: auto;
    // background: #ffffff 0% 0% no-repeat padding-box;
    // border: 1px solid #707070;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #272D3B1A;
    border: 2px solid #FFFFFF;
    border-radius: 8px;
    opacity: 1;
    // border-radius: 5px;
    padding: 10px;
    font-family: "Poppins-Medium";
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
    .tableHead_wrapper{
      .labels{
    color: #6f7075;
    font-family: "poppins-Regular";
      }
    }
    
  }

  .tableBody_wrapper_mobile {
    overflow-y: auto;
    height: 164px;
    width: 101%;
    .DefectValue_mob_os{
      width: 16.666667%;
      font-size: 12px;
      font-family: "poppins-semiBold";
      letter-spacing: 0px;
      color: #434db8;
      opacity: 1;
      // position: relative;
      // left: 16px;
      padding-left: 20px;
  
    }
    .kXteup {
      box-sizing: border-box;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      font-size: 1.6em;
      background-color: none !important;
      color: none !important;
      box-shadow: none !important;
      border-radius: 0% !important;
      border: none !important;

      width: 0px !important;
      height: 50px;
      min-width: 0px !important;
      line-height: 50px;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      cursor: pointer;
      outline: none;
    }
    .rec.rec-arrow:hover {
      background-color: inherit !important;
      color: black;
    }

    .rec.rec-arrow:disabled {
      visibility: hidden;
    }
    .lguFiu {
      
      // width: 107%;
      // position: relative;
      // left: -20px;
    //   width: 115%;
    // position: relative;
    // left: -6px;

    }

    .rec-arrow-left {
      position: absolute;
      z-index: 5;
      margin-left: 10px;
    }
    .rec-arrow-right {
      position: absolute;
      z-index: 5;
      right: 75px;
    }
    table {
      tbody {
        height: auto;
        overflow-y: scroll;
      }
    }
    .color-palette__item_chrome {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #81b9e3 0%, #4184b6 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_android {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #627724 0%, #9dbf36 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_ios {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #8f8e8e 0%, #b2b2b2 100%)
        0% 0% no-repeat padding-box;
      opacity: 1;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_blackberryOs {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #535252 0%, #000000 100%)
      0% 0% no-repeat padding-box;
      opacity: 1;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_fox {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(270deg, #a53e0f 0%, #eb5b1a 100%)
        0% 0% no-repeat padding-box;
      opacity: 1;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_opera {
      color: #ffffff;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(270deg, #3960a3 0%, #82acf3 100%)
        0% 0% no-repeat padding-box;
      opacity: 1;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_windows {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent
        linear-gradient(90deg, #58b5ff 0%, #2d84ca 49%, #005193 100%) 0% 0%
        no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_mac {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #c6cddf 0%, #9da9c0 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_linux {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #d9a501 0%, #b48204 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
    .color-palette__item_ubuntu {
      // width: 39px;
      color: #ffffff;
      // width: 80px;
      width: 100%;
      // height: 35px;
      background: transparent linear-gradient(90deg, #982e09 0%, #cb4213 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 26px;
      margin:2px;
      border-radius: 5px;
    }
  }
  .osName_OsVersion{
    display: flex;
    justify-content: center;
    font-size: 14px;
    // margin-top: 15px;
    height: 27px;
    font-family: "poppins-SemiBold";
    letter-spacing: 0px;
    letter-spacing: 0px;
    color: #434db8;
  }
  .totalOSDefect_Mobile {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-family: "poppins-Medium";
    letter-spacing: 0px;
    letter-spacing: 0px;
    color: #6f7075;

    span {
      color: #434db8;
      font-size: 16px;
    }
  }
  @keyframes slide-from-left_client {
    0% {
      position: relative;
      right: 1%;
      opacity: 0;
      width: 10%;
    }
    50% {
      position: relative;
      right: 5%;
      opacity: 0;
      width: 50%;
    }
    100% {
      position: relative;
      right: 1%;
      opacity: 1;
      width: 100%;
    }
  }
  .wrapperForContainer_clent {
    width: 100%;

    // animation: slide-from-left_client 0.7s ease-in;
  }
}
