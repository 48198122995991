@import './styles/font.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind colors;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 50px;
  height:4px
}


::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 

::-webkit-scrollbar-thumb {
  // background: #E6E6E6; 
  background:  #AEB0B2;
  cursor: pointer;
  border-radius: 100px;
 
}
.mb {
  margin-bottom: 0.5rem;
}
