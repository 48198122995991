@mixin fontPoppins($family: Poppins-Regular, $size: 16px) {
  font-family: $family !important;
  font-size: $size !important;
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(../fonts/poppins/Poppins-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url(../fonts/poppins/Poppins-Medium.otf) format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url(../fonts/poppins/Poppins-SemiBold.otf) format("truetype");
}

//Regular Start

.fontPoppinsRegularXLg {
  @include fontPoppins(Poppins-Regular, 18px);
}

.fontPoppinsRegularLg {
  @include fontPoppins(Poppins-Regular, 16px);
}

.fontPoppinsRegularMd {
  @include fontPoppins(Poppins-Regular, 14px);
}

.fontPoppinsRegularSm {
  @include fontPoppins(Poppins-Regular, 12px);
}

.fontPoppinsRegularXs {
  @include fontPoppins(Poppins-Regular, 10px);
}

//Regular End

//Medium Start
.fontPoppinsMediumPageSubHeader {
  @include fontPoppins(Poppins-Medium, 26px);
}

.fontPoppinsMediumLg {
  @include fontPoppins(Poppins-Medium, 16px);
}

.fontPoppinsMediumMd {
  @include fontPoppins(Poppins-Medium, 14px);
}

.fontPoppinsMediumSm {
  @include fontPoppins(Poppins-Medium, 12px);
}

//Medium End

//SemiBold Start
.fontPoppinsSemiboldPageHeader {
  @include fontPoppins(Poppins-SemiBold, 36px);
}

.fontPoppinsSemiboldAlertHeader {
  @include fontPoppins(Poppins-SemiBold, 32px);
}

.fontPoppinsSemiboldPageSubHeader {
  @include fontPoppins(Poppins-SemiBold, 24px);
}

.fontPoppinsSemiboldXXlg {
  @include fontPoppins(Poppins-SemiBold, 20px);
}

.fontPoppinsSemiboldXlg {
  @include fontPoppins(Poppins-SemiBold, 18px);
}

.fontPoppinsSemiboldLg {
  @include fontPoppins(Poppins-SemiBold, 16px);
}

.fontPoppinsSemiboldMd {
  @include fontPoppins(Poppins-SemiBold, 14px);
}

.fontPoppinsSemiboldSm {
  @include fontPoppins(Poppins-SemiBold, 12px);
}

.fontPoppinsSemiboldXs {
  @include fontPoppins(Poppins-SemiBold, 10px);
}