.card_section_tabel_container{
    height: 58vh;
    margin-top: 16px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #EBEBEB;
    border-radius: 4px;
    opacity: 1;
    // border-top: 2px solid red;
    margin-right: 1.5%;
    padding: 5px;
    .heading{
    color:#333333;
    font-size: 18px;
    font-family: "Poppins-Medium";
    }
    .card_table_container{
        height: 50vh;
        
        .defect_table_header{
            height: 30px;
            font-size: 12px;
            font-family: "Poppins-Regular";
            color:#8F8F8F;
            background: #F2F2F2;
        }
        .defect_table_body{
            // max-height: 399px;
            max-height: 280px;
            overflow-y: auto !important;
            height: auto;
            
        }
    }
  }
  .search_section{
    display: flex;
    justify-content: end;
}
.adminsearch{
    background: #fff;
    border-radius: 5px;
    display: flex;
    margin-right: 11px;
    border: 1px solid #EBEBEB;
    width:195px
}
.search_icon {
    padding-left: 2%;
    padding-top: 2%;
    svg{
      height: 15px;
      width:27px;
    }
}
.alldefect_search{
  background: #fff;
    border-radius: 5px;
    display: flex;
    margin-right: 11px;
    border: 1px solid #EBEBEB;
    width:195px;
    .search_icon{
      padding-left: 2%;
      padding-top: 3%;
      svg{
        height: 15px;
        width:27px;
      }
    } 
}
.searchfield {
    border-radius: 5px;
    color: #707070;
    font-family:"Poppins-Regular";
    font-size: 12px;
    height: 26px;
    outline: none;
    padding-left: 4%;
    width:163px
}
.table_tabs_text{
    font-size: 12px;
    font-family: "Poppins-Medium";
    justify-content: center;
    margin-top: 3px;
    cursor: pointer;
    .selected_tab{
        color: #1592E6;
    }
    .unselected_tab{
        color: #A6A6A6;
    }
}
.report_Download_btn{
    display: flex; 
    justify-content: end;
    button{
    font-size: 10px;
    border: 1px solid #EBEBEB;
    width: 100px;
    border-radius: 4px;
    height: 28px;
    font-family: "Poppins-Medium";
    svg{
        width: 20px;
    }
    &:hover{
        background: #434db8;
        color: #F2F2F2;
    }
    }
}
  
.defect_common_tabel_header{
    // position: absolute;
    // right: -15%;
    display: flex;
    justify-content: end
}
.reports_nodefect_img{
    display: flex;
    justify-content: center;
}


.spanedreporttable {
    max-height: 521px;
    overflow: auto;
    font-family: "Poppins";
    .heading {
      font-family: "Poppins-Medium";
      font-size: 18px;
      color: #3c3838;
     
    }
    tbody [rowspan],
    tbody [rowspan] ~ th,
    tbody [rowspan] ~ td,
    tfoot {
      border-top: 1px solid #EBEBEB;
    }
    .defecttablelistOutline {
        background: #ffffff;
        height: auto;
        opacity: 1;
        .defectTablecontainer {
          background: #ffffff 0% 0% no-repeat padding-box;
          opacity: 1;
          max-height: 441px;
          border-top: 1px solid #707070;
          border-bottom: 1px solid #707070;
    
          table {
            border-spacing: 0;
    
            // border-top: 1px solid #707070;
            tbody {
              max-height: 399px;
              height: auto;
              :last-child {
                td {
                  border-bottom: 0px;
                }
              }
            }
            tr {
              :last-child {
                border-right: 0px;
              }
            }
            th,
            td {
            //   border-bottom: 1px solid #707070;
            //   border-right: 1px solid #707070;
              :last-child {
                border-right: 0;
              }
            }
          }
        }
        .rejectedTablecontainer {
          background: #ffffff 0% 0% no-repeat padding-box;
          opacity: 1;
          max-height: 441px;
          border-top: 1px solid #707070;
          border-bottom: 1px solid #707070;
    
          table {
            border-spacing: 0;
    
            // border-top: 1px solid #707070;
            tbody {
              max-height: 399px;
              height: auto;
              :last-child {
                td {
                  border-bottom: 0px;
                }
              }
            }
            tr {
              :last-child {
                border-right: 0px;
              }
            }
            th,
            td {
            //   border-bottom: 1px solid #707070;
            //   border-right: 1px solid #707070;
              :last-child {
                border-right: 0;
              }
            }
          }
        }
      }
   
  }
  .rowSpan_darkBlack {
    color: #282828;
    font-family: "Poppins-SemiBold";
    font-size: 12px;
  }
  .rowSpan_count{
    color: #282828;
    font-family: "Poppins-Regular";
    font-size: 10px;
    opacity: 0.6;
  }
  .segment_table_container{
    .table_headers{
      height: 30px;
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #8F8F8F;
      background: #F2F2F2;
    }
    .segment_table_body{
      max-height: 190px;
      overflow-y: auto !important;
      height: auto;
    }
  }
  .segment_table{
    .table_header{
      .header_text{
        font-size: 14px;
        font-family: "Poppins-Regular";
        color: #333333;
      }
      .header_btn{
        .download_btn{
          button{
            font-size: 10px;
            border: 1px solid #EBEBEB;
            width: 100px;
            border-radius: 4px;
            height: 28px;
            font-family: "Poppins-Medium";
            &:hover{
              background: #434db8;
              color: #F2F2F2;
          }
          }
        }
      }
    }
  }

  .reports_defectlog_table_container{
    // height: 58vh;
    height: 70vh;
    margin-top: 3%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #EBEBEB;
    border-radius: 4px;
    opacity: 1;
    // border-top: 2px solid red;
    margin-right: 1%;
    padding: 5px;
    .heading{
    color:#333333;
    font-size: 18px;
    font-family: "Poppins-Medium";
    }
    .search_section{
      display: flex;
      justify-content: end;
      .defectlog_search{
        background: #fff;
        border-radius: 5px;
        display: flex;
        margin-right: 11px;
        border: 1px solid #EBEBEB;
        width:211px;
        .search_icon {
          padding-left: 2%;
          padding-top: 2%;
          width:30px
      }
      .searchfield{
          border-radius: 5px;
          color: #707070;
          font-family:"Poppins-Regular";
          font-size: 12px;
          height: 26px;
          outline: none;
          padding-left: 4%;
          width:177px
      }
      }
     
  }
  .defeclog_Download_btn{
    display: flex; 
    justify-content: end;
    margin-right: 5px;
    button{
    font-size: 10px;
    border: 1px solid #EBEBEB;
    width: 100px;
    border-radius: 4px;
    height: 28px;
    font-family: "Poppins-Medium";
    svg{
        width: 20px;
    }
    &:hover{
        background: #434db8;
        color: #F2F2F2;
    }
    }
  }
  .defectlog_filter_btn{
    display: flex; 
    justify-content: end;
    margin-right: 5px;
    button{
    font-size: 10px;
    border: 1px solid #EBEBEB;
    width: 80px;
    border-radius: 4px;
    height: 28px;
    font-family: "Poppins-Medium";
    svg{
        width: 20px;
    }
    &:hover{
        background: #434db8;
        color: #F2F2F2;
    }
    }
  }
    .card_table_container{
        height: 50vh;
        
        .defect_table_header{
            height: 30px;
            font-size: 12px;
            font-family: "Poppins-Regular";
            color:#8F8F8F;
            background: #F2F2F2;
        }
        .defect_table_body{
            max-height: 352px;
            overflow-y: auto !important;
            height: auto;
            
        }
       
    }
  }
 

  .module_segment_container{
    .table_header{
      .header_text{
        font-size: 14px;
        font-family: "Poppins-Regular";
        color: #333333;
      }
      .header_btn{
        .download_btn{
          button{
            font-size: 10px;
            border: 1px solid #EBEBEB;
            width: 100px;
            border-radius: 4px;
            height: 28px;
            font-family: "Poppins-Medium";
            &:hover{
              background: #434db8;
              color: #F2F2F2;
          }
          }
        }
      }
    }
  }
  .execution_reports_container{
    .reports_chart_container{
    
      .result_container,.module_container{
        height: 76vh;
    margin-top: 16px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #EBEBEB;
    border-radius: 4px;
    opacity: 1;
    margin-right: 2.5%;
    padding: 5px;
      }
    }
  }

  .module_segment_container{
    .module_segment_header{
      background: #F8F9FF;
      height:30px ;
      padding:3px ;
      border-radius: 5px;
      .module_name{
        font-size: 12px;
        font-family: "Poppins-Regular";
        color:#333333;
        padding:5px
      }
    }
  }

 .expand_module_container{
 .expand_module_header{
    .header_text{
      font-size: 14px;
      font-family: "Poppins-Medium";
      color:#333333;
    }
  }
 }
.segment_noreportIcon{
  margin: auto;
  margin-top: 36px;
  width: 34%;
  .text_msg{
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    margin-left: -9%;
  }
}

//new filters
.new_filter_containers{
  bottom: 82%;
    left: 30.5%;
    position: relative;
    // height: 79vh;
    height: 69vh;
    width: 70%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #272D3B1A;
   border: 2px solid #FFFFFF;
   border-radius: 16px;
   opacity: 1;
   
  .new_filter_containers_header{
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    text-transform: capitalize;
  }
}
.filter_containers{
 top:14%;
 right: 0%;
    position:absolute;
    // height: 79vh;
    height: 69vh;
    width: 70%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #272D3B1A;
   border: 2px solid #FFFFFF;
   border-radius: 16px;
   opacity: 1;
   
  .new_filter_containers_header{
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    text-transform: capitalize;
  }
}
.new_filter_section{
  height: auto;
  max-height: 55vh;
  overflow-y: scroll;
  padding-bottom: 10%;
}
 
.reports_tescasetlog_table_container{
  height: 60vh;
  margin-top: 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #EBEBEB;
  border-radius: 4px;
  opacity: 1;
  padding: 5px;
  .heading{
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    text-transform: capitalize;
  }
  .search_section{
    display: flex;
    justify-content: end;
    .defectlog_search{
      background: #fff;
      border-radius: 5px;
      display: flex;
      margin-right: 11px;
      border: 1px solid #EBEBEB;
      width:211px;
      .search_icon {
        padding-left: 2%;
        padding-top: 2%;
        width:30px
    }
    .searchfield{
        border-radius: 5px;
        color: #707070;
        font-family:"Poppins-Regular";
        font-size: 12px;
        height: 26px;
        outline: none;
        padding-left: 4%;
        width:177px
    }
    }
   
}
.defeclog_Download_btn{
  display: flex; 
  justify-content: end;
  margin-right: 5px;
  button{
  font-size: 10px;
  border: 1px solid #EBEBEB;
  width: 100px;
  border-radius: 4px;
  height: 28px;
  font-family: "Poppins-Medium";
  svg{
      width: 20px;
  }
  &:hover{
      background: #434db8;
      color: #F2F2F2;
  }
  }
}
.defectlog_filter_btn{
  display: flex; 
  justify-content: end;
  margin-right: 5px;
  button{
  font-size: 10px;
  border: 1px solid #EBEBEB;
  width: 80px;
  border-radius: 4px;
  height: 28px;
  font-family: "Poppins-Medium";
  svg{
      width: 20px;
  }
  &:hover{
      background: #434db8;
      color: #F2F2F2;
  }
  }
}
  .card_table_container{
      height: 50vh;
      
      .defect_table_header{
          height: 30px;
          font-size: 12px;
          font-family: "Poppins-Regular";
          color:#8F8F8F;
          background: #F2F2F2;
      }
      .defect_table_body{
          max-height: 289px;
          overflow-y: auto !important;
          height: auto;
          
      }
     
  }
}
.expand_module_Segment_container{
  .expand_module_segment_header{
     .header_text{
       font-size: 14px;
       font-family: "Poppins-Medium";
       color:#333333;
     }
     .segment_tab{
      font-size: 12px;
      font-family: "Poppins-Medium";
      margin-right: -35px;
      .selected_tab{
        color:#1592E6;
        cursor:pointer
      }
      .unselected_tab{
        color:#A6A6A6;
        cursor:pointer
      }
     
   }
  }
}
.expand_Defects_Search{
  background: #fff;
  border-radius: 5px;
  display: flex;
  margin-right: 11px;
  border: 1px solid #EBEBEB;
  width:211px
}
.See_more_btn{
  font-size: 12px;
  font-family: "Poppins-SemiBold";
  color: #1592E6;
  cursor: pointer;
  span{
    &:hover{
      border-bottom: 1px solid #1592E6;
    }
  }
}
.expand_segment_noreportIcon{
  margin: auto;
  margin-top: 36px;
  width: 19%;
  .text_msg{
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    margin-left: -9%;
  }
}
.defects-donut-chart-custom-tooltip{
  padding: 5px ;
  display: inline-block ;
  overflow-wrap: break-word ;
  word-break: break-all;
  max-width:160px;
  background-color: #fff;
  color:  #333333;
  
 
  
}
.bug_Icon{
  svg{
    height: 28px;
    width:25px;
    &:hover{
      path{
        fill:#434db8
      }
    }
    
  }
}


.all_defect_list {
  height: 100vh;
  .header {
    height: 38px;
    background: #434db8;
    padding-top: 5px;
    color: #ffffff;
    // border-radius: 5px 5px 0px 0px;
    border-top-left-radius: 15px;
    .heading {
      font-family: "Poppins-SemiBold";
      font-size: 16px;
    }
  }
  .body {
    // height: 600px;
    height: 92vh;
    background: #ffffff;
    overflow-y: scroll;
  }
  .footer {
    height: 50px;
    background: #fbfcfd;

    // border-radius: 0px 0px 5px 5px;
    padding-top: 11px;
    .btn_close {
      border-radius: 2px;
      background: #707070;
      height: 30px;
      width: 70px;
      text-align: center;
      color: white;
      font-family: OpenSans-Regular;
      font-size: 15px;
    }
  }
}

.all_defects_table_container{
  .table_headers{
    height: 30px;
    font-size: 12px;
    font-family: "Poppins-Regular";
    color: #8F8F8F;
    background: #F2F2F2;
  }
  .segment_table_body{
    max-height: 549px;
    overflow-y: auto !important;
    height: auto;
  }
}
.all_defects_noreportIcon{
  margin: auto;
  margin-top: 24%;
  width: 21%;
  .text_msg{
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    margin-left: -9%;
  }
}
.chart-labels-container{
  // background-color: yellowgreen;
  // position: relative;
  // left:24%;
  height:auto;
  cursor :default;
  padding:0% 23%;
  .label_dot{
    width: 12px;
    height: 12px;
    border-radius: 100px;
    margin-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 3%;
  }
  .label_text{
    font-size: 12px;
    font-family:"Poppins-Regular" ;
  }
}