.testcase-import{
    .header {
        height: 50px;
        padding-top: 8px;
        background: #ffffff;
      }
      .heading {
        font-family: "Poppins-SemiBold";
        font-size: 18px;
        color: #3c3838;
        cursor: default;
      }
    .icon_search_manualTestcase{
        background: #ecedf8;
        border-radius: 16px;
        height: 32px;
        width: 32px;
        padding: 4px;
        margin-top: 2px;
        margin-left: 1%;
        color: #434db8;
        font-size: 12px;
    }
    .table_search_manualTestcase{
        border: 2px solid #4169e1;
        background: #ecedf8;
        border-radius: 16px;
        height: 33px;
        padding: 2px 4px;
        transition: 0.3s linear;
        position: relative;
        right: 255px;
        .search_input {
            background: #ecedf8;
            height: 27px;
            border-radius: 12px;
            font-family: OpenSans-SemiBold;
            font-size: 12px;
            width: 215px;
            &:focus {
              outline: none;
            }
          }
          .icon_close {
            background: #4169e1;
            border-radius: 12px;
            color: #fff;
            font-size: 12px;
            height: 23px;
            width: 22px;
            // margin-left: 46%;
            svg {
              height: 17px;
              width: 22px;
            }
          }
          .icon_search_input {
            background: #ecedf8;
            border-radius: 12px;
            color: #434db8;
            font-size: 12px;
            svg {
              height: 21px;
              width: 25px;
            }
          }
    }
    
  .import_buttton{
    background: #ecedf8;
    font-family: "Poppins-Medium";
    color: #434db8;
    border-radius: 21px;
    font-size: 12px;
    text-align: center;
    height: 34px;
    border-bottom: 1px solid #ecedf8;
    border-top: 1px solid #ecedf8;
    border-right: 1px solid #ecedf8;
    position: absolute;
    width: 99px;
    right: 29px;
  }
  
}
// browse module styles---------------------------
.add_import_modal{
  .browse_Excel_container{
    background: #ecedf8;
    padding: 22px;
    width: auto;
    margin: 8px;
    border-radius: 6px;
    .browse_file{
      border: 1px solid #0900ff;
    padding: 5px;
    border-radius: 5px;
    }
    .import_button{
      border: 1px solid #0900ff;
      padding: 5px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      background: #1648c6;
      color: #ffff;
    
    }
    .download_Excel_button{
      @extend .browse_file;
      width: 100%;
    }
  }
}
.import_btn{
  margin-left: 26px;
  width: 105px;
  background: #434DB8;
  color: #ffff;
  border-radius: 5px;
  height: 30px;
  font-size: 12px;
  font-family: 'Poppins-Regular';
  display: flex;
  justify-content: center;
  padding-top: 7px;
}
.import_download_btn{
  background:#434DB8;
  width: 200px;
  height: 35px;
  color: #ffff;
  font-size: 14px;
  font-family: 'Poppins-Regular';
  display: flex;
  justify-content: center;
  padding-top: 7px;
  border-radius: 5px;
  cursor: pointer;
}
.import_filename{
  font-size: 14px;
  font-family: 'Poppins-Regular';
  color: #525252;
  width:200px
}
.importfile_icon{
  svg{
    height: 20px;
    width:20px
  }
}
.import_file_form_label{
  font-size: 14px;
  font-family: 'Poppins-Regular';
  color: #525252;
}