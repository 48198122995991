@import '../../styles/variable.scss';
.pagebody {
  background: white;
  border: 2px solid blue;
  width: 70%;
  border-radius: 10px;
}
.single-project {
  display: flex;
}
.project-details {
  display: flex;
  flex-direction: row;
  line-height: 30px;
}
.project-details > div {
  width: 130px;
  text-align: center;
}
.project-details1 {
  display: flex;
  flex-direction: row;
  line-height: 30px;
}

.total-defects {
  width: 150px;
  text-align: center;
}
.total-scripts {
  width: 180px;
  text-align: center;
}
.display {
  display: none;
}
.header-title {
  margin-left: 42px;
  margin-bottom: -60px;
  margin-top: 35px;
}
.content {
  width: 100%;
  margin-left: 45px;
  margin-top: 77px;
}

.labels {
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #434db8;
}
.values {
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #525252;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 190px;
}
.create-labels {
  font-family: "Poppins-Regular";
  color: #646566;
  font-size: 14px;
}
#projectName{
  font-family: "Poppins-Regular";
  color: #646566;
  font-size: 12px;
}
.project-type-values {
  font-family: "Poppins-SemiBold";
  color: #646566;
  font-size: 12px;
}
.description_value {
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #525252;
}
.upload_btn_text {
  font-family: "OpenSans-SemiBold";
  font-size: 12px;
  color: #434db8;
}

.files_value {
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #434db8;
}
.status {
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: green;
}
.pending-status{
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #c50303;
}
.button {
  font-family: OpenSans-SemiBold;
  font-size: 15px;
  color: #fff;
}

.tablecontainer {
  margin: 6px;
  // height: 586px;
  height: 80vh;
  border-radius: 5px;
  //    padding-left:1px;
  overflow-x: hidden;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
tbody {
  // height: 500px;
  height: 73.5vh;
  table-layout: fixed;
  display: block;
  // overflow-y: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.datatrimmer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table_column_header {
  font-size: 12px;
  font-family: "Poppins-SemiBold";
  color: #434db8;
  cursor: default;
}
.table_cell_value {
  font-size: 12px;
  font-family: "Poppins-Medium";
  color: #525252;
  cursor: default;
}

.table_cell_links {
  font-size: 12px;
  font-family: "Poppins-Medium";
  color: #434db8;
  cursor: pointer;
}
.active {
  font-size: 12px;
  font-family: "Poppins-Medium";
  color: #5ea606;
}
.pending {
  font-size: 12px;
  font-family: "Poppins-Medium";
  color: #c50303;
}

.table_message {
  text-align: center;
  font-size: 25px;
  font-family: OpenSans-Bold;
  color: #434db8;
  margin-top: 15%;
}
.icon_search {
  background: #ecedf8;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  padding: 4px;
  margin-top: 2px;
  margin-left: 75%;
  color: #434db8;
  font-size: 12px;
}
.icon_searchcards {
  background: #ecedf8;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  padding: 4px;
  margin-top: 2px;
  margin-left: 75%;
  color: #434db8;
  font-size: 12px;
  margin-right: 56px;
}
.table_search {
  border: 2px solid #4169e1;
  background: #ecedf8;
  border-radius: 16px;
  height: 33px;

  padding: 2px 4px;
  // transition: 0.3s linear;
  position: relative;
  left: -24px;

  .search_input {
    background: #ecedf8;
    height: 27px;
    border-radius: 12px;
    font-family: OpenSans-SemiBold;
    font-size: 12px;
    &:focus {
      outline: none;
    }
  }
  .icon_close {
    background: #4169e1;
    border-radius: 12px;
    color: #fff;
    font-size: 12px;
    height: 23px;
    width: 22px;
    padding-top: 1px;
    // margin-left: 46%;
    svg {
      height: 17px;
      width: 22px;
    }
  }
  .icon_search_input {
    background: #ecedf8;
    border-radius: 12px;
    color: #434db8;
    font-size: 12px;
    svg {
      height: 21px;
      width: 25px;
    }
  }
}
.pageContainer {
  margin: 6px;
  // height: 586px;
  height: 83vh;
  overflow: auto;
  padding-bottom: 5px;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  // border-radius: 5px;

  .supporting_doc {
    max-height: 70px;
    // overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: justify;
  }
  .description {
    height: 70px;
    overflow: auto;
    padding-right: 8px;
  }
}
.project_status {
  font-family: "poppins-Medium";
  font-size: 12px;
  .status_inreview {
    color: #ffa500;
  }
  .status_approved {
    color: #5ea606;
  }
  .status_rejected {
    color: #c50303;
  }
  .status_close {
    color: #c50303;
  }
  .status_inprogress {
    color: #3377f9;
  }
  .status_completed{
    color: #006400;
  }
}
.attach_file {
  .icon_attach {
    color: #4169e1;
    font-size: 12px;
    svg {
      height: 18px;
      width: 20px;
    }
  }
  .icon_close {
    border-radius: 12px;
    color: #4169e1;
    font-size: 12px;
    svg {
      height: 18px;
      width: 20px;
    }
  }
}

.test_card {
  color: #ffffff;
  font-size: 20px;
  font-family: OpenSans-SemiBold;
  .defect_card {
    height: 170px;
    background-color: #26428b;
    border-radius: 12px;
  }
  .manual_card {
    height: 170px;
    background-color: #333399;
    border-radius: 12px;
  }
  .automation_card {
    height: 170px;
    background-color: #3333cc;
    border-radius: 12px;
  }
}
.main-content {
  overflow-y: scroll;
  // max-height: 565px;
  // min-height: 400px;
  max-height: 565px;
  min-height: 350px;
  // font-family: OpenSans-Semibold;
  // font-size: 14px;
}
.createProject-main-content{
  overflow-y: scroll;
  // max-height: 565px;
  // min-height: 400px;
  max-height: 554px;
  min-height: 350px;
  // font-family: OpenSans-Semibold;
  // font-size: 14px;
}
.button-content {
  background-color: #f3f3f3;
  &.modal_footer{
    border-bottom-left-radius: 15px;
  }
}
.create-modal-heading {
  background-color: #434db8;
  color: #ffffff;
  font-family: "Poppins-Medium";
  font-size: 14px;
  height: 38px;
}
.step-heading {
  color: #434db8;
  background-color: #f2f3f3;
  font-family: "Poppins-SemiBold";
  font-size: 14px;
}
.project_list_body {
  height: 80vh;
  overflow: auto;
}

.project_item_container {
  height: 216px;
  background: #ffffff;
  box-shadow: 0px 0px 15px #0000001a;
  border-radius: 5px;
  padding: 10px;
  font-family: "Poppins-Medium";
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px;
  .label {
    color: #434db8;
  }
  .value {
    color: #525252;
  }
  .status_inreview {
    color: #ffa500;
  }
  .status_approved {
    color: #5ea606;
  }
  .status_rejected {
    color: #c50303;
  }
  .status_close {
    color: #c50303;
  }
  .status_inprogress {
    color: #3377f9;
  }
  .status_completed{
    color: #006400;
  }
}
.project_list {
  .header {
    height: 50px;
    background: #ffffff;

    padding-top: 8px;
    .header_text {
      font-family: "Poppins-SemiBold";
      font-size: 18px;
      color: #3c3838;
    }
    .create_buttton {
      background: #ecedf8;
      font-family: "Poppins-Medium";
      font-size: 14px;
      color: #434db8;
      border-radius: 21px;
      font-size: 12px;
      // height: 41px;
      height: 33px;
      border-bottom: 1px solid #ecedf8;
      border-top: 1px solid #ecedf8;
      border-right: 1px solid #ecedf8;
      &:hover {
        border-bottom: 1px solid #434db8;
        border-top: 1px solid #434db8;
        border-right: 1px solid #434db8;
      }
      .add_icon {
        // border-radius: 16px;
        // background: blue;
        // color: white;
        // width: 33px;
        // font-size: 12px;
        // padding: 5px;
        svg {
          height: 32px;
          width: 32px;
        }
      }
    }
    .icon_search {
      // background: #ecedf8;
      // border-radius: 16px;
      // height: 32px;
      // width: 33px;
      // padding: 4px;
      // margin-top: 2px;
      // // margin-left: 80%;
      // color: #434db8;
      // font-size: 12px;
      background: #ecedf8;
    border-radius: 51px;
    height: 35px;
    width: 37px;
    /* padding: 4px; */
    padding-left: 3px;
    /* padding-right: 7px; */
    margin-top: 0px;
    color: #434db8;
    font-size: 12px;
      svg{
        height: 28px;
        width: 32px;
      }
    }
    .table_search {
      border: 2px solid #4169e1;
      background: #ecedf8;
      border-radius: 16px;
      padding: 2px 7px;
      height: 33px;
      margin-right: 3px;
      position: relative;
      left: -24px;
      .search_input {
        background: #ecedf8;
        width: 94%;
        height: 27px;
        border-radius: 12px;
        // padding-left: 2px;
        font-family: OpenSans-SemiBold;
        font-size: 12px;
        &:focus {
          outline: none;
        }
      }
      .icon_close {
        background: #4169e1;
        border-radius: 12px;
        color: #fff;
        font-size: 12px;
        height: 23px;
        width: 22px;
        svg {
          height: 17px;
          width: 22px;
        }
      }
      .icon_search_input {
        background: #ecedf8;
        border-radius: 12px;
        color: #434db8;
        font-size: 12px;
        svg {
          height: 21px;
          width: 25px;
        }
      }
    }
  }
  .body {
    padding: 5px;
  }
}
.tester_deatils {
  height: 100vh;
  .header {
    height: 38px;
    background: #434db8;
    padding-top: 5px;
    color: #ffffff;
    // border-radius: 5px 5px 0px 0px;
    border-top-left-radius: 15px;
    .heading {
      font-family: "Poppins-SemiBold";
      font-size: 16px;
    }
  }
  .body {
    // height: 600px;
    height: 88vh;
    background: #ffffff;
    overflow-y: scroll;
  }
  .footer {
    height: 50px;
    background: #fbfcfd;

    // border-radius: 0px 0px 5px 5px;
    padding-top: 11px;
    .btn_close {
      border-radius: 2px;
      background: #707070;
      height: 30px;
      width: 70px;
      text-align: center;
      color: white;
      font-family: OpenSans-Regular;
      font-size: 15px;
    }
  }
}
.project_list_page_msg {
  height: 550px;
  background: #ffffff;
  box-shadow: 0px 0px 15px #0000001a;
  border-radius: 5px;
  .msg_text {
    font-size: 15px;
    color: #3c3838;
    font-family: "Poppins-SemiBold";
    padding-top: 6px;
  }
  .create_buttton {
    background: #ecedf8;
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #434db8;
    border-radius: 21px;
    font-size: 12px;
    // height: 41px;
    height: 33px;
    margin: 0px 8px;
    .add_icon {
      // border-radius: 16px;
      // background: blue;
      // color: white;
      // width: 33px;
      // font-size: 12px;
      // padding: 5px;
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }
}
.project_details_page {
  .header {
    height: 50px;
    background: #ffffff;
    padding-top: 8px;
  }
  .edit_btn {
    background: #ecedf8;
    color: #434db8;
    border-radius: 21px;
    // height: 41px;
    height: 33px;
    border-bottom: 1px solid #ecedf8;
    border-top: 1px solid #ecedf8;
    border-right: 1px solid #ecedf8;
    opacity: 0.5;
    .edit_text {
      font-family: "Poppins-Medium";
      color: #434db8;
      font-size: 14px;
      padding-left: 25px;
      padding-top: 6px;
    }
    .edit_icon {
      border-radius: 21px;
      background: #434db8;
      width: 34px;
      height: 32px;
      // font-size: 14px;
      padding: 8px;
      svg {
        color: white;
        height: 16px;
      }
    }
  }
  .create_buttton {
    background: #ecedf8;
    color: #434db8;
    border-radius: 21px;
    // height: 41px;
    height: 33px;
    border-bottom: 1px solid #ecedf8;
    border-top: 1px solid #ecedf8;
    border-right: 1px solid #ecedf8;
    &:hover {
      border-bottom: 1px solid #434db8;
      border-top: 1px solid #434db8;
      border-right: 1px solid #434db8;
    }
    .edit_text {
      font-family: "Poppins-Medium";
      color: #434db8;
      font-size: 14px;
      padding-left: 25px;
      padding-top: 6px;
    }
    .edit_icon {
      border-radius: 21px;
      background: #434db8;
      width: 34px;
      height: 32px;
      // font-size: 14px;
      padding: 8px;
      svg {
        color: white;
        height: 16px;
      }
    }
  }
  .heading {
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    color: #3c3838;
    .project-details-heading {
      width: 500px;
    }
  }
}
// .user-deatils {
//   .heading {
//     font-family: "Poppins-SemiBold";
//     font-size: 18px;
//     color: #3c3838;
//     cursor: default;
//   }
// }
.defects-list {
  .header {
    height: 50px;
    padding-top: 8px;
    background: #ffffff;
  }
  .heading {
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    color: #3c3838;
    cursor: default;
  }
  .search_icon{
    position: fixed;
    right: 11%;
    // top:7.5%;
    top:3.5%;
  }
  .addDefectBtn{
    background: #ecedf8;
    font-family: "Poppins-Medium";
    color: #434db8;
    border-radius: 21px;
    font-size: 12px;
    text-align: center;
    height: 34px;
    border-bottom: 1px solid #ecedf8;
    border-top: 1px solid #ecedf8;
    border-right: 1px solid #ecedf8;
    position: absolute;
    width: 100px;
    right: 25px;
    border-bottom: 1px solid #ecedf8;
        border-top: 1px solid #ecedf8;
        border-right: 1px solid #ecedf8;
        &:hover {
          border-bottom: 1px solid #434db8;
          border-top: 1px solid #434db8;
          border-right: 1px solid #434db8;
        }
  }
  .defectFilterBtn{
    background: #ecedf8;
    font-family: "Poppins-Medium";
    color: #434db8;
    border-radius: 21px;
    font-size: 12px;
    text-align: center;
    height: 34px;
    border-bottom: 1px solid #ecedf8;
    border-top: 1px solid #ecedf8;
    border-right: 1px solid #ecedf8;
    position: absolute;
    width: 83px;
    right: 25px;
    border-bottom: 1px solid #ecedf8;
        border-top: 1px solid #ecedf8;
        border-right: 1px solid #ecedf8;
        &:hover {
          border-bottom: 1px solid #434db8;
          border-top: 1px solid #434db8;
          border-right: 1px solid #434db8;
        }

  }
}
.defect-details {
  height: 100vh;
  .header {
    background: #434db8;
    // border-top-left-radius: 5px;
    color: #fff;
    height: 38px;
    padding-top: 7px;
    .heading {
      font-family: "Poppins-Medium";
      font-size: 16px;
    }
  }
  .body {
    // height: 600px;
    background: #fff;
    height: 87vh;
    overflow: auto;
  }
  .footer {
    height: 50px;
    background: #fbfcfd;
    // border-radius: 0px 0px 5px 5px;
    padding-top: 11px;
    .btn_close {
      border-radius: 2px;
      background: #707070;
      height: 30px;
      width: 70px;
      text-align: center;
      color: white;
      font-family: OpenSans-Regular;
      font-size: 15px;
    }
  }
}

.defect-details_modal {
  height: 100vh;
  .header {
    height: 38px;
    background: #434db8;
    padding-top: 7px;
    color: #ffffff;
    border-top-left-radius: 14px;
    .heading {
      font-family: "Poppins-Medium";
      font-size: 16px;
    }
    .defectDetails-edit-icon{
      margin-right: 11px;
      margin-top: -5px;
      svg{
        path{
          fill:#ffffff;
        }
      }
    }
  }
  .body {
    // height: 600px;
    height: 93vh;
    overflow: auto;
    background: #fff;
  border-radius: 10px;
  }
  .footer {
    height: 50px;
    background: #fbfcfd;
    // border-radius: 0px 0px 5px 5px;
    padding-top: 11px;
    .btn_close {
      border-radius: 2px;
      background: #707070;
      height: 30px;
      width: 70px;
      text-align: center;
      color: white;
      font-family: OpenSans-Regular;
      font-size: 15px;
    }
  }
}

.tcuser-details {
  height: 100vh;
  .header {
    height: 38px;
    background: #434db8;
    padding-top: 7px;
    color: #ffffff;
    // border-top-left-radius: 5px;
    .heading {
      font-family: "Poppins-Medium";
      font-size: 16px;
      color: #ffffff;
      margin-left: 15px;
    }
  }
  .body {
    // height: 600px;
    height: 86vh;
    overflow: auto;
    background: #fff;
  }
  .footer {
    height: 50px;
    background: #fbfcfd;
    // border-radius: 0px 0px 5px 5px;
    padding-top: 11px;
    .btn_close {
      border-radius: 2px;
      background: #707070;
      height: 30px;
      width: 70px;
      text-align: center;
      color: white;
      font-family: OpenSans-Regular;
      font-size: 15px;
    }
  }
}
@keyframes slide-from-bottom {
  0% {
    margin-top: 10%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

.wrapper {
  background-color: #fbfcfd;
  box-shadow: 0px 0px 6px #00000029;
  width: 100%;
  padding: 17px 0px;
  margin: 12px 0px;
  color: #525252;
  font-family: OpenSans-Regular;
  animation: slide-from-bottom 0.5s ease-in;
}
.wrapperForContainer {
  width: 100%;

  animation: slide-from-bottom 0.7s ease-in;
}
@keyframes placeholder-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 100%;
  }
}
.title {
  // color: #0000001c;
  margin-left: 10px;
  // animation: placeholder-animation 1.5s ease-in-out 0.5s infinite;
}

#projectName::placeholder,
#duration::placeholder,
#numberOfTesters::placeholder,
#webUrl::placeholder,
#appPackage::placeholder,
#appActivity::placeholder,
#bundleID::placeholder,
#baseuri::placeholder,
#port::placeholder {
  font-family: "Poppins-Medium";
  color: #646566;
  // color: #4f48485e;
  font-size: 12px;
}
.errortext {
  font-family: "Poppins-Regular";
  color: #c50303;
  font-size: 12px;
}
.attachment_icon {
  svg {
    height: 20px;
    width: 20px;
  }
}
.user_list_page_msg {
  background: #ffffff;
  border-radius: 5px;
  .message {
    font-size: 25px;
    color: #434db8;
    font-family: "Poppins-Semibold";
  }
  .msg_text {
    font-size: 15px;
    color: #3c3838;
    font-family: OpenSans-Semibold;
    padding-top: 5px;
  }
  .create_buttton {
    background: #ecedf8;
    font-family: OpenSans-Bold;
    font-size: 14px;
    color: #434db8;
    border-radius: 16px;
    font-size: 12px;
    height: 33px;
    margin: 0px 8px;
    &:hover {
      border-bottom: 1px solid #434db8;
      border-top: 1px solid #434db8;
      border-right: 1px solid #434db8;
    }
    .add_icon {
      // border-radius: 16px;
      // background: blue;
      // color: white;
      // width: 33px;
      // font-size: 12px;
      // padding: 5px;
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }
}
.user-deatils {
  .content-between {
    height: 50px;
    background: #ffffff;
    padding-top: 8px;
    .heading {
      font-family: "Poppins-SemiBold";
      font-size: 18px;
      color: #3c3838;
      cursor: default;
    }

    .create_buttton {
      background: #ecedf8;
      font-family:"Poppins-Medium";
      font-size: 14px;
      color: #434db8;
      border-radius: 16px;
      font-size: 12px;
      width: 94px;
      height: 33px;
      border-bottom: 1px solid #ecedf8;
      border-top: 1px solid #ecedf8;
      border-right: 1px solid #ecedf8;
      &:hover {
        border-bottom: 1px solid #434db8;
        border-top: 1px solid #434db8;
        border-right: 1px solid #434db8;
      }
      .add_icon {
        // border-radius: 16px;
        // background: blue;
        // color: white;
        // width: 33px;
        // font-size: 12px;
        // padding: 5px;
        svg {
          height: 32px;
          width: 32px;
        }
      }
    }
    .icon_search {
      background: #ecedf8;
      border-radius: 16px;
      height: 32px;
      width: 32px;
      padding: 4px;
      margin-top: 2px;
      // margin-left: 80%;
      color: #434db8;
      font-size: 12px;
    }
    .table_search {
      left:24px
    }
    .user_table_search{
      left:-22px
    }
    .table_search , .user_table_search {
      border: 2px solid #4169e1;
      background: #ecedf8;
      border-radius: 16px;
      padding: 2px 7px;
      height: 33px;
      margin-right: 3px;
      position: relative;
     
      width:200px;
      .search_input {
        background: #ecedf8;
        width: 94%;
        height: 27px;
        border-radius: 12px;
        // padding-left: 2px;
        font-family: OpenSans-SemiBold;
        font-size: 12px;
        &:focus {
          outline: none;
        }
      }
      .icon_close {
        background: #4169e1;
        border-radius: 12px;
        color: #fff;
        font-size: 12px;
        height: 23px;
        width: 22px;
        svg {
          height: 17px;
          width: 22px;
        }
      }
      .icon_search_input {
        background: #ecedf8;
        border-radius: 12px;
        color: #434db8;
        font-size: 12px;
        svg {
          height: 21px;
          width: 25px;
        }
      }
    }
  }
  .body {
    padding: 5px;
  }
}
.user-phone-input-tyle {
  width: 100% !important;
  height: 1.43em !important;
}
.user-email-input-tyle {
  min-height: 30px !important;
}
.user-create-button {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #f3f3f3;
  border-bottom-left-radius: 15px;
}
.testcases {
  .header{
    
      height: 50px;
      background: #ffffff;
      padding: 8px;
  
  }
  .heading {
    font: normal normal 600 14px/21px Poppins-SemiBold;
    font-size: 18px;
    color: #3c3838;
    text-transform: capitalize;
  }
  .testcases_container {
    // height: 560px;
    height: 82vh;
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: auto;
    // background: #ffffff;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin: 10px;
    border-radius: 5px;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .expande_modal{
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    margin-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .testcase_msg {
    font-size: 25px;
    font-family: "Poppins-SemiBold";
    color: #434db8;
    background: white;
    width: 482px;
    padding: -20px;
    text-align: center;
  }
  .chart-container {
    // width: 100%;
    // height: 740px;
    height: 550px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    margin-top: 50px;
  }
  .tcchart-container {
    // height: 550px;
    background: #ffffff 0% 0% no-repeat padding-box;
    // border: 1px solid #707070;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    opacity: 1;
    
  }
  
  .add_testcase_btn{
    font-family: "Poppins-Medium";
    background: #ecedf8;
    color: #434db8;
    border-radius: 21px;
    font-size: 14px;
    text-align: center;
    height: 36px;
    border-bottom: 1px solid #ecedf8;
    border-top: 1px solid #ecedf8;
    border-right: 1px solid #ecedf8;
    position: absolute;
    width: 118px;
    right: 9%;
  }
  .add_assign_btn{
    // font-family: "Poppins-Medium";
    // background: #ecedf8;
    // color: #434db8;
    // border-radius: 21px;
    // font-size: 14px;
    // text-align: center;
    // height: 36px;
    // border-bottom: 1px solid #ecedf8;
    // border-top: 1px solid #ecedf8;
    // border-right: 1px solid #ecedf8;
    position: absolute;
    width: 56px;
    top:8%;
    svg{
      height: 34px;
      width:34px;
    }
    &.assing_btn{
      right:17%
    }
    &.assign_testcase{
      right: 0%;
    }
    
   
  }
  .add_module_btn{
    font-family: "Poppins-Medium";
    background: #ecedf8;
    color: #434db8;
    border-radius: 21px;
    font-size: 14px;
    text-align: center;
    height: 36px;
    border-bottom: 1px solid #ecedf8;
    border-top: 1px solid #ecedf8;
    border-right: 1px solid #ecedf8;
    position: absolute;
    width: 100px;
    right: 1%;
  }
  .add_icon{
    svg{
    height: 34px;
    width: 33px
    }
  }
}
.ExecutionAnalytics{
  .tcchart-container {
    // height: 550px;
    background: #ffffff 0% 0% no-repeat padding-box;
    // border: 1px solid #707070;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    opacity: 1;
    
  }
}
.testcasesreport {
  .heading {
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    text-transform: capitalize;
  }
}
.boxedInput {
  box-shadow: 0px 0px 6px #00000000;
  border: 1px solid #a6a6a65e;
  border-radius: 5px;
  height: 3em;
  opacity: 1;
}
.modal-close-btn {

  width: 24px;
  border: 2px solid #434db8;
  border-radius: 19px;
  height: 25px;
 
 
  &:hover {
    border: 2px solid white;
    cursor: pointer;
    background-color: white;
    color:#434db8
  }
}
.popins-semi-bold {
  font-family: Poppins-SemiBold;
}
.popins-medium {
  font-family: Poppins-Medium;
}

.project-description {
  padding-left: 5px;
  line-height: 17px;
  width: 100%;
  overflow: hidden;
  border: 2px solid #e5e7eb;
  border-radius: 5px;
  text-align: justify;
}
.project-description:focus {
  border: 2px solid;
}
.project-description:focus {
  border: 2px solid #434db8;
  outline: none;
}
.alertDanger_icon {
  background: #ffeded;
  height: 36px;
  width: 218px;
  padding: 6px;
}

#projectDesc::placeholder,
#instructions::placeholder {
  font-family: "Poppins-Medium";
  color: #4f48485e;
  font-size: 12px;
}
#projectstatus_dropdown {
  div {
    border-radius: 5px;
    font-size: 13px;
    font-family: "Poppins-Regular";
    div[aria-label="Dropdown select"] {
      min-height: 0px;
      height: 26px;
      width: 150px;
    }
  }
}
.create_status_label {
  font-family: "Poppins-semibold";
  color: #646566;
  font-size: 15px;
}
.defectCreateBtn {
  background: #ecedf8;
  font-family: "Poppins-Medium";
  color: #434db8;
  border-radius: 21px;
  font-size: 12px;
  text-align: center;
  height: 34px;
  border-bottom: 1px solid #ecedf8;
  border-top: 1px solid #ecedf8;
  border-right: 1px solid #ecedf8;
  position: absolute;
  width: 110px;
  right: 21px;
}
.addDefectIcon {
  height: 30px;
  width: 30px;
  left: -1px;
  position: absolute;
  top: 1px;
}
.defectFilterIcon{
  height: 30px;
  width: 30px;
  left: -1px;
  position: absolute;
  top: 1px;
  background: #434db8;
    color: white;
    border-radius: 17px;
    padding-top: 3px
}
.icon_searchTesters {
  background: #ecedf8;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  padding: 4px;
  margin-top: 2px;
  margin-left: 22%;
  color: #434db8;
  font-size: 12px;
}

.table_searchTesters {
  border: 2px solid #4169e1;
  background: #ecedf8;
  border-radius: 16px;
  height: 33px;
  padding: 2px 4px;
  transition: 0.3s linear;
  position: relative;
  left: -120px;
  .search_input {
    background: #ecedf8;
    width: 94%;
    height: 27px;
    border-radius: 12px;
    // padding-left: 2px;
    font-family: OpenSans-SemiBold;
    font-size: 12px;
    &:focus {
      outline: none;
    }
  }
  .icon_close {
    background: #4169e1;
    border-radius: 12px;
    color: #fff;
    font-size: 12px;
    height: 23px;
    width: 22px;
    svg {
      height: 17px;
      width: 22px;
    }
  }
  .icon_search_input {
    background: #ecedf8;
    border-radius: 12px;
    color: #434db8;
    font-size: 12px;
    svg {
      height: 21px;
      width: 25px;
    }
  }
}
.more_btn {
  border: 1px solid #646566;
  height: 18px;
  width: 50px;
  border-radius: 4px;
  text-align: center;
  padding-top: 2px;
  font-size: 9px;
  cursor: pointer;
}

.defect-table-cell-value {
  font-size: 12px;
  font-family: "Poppins-Medium";
  color: #525252;
  cursor: default;
  .defect_action{
    visibility: hidden;
    &.jira_icon{
      svg{
        height: 22px;
      }
    }
    &.disbale_jira_icon{
      svg{
        height: 22px;
        opacity: 0.5;
      }
    }
  }
}
.defect-table-cell-link {
  font-size: 12px;
  font-family: "Poppins-Medium";
  color: #434db8;
  cursor: pointer;
}
.defect-table-cell-value:hover {
  background: #ecedf8;
  .defect_action{
    visibility: visible;
  }
}
.action {
  visibility: hidden;
}
.table_cell_value:hover {
  background: #ecedf8;
}
.table_cell_value:hover .action {
  visibility: visible;
}
.dropdownlabels:hover {
  background: #ecedf8;
  //  color-scheme: white;
}
.user-main-content {
  overflow-y: scroll;
  min-height: 516px;
  max-height: 550px;
  // min-height: 550px;
  // font-family: OpenSans-Semibold;
  // font-size: 14px;
  
}
.user-main-container{
  max-height: 609px;
  min-height: 566px;
  overflow-y: scroll;
}
.defect-add-platform {
  background: #f6f6f6;
  border-radius: 8px;
  max-height: 565px;
  min-height: 400px;
  overflow-y: scroll;
  .platform_dropdown {
    .css-wmy1p7-ReactDropdownSelect {
      height: 20px;
      border-radius: 5px;
    }
  }
  .checkbox_label {
    font-family: "Poppins-Medium";
    font-size: 15px;
    color: #4b566b;
    margin-left: 8px;
  }
  .platform_checkbox {
    width: 55%;
  }
  .platform_heading {
    color: #434db8;
    font-size: 16px;
    font-family: "Poppins-Medium";
    width: 59%;
  }
  .add_new_device {
    border: 1px solid #434db8;
    border-radius: 5px;
    width: 165px;
    height: 35px;
    padding-top: 3px;
    .btn_txt {
      font-size: 13px;
      font-family: "Poppins-Medium";
      color: #434db8;
      padding-top: 4px;
    }
  }
  .add_platform {
    .label {
      font-family: "Poppins-Medium";
      font-size: 15px;
      color: #4b566b;
    }
  }
  .ostype {
    height: 37px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    .osvalue {
      font-size: 14px;
      font-family: "Poppins-Regular";
      color: #3c3838;
      padding-top: 6px;
    }
  }
}

.platform-main-content {
  max-height: 565px;
  min-height: 400px;
  overflow-y: scroll;
  padding-bottom: 22px;
  .project_type_heading {
    border-bottom: 2px solid #3c3838;
    font-size: 17px;
    color: #3c3838;
    font-family: "Poppins-Regular";
  }
  .add_platform {
    .labelPlatFormName {
      font-family: "Poppins-Medium";
      font-size: 15px;
      color: #4b566b;
    }
    .label {
      font-family: "Poppins-Medium";
      font-size: 15px;
      color: #8F8E8E;
    }
    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
      width: 367px;
      margin-left: 9px;
    }
  }
  .ostype {
    height: 37px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background: #F6F6F8;
    .osvalue {
      font-size: 14px;
      font-family: "Poppins-Regular";
      color: #8F8E8E;
      padding-top: 6px;
    }
  }
  .disabledContainer{
   padding-right: 8px;
    height: 328px;
    border-radius: 8px;
    padding: -5px;
    padding-left: 9px;
  }
   .mobileTypeDisabledContainer {
    padding-right: 8px;
    height: 414px;
    border-radius: 8px;
    padding: -5px;
    padding-left: 9px;
}
}

.content-box {
  font-size: 12px;
  font-family: "Poppins-Regular";
  letter-spacing: 0px;
  color: #646566;
  opacity: 1;
  .notes {
    font-family: "Poppins-Medium";
  }
}
.oldTimer {
  top: 27%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
}
.warningText {
  left: 54%;
  font-size: 24px;
  font-family: "Poppins-Medium";
  line-height: 35px;
  letter-spacing: 0px;
  color: #c50303;
  opacity: 1;
  margin-left: 25px;
}
.warningContents {
  position: absolute;
  top: 23%;
  font-size: 15px;
  font-family: "Poppins-Regular";
  letter-spacing: 0px;
  color: #646566;
  opacity: 1;
  left: -160px;
  flex-direction: row;
  justify-content: left;
 
  .activeLink {
    position: relative;
    left: 170px;
    color: #3333cc;
    margin-top: 36px;
  }
  p:nth-child(2) {
    position: relative;
    left: 171px;
    letter-spacing: 0px;
    color: #3333cc;
    margin-top: 36px;
  }
  .showText {
    color: #434db8;
    opacity: 0.5;
  }
}
.customBtns{
  position: relative;
  left: 229px;
  top:9px;
}
.customBtnsNoerrors{
  position: relative;
  left: 229px;
  top: 31px;
}
.guidanceContainer{
  position: absolute;
    top: 240px;
  .responseText{
   margin-left: 100px;
  font-size: 16px;
  font-family: "Poppins-Medium";
letter-spacing: 0px;
color: #00B040;
opacity: 1;
  }
  .resentLink{
    margin-left: 100px;
    font-size: 16px;
    font-family: "Poppins-Medium";
letter-spacing: 0px;
color: #3C3C3C;
opacity: 1;
  }
  .changeEmail{
    margin-left: 100px;
    font-size: 16px;
    font-family: "Poppins-Medium";
letter-spacing: 0px;
color: #434DB8;
opacity: 1;

  }
  .statusResponse{
    left: 167px;
    position: absolute;
    font-size: 16px;
    font-family: "poppins-Medium";
    letter-spacing: 0px;
    color: #8F8E8E;
  }
  .guidanceContainerDetails{
    width: 454px;
    height: 343px;
    background: #FBFBFB 0% 0% no-repeat padding-box;
    border: 1px solid #CECECE;
    border-radius: 5px;
    opacity: 1;
    margin-top: 30px;
    margin-left: 10px;
    .detailsContainer{
    // margin-top: 45px;
    .selectedText{
      text-align: center;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      font-family: "Poppins-Medium";
      font-size: 10px;
    }
      
    }
    .guidanceHeader{
      width: 229px;
height: 20px;
text-align: left;
font-size: 14px;
font-family:"Poppins-Medium" ;

letter-spacing: 0px;
color: #000000;
opacity: 1;
margin: 10px;
margin-left: 111px;
    }
    .customHr{
      width: 421px;
      height: 0px;
      border: 1px solid #CECECE;
      opacity: 1;
      margin-left: 16px;
    }
  }
  .Verified{
    font-family: "poppins-Medium";
    color: #434db8;
  }
  .ststusText{
    font-size: 16px;
    font-family: "poppins-Regular";
letter-spacing: 0px;
color: #8F8E8E;
  }
  .otherTexts{
    font-size: 11px;
    font-family: "poppins-Medium";
    width: 283px;
    height: 16px;
    margin-left: 85px;
letter-spacing: 0px;
color: #000000;
  }
  .footerHr{
    width: 421px;
    height: 0px;
    border: 1px solid #CECECE;
    position: absolute;
    opacity: 1;
    bottom: 42px;
    margin-left: 16px;
  }
  .footerNote{
    font-size: 11px;
    font-family: "poppins-Medium";
  
letter-spacing: 0px;
color: #000000;
position: absolute;
bottom: 15px;
right: 116px;
  }
}
.waringContentTextField {
  border-radius: 8px;
  left: 10px;
  opacity: 1;
  height: 150px;
  position: absolute;
  width: 34vw;
  .noteWaringContentTextField{
    width: 490px;
height: 17px;
text-align: left;
letter-spacing: 0px;
color: #8F8E8E;
opacity: 1;
font-size: 13px;
  }
  .custom_heightPlatform .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 8px;
    border-radius: 5PX;
    box-shadow: 0px 0px 6px #00000000;
border: 1px solid #E0E0E0;
border-radius: 5px;
opacity: 1;
   .disabledBackground{
    background: #DDDDDD 0% 0% no-repeat padding-box;
   }
  }
}

.user-upload-btn {
  border: 1px solid #ffffff;
  border-radius: 3px;
}
.defect_labels {
  font-family: "Poppins-Regular";
  color: #646566;
  font-size: 14px;
}
.feedback_questions_text{
  font-family: "Poppins-Regular";
  color: #000000;
  font-size: 14px;
}

.table_search_defects {
  border: 2px solid #4169e1;
  background: #ecedf8;
  border-radius: 16px;
  height: 33px;
  padding: 2px 4px;
  transition: 0.3s linear;
  position: relative;
  left: -20px;
  .search_input {
    background: #ecedf8;
    width: 94%;
    height: 27px;
    border-radius: 12px;
    // padding-left: 2px;
    font-family: OpenSans-SemiBold;
    font-size: 12px;
    &:focus {
      outline: none;
    }
  }
  .icon_close {
    background: #4169e1;
    border-radius: 12px;
    color: #fff;
    font-size: 12px;
    height: 23px;
    width: 22px;
    svg {
      height: 17px;
      width: 22px;
    }
  }
  .icon_search_input {
    background: #ecedf8;
    border-radius: 12px;
    color: #434db8;
    font-size: 12px;
    svg {
      height: 21px;
      width: 25px;
    }
  }
}
.icon_search_defects {
  background: #ecedf8;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  padding: 4px;
  margin-top: 2px;
  margin-left: 75%;
  color: #434db8;
  font-size: 12px;
}
.defect_more_btn {
  margin-top: 2px;
  .MuiButtonBase-root {
    height: 30px;
    width: 30px;
    &:hover{
      color:#434db8
    }
  }
}
.bulk-upload-unit {
  .input-area {
    background: #f9fafb 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000000;
    border: 1px dashed #434db8;
    border-radius: 5px;
    opacity: 1;
    font-size: 12px;
    font-family: Montserrat, Medium;
    letter-spacing: 0px;
    color: #434db8;
  }
  .upload-template-area {
    background: #f9fafb 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000000;
    border: 1px dashed #434db8;
    border-radius: 5px;
    opacity: 1;
    font-size: 12px;
    font-family: Montserrat, Medium;
    letter-spacing: 0px;
    color: #434db8;
  }
}
.defect-datepicker{
  width: 100%;
  height: 38px;
  border-radius: 5px;
  border: 2px solid #e5e7eb;
  &:hover{
  border: 2px solid #646566;

  }
  .datepicker_field{
    height: 33px;
    background-color: rgb(251, 252, 253);
    width:98%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    outline: none;
    padding: 2px;
    border-right:1px solid #e5e7eb

  }
}
.selected_tab_style {
  font-weight: 600;
  border-bottom: 2px solid #434db8;
  font-family:"Poppins-Medium";
  font-size: 14px;
  color:#434db8;
}
.renderedTabs{
  font-weight: 200;
  font-family:"Poppins-Medium";
  font-size: 14px;
  color:#434db8;
 
  
}


.defect-error{
  color: #d32f2f;
  font-size: 12px;
  font-family:"Poppins-Medium";
}
.defect_list_page_msg {
  .msg_text {
    font-size: 15px;
    color: #3c3838;
    font-family: "Poppins-SemiBold";
    padding-top: 6px;
  }
  .create_buttton {
    background: #ecedf8;
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #434db8;
    border-radius: 21px;
    font-size: 12px;
    // height: 41px;
    height: 33px;
    margin: 0px 8px;
    border-bottom: 1px solid #ecedf8;
    border-top: 1px solid #ecedf8;
    border-right: 1px solid #ecedf8;
    &:hover {
      border-bottom: 1px solid #434db8;
      border-top: 1px solid #434db8;
      border-right: 1px solid #434db8;
    }
    .add_icon {
      // border-radius: 16px;
      // background: blue;
      // color: white;
      // width: 33px;
      // font-size: 12px;
      // padding: 5px;
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }
}

.textarea-content-style{
  border: 1px solid #989797;
  border-radius: 5px;
  height: 100px!important;
  max-height: 100px;
  resize: none;
  overflow: scroll!important;

}
.textarea-content-style:focus{
  outline: none;
  border:1px solid #434db8;
  border-radius: 5px;
  height: 100px!important;
  max-height: 100px;
  resize: none;
  overflow: scroll!important;

}

.certificat{
  width: 59%;
  margin-left:20%;
  .certificat_header{
    font-size: 30px;
    font-family:"Poppins-SemiBold" ;
    color:#D2D6FF;
  }
  .certificat_username{
    font-size: 32px;
    font-family:"Poppins-SemiBold" ;
    color:#FFFFFF;
    margin-top:-13px;
  }
  .certificat_img{
    img{
      height: 450px;
      width: 100%;
    }
  }
  .certificat_footer{
    font-size: 24px;
    font-family:"Poppins-SemiBold" ;
    color:#FFFFFF;
    text-align: center
  }
  .certificat_download{
    .tooltiptext{
      visibility: hidden;
  width: 60px;
  background-color: #969A9B ;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
    }
  }
}
.certificat_download:hover .tooltiptext {
  visibility: visible;
}
.certificat_close_btn{
  display: flex;
  justify-content: end;
  button{
    margin-right: 0.5%;
    margin-top: 1%;
}
}
.project-executive-report{
  .public-DraftEditor-content {
    border: none;
    min-height: 133px;
    margin-left: -7px;
    // padding: 0 8px;
    font-size: 12px;
    font-family: 'Poppins-Regular';
  }

}
.defect-texteditor{
  .public-DraftEditor-content {
    border:none;
    min-height: 133px;
    margin-left: -9px;
    margin-top: -13px;
    //  padding: 0 8px;
    font-size: 12px;
    font-family: 'Poppins-Medium';
  }

}
.report-toggle-btn{
  font-family: 'Poppins-SemiBold';
    color: #434db8;
    // margin-left: 27px;
    font-size: 14px;
    padding: 13px;
}
.table-tree-demo{
  .title{
    animation: none;
    color: $grey_darkest;
  }
}
.piechart_heading{
  font-size: 20px;
  font-family: "Poppins-Medium";
  color: #434db8;
}
.tester_instruction{
  background: #FFFFFF;
}
.refreshicon {
  position: absolute;
  margin-left: 160px;
}
#user_hierarchy_dropdown{
  div{
    font-family: "Poppins-Medium";
  font-size: 11px;
  color: #525252;
  }
}
#user_role_dropdown{
  div{
    font-size: 12px;
    font-family: "Poppins-Medium";
    color: #525252;
  }
  
}
#user_reporting_to{
  div{
    font-size: 12px;
    font-family: "Poppins-Medium";
    color: #525252;
  }
}
.total_defect_icon_searchcards{
  background: #ecedf8;
    border-radius: 16px;
    height: 32px;
    width: 32px;
    padding: 4px;
    margin-top: 2px;
    margin-left: 65%;
    color: #434db8;
    font-size: 12px;
    margin-right: 56px;
}

.total_defects_table_search {
  border: 2px solid #4169e1;
  background: #ecedf8;
  border-radius: 16px;
  height: 33px;

  padding: 2px 4px;
  // transition: 0.3s linear;
  position: relative;
  left: 8px;

  .search_input {
    background: #ecedf8;
    height: 27px;
    border-radius: 12px;
    font-family: OpenSans-SemiBold;
    font-size: 12px;
    &:focus {
      outline: none;
    }
  }
  .icon_close {
    background: #4169e1;
    border-radius: 12px;
    color: #fff;
    font-size: 12px;
    height: 23px;
    width: 22px;
    padding-top: 2px;
    // margin-left: 46%;
    svg {
      height: 17px;
      width: 22px;
    }
  }
  .icon_search_input {
    background: #ecedf8;
    border-radius: 12px;
    color: #434db8;
    font-size: 12px;
    svg {
      height: 21px;
      width: 25px;
    }
  }
}
#projectName,#projectDesc,#rangeFromDate,#duration,#numberOfTesters,
#webUrl,#appPackage,#appActivity,#bundleID,#baseURI,#instructions,.create-project-values
{
  font-family: "Poppins-Regular";
  color: #646566;
  font-size: 12px;
}
.support_doc_value{
  font-family: "Poppins-Regular";
  font-size: 12px;
  padding-top:4px
}
.supportDoc_icon{
  svg{
    height: 18px;
    width:19px
  }
}
.supportDoc_close_icon{
  svg{
    height: 18px;
    width:19px
  }

}
.defects_log_table_search{
  border: 2px solid #4169e1;
  background: #ecedf8;
  border-radius: 16px;
  height: 33px;

  padding: 2px 4px;
  // transition: 0.3s linear;
  position: relative;
  left: -6px;
  width:200px;

  .search_input {
    background: #ecedf8;
    height: 27px;
    border-radius: 12px;
    font-family: OpenSans-SemiBold;
    font-size: 12px;
    &:focus {
      outline: none;
    }
  }
  .icon_close {
    background: #4169e1;
    border-radius: 12px;
    color: #fff;
    font-size: 12px;
    height: 23px;
    width: 22px;
    padding-top: 2px;
    position: absolute;
    right: 1%;
    top: 7%;
    // margin-left: 46%;
    svg {
      height: 17px;
      width: 22px;
    }
  }
  .icon_search_input {
    background: #ecedf8;
    border-radius: 12px;
    color: #434db8;
    font-size: 12px;
    svg {
      height: 21px;
      width: 25px;
    }
  }

}
.defect_log_icon_searchcards{
  background: #ecedf8;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  padding: 4px;
  margin-top: 2px;
  margin-left: 36%;
  color: #434db8;
  font-size: 12px;
  margin-right: 56px;
}

.supporting_doc_values{
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #525252;
}
#defect_platform{
  .react-dropdown-select-content{
  span{
    text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
    width: 133px;
  }
  }
}
.selected-hierarchy-style{
  background-color: lightgray;
}

.platform-header{
  font-size: 14px;
  font-family:"Poppins-Medium" ;
  color: #434DB8;
}
.platform_tablecontainer{
  height: 50vh;
  overflow-x: hidden;
  .table_column_header{
    font-size: 12px;
    font-family: "Poppins-Medium";
    color: #434db8;
    cursor: default;
    background: #F5F6FC;
  }
  tbody{
    height: 42vh;
    table-layout: fixed;
    display: block;
    overflow-y: scroll;
    overflow-x: hidden;
   
}
  

}
.asignTstCaseAlignment{
  right: 1% !important;
}
.module_btn{
  font-family: "Poppins-Medium";
  background: #ecedf8;
  color: #434db8;
  border-radius: 21px;
  font-size: 14px;
  text-align: center;
  height: 36px;
  border-bottom: 1px solid #ecedf8;
  border-top: 1px solid #ecedf8;
  border-right: 1px solid #ecedf8;
  width: 138px;
}
.nomodule{
  top: 55%;
  left: 60%;
  transform: translate3d(-50%,-50%, 0);
  position: absolute;
  .message_text{
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #525252;
    padding-top: 8px;
  }
}
.assignee-list-container{
  height: 85vh;
  // background: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
  .useremail{
    color:#969696
  }
}
.testcase_message{
  font-size: 20px;
  font-family: OpenSans-SemiBold;
  color: #808080;
}
.feedback_report_container{
  // margin: 6px;
  height: 76vh;
  overflow: auto;
  // padding-bottom: 5px;
  .feedback_report{
    height: 76vh;
    overflow: auto;
   
  }
}
.testcase_table_values{
  font-family: "Poppins-Medium";
  font-size: 12px;
  .status_count{
    min-width: 15px;
    height: 19px;
    border: 1px solid;
    border-radius: 12px;
    text-align: center;
    font-size: 8px;
    padding: 3px 7px;
    &.pending_count{
      color: white;
      background-color: #ECBB0F;
    }
  }
}
.Add-Device-Container{
  overflow-y: scroll;
  max-height: 551px;
  min-height: 350px;
}
.add-Device-autocomplete{
  .MuiAutocomplete-root{
    width:100%
  }
}
.Add-Device-Container_btn{
  height: 49px;
  button{
    height: 31px;
  }
}
.executive_report_edit_icon{
  margin-top: -1px;
  svg{
    height: 15px;
  }
}
.assignelist-search{
  position: absolute;
  right: 5%;
}