.selectCustomField {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;

  height: 120px;
}
.selectCustomField{
.css-wmy1p7-ReactDropdownSelect{
    height: 45px;
    border-radius: 5PX;
}
}
.selectCustomField{
.css-6sxacw-ReactDropdownSelect{
  height: 45px;
    border-radius: 5PX;
}
}

.custom_height{
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 8px;
    border-radius: 5PX;
}
}
.form-content{
    overflow: scroll;
    max-height: 339px;
    min-height: 305px;
}
.form-content_radioForms{
  overflow: scroll;
  max-height: 339px;
  min-height: 345px;
}
.textFieldHeader{
    color: #8F8E8E;
}
.errorMessage {
    font-size: 12px;
    text-align: left;
    font-family: Poppins-Regular;
    color: #ea0322;
  }
.closedIcon{
    position: fixed;
    left: 94%;
    top: 60px;
  }
  .dateFormat{
    .css-wmy1p7-ReactDropdownSelect{
height: 44px;
border-radius: 5px;
    }
  }
  .defecttemplate-labels{
    color: #4b566b;
    font-size: 14px;
  }