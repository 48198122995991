.headerSectionDevice {
  width: 100vw;
  height: 64px;
  background: #434db8;
  display: flex;
  p {
    // position: absolute;
    // display: inline-block;
    // left: 50%;
    // top: 5%;
    // -webkit-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    align-items: flex-start;
    top: 0%;
    position: relative;
    left: 1%;
    svg{
      height: auto;
      width:185px
    }
  }
}
.textContainer {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 45%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 15px;
  font-family: "Poppins-Regular";
  letter-spacing: 0px;
  .platformHeading{
    letter-spacing: 0px;
color: #3C3838;
font-family: "poppins-Medium";
font-size: 14px;
padding: 10px;
  }
  .hrLine {
    width: 44px;
    height: 0px;
    border: 2px solid #646566;
    opacity: 1;
    position: relative;
    bottom: 22px;
    top: 31px;
  }

  p:nth-child(3) {
    position: relative;
    top: 18px;
    letter-spacing: 0px;
    color: #434db8;
    opacity: 1;
    font-family: "Poppins-Medium";
    cursor: pointer;
  }
}
.headerSectionDeviceMobile {
    width: 100vw;
    height: 64px;
    background: #434db8;
    display: flex;
   
    p {
    //   position: absolute;
    //   display: inline-block;
    //   left: 50%;
    //   top: 5%;
    //   -webkit-transform: translate(-50%, -50%);
    //   transform: translate(-50%, -50%);
    align-items: flex-start;
    top: 0%;
    position: relative;
    left: 1%;
    svg{
      height: auto;
      width:185px
    }
    
    }
  }
.textContainerVarified {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 15px;
  font-family: "Poppins-Regular";
  letter-spacing: 0px;
  .hrLine {
    width: 44px;
    height: 0px;
    border: 2px solid #646566;
    opacity: 1;
    position: relative;
    bottom: 22px;
  }
  p:nth-child(2) {
    letter-spacing: 0px;
    color: #434db8;
    opacity: 1;
    font-family: "Poppins-Medium";
    font-size: 24px;
    line-height: 9px;
    position: relative;
    bottom: 42px;
  }
  p:nth-child(4) {
    font-family: "Poppins-Medium";
  }

  p:nth-child(5) {
    position: relative;
    top: 2px;
    letter-spacing: 0px;

    opacity: 1;
    font-family: "Poppins-Medium";
  }
  p:nth-child(6) {
    font-family: "Poppins-Medium";
  }
  .verifiedIcon {
    position: relative;
    top: -64px;
  }
}
.item_container_platform{
  width: 609px;
height: auto;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 10px #00000029;
border-radius: 10px;
opacity: 1;
.label {
  color: #434db8;
}
.value {
  color: #525252;
}
}
.verifyBtn{
  width: 80px;
  height: 40px;
  background: #434DB8 0% 0% no-repeat padding-box;
  border-radius: 4px;
  
  color: #FFFFFF;
  left: 3px;
  position: relative;
  top: 50px;
}
.fetch-location-btn{
  width: 135px;
}
.platFormNote{
  position: relative;
  text-align: left;
  font-family: "poppins-Regular";
  font-size: 14px;
  letter-spacing: 0px;
  color: #6F7075;
  padding: 3px;
  right: 84px;
}
.restTexts p{
  font-family: "poppins-Regular";
  font-size: 14px;
  letter-spacing: 0px;
  color: #3C3838;
  opacity: 1;
  margin-top: 15px;
 
}
.error-Text {
  font-family: "Poppins-Regular";
  color: #c50303;
  font-size: 16px;
  position: relative;
  left: 18px;
  top: 12px;
  // font-family: "Poppins-Regular";
  // color: #c50303;
  // font-size: 16px;
  // position: fixed;
  // left: 586px;
  // top: 203px;
}
.mobileIcon{
  margin-top: 4rem;
}
.success-Text{
  font-family: "Poppins-Regular";
  color: #09952F;
  font-size: 16px;
  position: relative;
  left: 0px;
  top: 12px;
}

  .css-lb963a-MuiAutocomplete-root .MuiOutlinedInput-root{
    padding: 2px !important;
  }
.checkCircleIcon{
  position: relative;
  top: 14px;
  left: 0px;
}
.autoCompleteDiv{
  width: auto;
  .css-lb963a-MuiAutocomplete-root .MuiAutocomplete-input{
    font-size: 11px;
    font-family: "Poppins-Regular";
    color: #525252
  }
}
// @media only screen and (Max-width: 480px )
@media only screen and (max-width: 767px) and (max-height: 1366px) {
    .headerSectionDevice {
        width: 100vw;
        height: 64px;
        background: #434db8;
        display: flex;
        p {
          position: absolute;
          display: inline-block;
          left: 50%;
          top: 5%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
         
        }
      }
      .headerSectionDeviceMobile{
        width: 100vw;
        height: 64px;
        background: #434db8;
        display: flex;
        p {
          position: absolute;
          display: inline-block;
          left: 50%;
          top: 5%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
         
        }
      }
      .item_container_platform{
        width: auto;
        height:auto;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px rgb(0 0 0 / 16%);
        border-radius: 10px;
        opacity: 1;
        left: -6px;
        position: relative;
        .label {
          color: #434db8;
          font-size: 11px;
      }
      .value {
        color: #525252;
        font-size: 11px;
      }
      // .osVersionLabel{
      //   margin-left: -11px;
      // }
      }
      .platFormNote{
        position: relative;
        text-align: left;
        font-family: "poppins-Regular";
        font-size: 11px;
        letter-spacing: 0px;
        color: #6F7075;
        padding: 5px;
        // right: -22px;
        right: auto;
      }
      .restTexts p{
        font-family: "poppins-Regular";
        font-size: 13px;
        letter-spacing: 0px;
        color: #3C3838;
        opacity: 1;
        margin-top: 15px;
      }
      .error-Text {
        font-family: "Poppins-Regular";
        color: #c50303;
        font-size: 14px;
        position: relative;
        left: 5px;
        top: 12px;
      }
      .success-Text{

        font-family: "Poppins-Regular";
        color: #09952F;
        font-size: 14px;
        position: relative;
        left: -5px;
        top: 12px;
      }
      .mobileIcon{
        margin-top: 4rem;
      }
      .css-lb963a-MuiAutocomplete-root {
        // width: 180px;
        width: auto !important;
    }
    .autoCompleteDiv{
      width: auto;
      .css-lb963a-MuiAutocomplete-root .MuiAutocomplete-input{
        font-size: 12px;
      }
    }
    .autocomplete{
      .css-wb57ya-MuiFormControl-root-MuiTextField-root{
        width: 86px;
      }
    
     
    }
    .listFonts{
      font-size: 12px;
    }
    .checkCircleIcon {
      position: relative;
      top: 14px;
      left: -8px;
  }

}