@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans-Regular"),
    url(../fonts/open-sans/OpenSans-Regular.ttf) format("truetype");
   
}
@font-face {
  font-family: "OpenSans-Semibold";
  src: local("OpenSans-Semibold"),
    url(../fonts/open-sans/OpenSans-Semibold.ttf) format("truetype");
   
}
@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"),
    url(../fonts/open-sans/OpenSans-Bold.ttf) format("truetype");
   
}
@font-face {
  font-family:"Poppins-SemiBold" ;
  src: local("Poppins-SemiBold"),
  url(../fonts/poppins/Poppins-SemiBold.otf) format("truetype");
}
@font-face {
  font-family:"Poppins-Medium" ;
  src: local("Poppins-Medium"),
  url(../fonts/poppins/Poppins-Medium.otf) format("truetype");
}
@font-face {
  font-family:"Poppins-Regular" ;
  src: local("Poppins-Regular"),
  url(../fonts/poppins/Poppins-Regular.otf) format("truetype");
}
@font-face {
  font-family:"Montserrat-Medium" ;
  src: local("Montserrat-Medium"),
  url(../fonts/Montserrat/static/Montserrat-Medium.ttf) format("truetype");
}
