.Dashboard-container {
  .Dashboard-header {
    height: 50px;
    background: #ffffff;
    padding-top: 8px;
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    color: #3c3838;
  }

  .Dashboard-body {
    height: 85vh;
    // overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;

    .dashbord-card {
      height: 96px;
      border-radius: 10px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      // box-shadow: 0px 0px 16px #00000024;
      // border: 1px solid #434DB824;
      // padding: 21px;
      cursor: pointer;
      &.project{
        border: 1px solid #2AAD2A;
        &:hover{
          box-shadow: 0px 0px 15px #2AAD2A;
          border: 1px solid #ffffff;
        }
      }
      &.device{
        border: 1px solid #A57921;
        &:hover{
          box-shadow: 0px 0px 15px #A57921;
          border: 1px solid #ffffff;
        }
      }
      &.OS{
        border: 1px solid #8F2AAD;
        &:hover{
          box-shadow: 0px 0px 15px #8F2AAD;
          border: 1px solid #ffffff;
        }
      }
      &.browser{
        border: 1px solid #434DB8;
        &:hover{
          box-shadow: 0px 0px 15px #434DB8;
          border: 1px solid #ffffff;
        }
      }
      &.location{
        border: 1px solid #AD2997;
        &:hover{
          box-shadow: 0px 0px 15px #AD2997;
          border: 1px solid #ffffff;
        }
      }

      

      .dashbord-card-icons {
        // padding-top: 8px;
        .Icons{
          position: relative;
          bottom: 12%;
          left: 8px;
          svg{
            height: 45px;
          }
        }
      }

      .dashbord-card-label {
        padding: 10px;
        .label-text {
          font-family: "Poppins-SemiBold";
          font-size: 16px;
          color: #3C3838;;
        }

        .label-count {
          font-family: "Poppins-SemiBold";
          font-size: 23px;
          color: #3C3838;;
        }
      }
    }

    .selected-dashboard-card {
      height: 96px;
      border-radius: 10px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      // box-shadow: 0px 0px 15px #434DB8;
      border: 1px solid #FFFFFF;
      // padding: 21px;
      cursor: pointer;
      &.project{
        box-shadow: 0px 0px 15px #2AAD2A;
      }
      &.device{
        box-shadow: 0px 0px 15px #A57921;
      }
      &.OS{
        box-shadow: 0px 0px 15px #8F2AAD;
      }
      &.browser{
        box-shadow: 0px 0px 15px #434DB8;
      }
      &.location{
        box-shadow: 0px 0px 15px #AD2997;
      }

      

      .dashbord-card-icons {
        // padding-top: 8px;
        
         .Icons{
          position: relative;
          bottom: 12%;
          left: 8px;
          svg{
            height: 45px;
          }
        }

      }

      .dashbord-card-label {
        padding:10px;
        .label-text {
          font-family: "Poppins-Medium";
          font-size: 16px;
          color: #3C3838;
        }

        .label-count {
          font-family: "Poppins-SemiBold";
          font-size: 23px;
          color: #3C3838;
        }
      }

    }

    .Dashboard-table-container {
      height: 409px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #0000001A;
      border: 1px solid #EBEBEB;
      border-radius: 10px;
      opacity: 1;
      margin-top: 20px;

      .dashbord_table {
        .dashboard_table_header {
          .dashboardheader {
            font-family: "Poppins-SemiBold";
            font-size: 12px;
            color: #434DB8;
          }
        }

        .dashboard_table_body {
          height: 55.5vh;
          table-layout: fixed;
          display: block;
          overflow-y: scroll;
          overflow-x: hidden;

          tr {
            border-top: 1px solid #EBEBEB;

          }
          .project-details-row{
            //  box-shadow: 0px 0px 4px #3C3838;
            &:hover{
              // border:1px solid #EBEBEB;
             
              box-shadow: 0px 1px 2px #3C3838;
            }
          }
        }
      }

      .cards_header {
        font-family: "Poppins-SemiBold";
        font-size: 16px;
        color: #3c3838;
      }

      
    }
    .Dashboard-piechart-container{
      height: 350px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #0000001A;
      border: 1px solid #434DB824;
      border-radius: 10px;
      opacity: 1;
      margin-top: 20px;

      .see_more{
        font-size: 10px;
        font-family: 'Poppins-SemiBold';
        color:#434DB8;
        cursor: pointer;
        display: flex;
        justify-content: end;
         margin-right: 5%;
      }

      .dashboard-pie-chart-header {
        height: 50px;
        font-size: 20px;
        font-family: 'Poppins-SemiBold';
        padding-top: 3%;
        // padding-left: 23%;
        color: #3C3838;
        &.user_label{
          padding-left: 20%;
        }
        &.label_user{
          padding-left: 26%;
        }
        &.OS_label{
          padding-left: 21%;
        }
        &.label_OS{
          padding-left: 28%;
        }
        &.browser_label{
          padding-left: 16%;
        }
        &.label_browser{
          padding-left: 23%;
        }
      }

      .dashboard-card-chart-labels {
        margin-left: 17%;
        margin-top: 30%;

        .label_dot {
          width: 12px;
          height: 12px;
          border-radius: 100px;
          margin-top: 1%;
          margin-left: 1%;
          margin-right: 1%;
          margin-bottom: 3%;
        }

        .label_text {
          font-size: 12px;
          font-family: "Poppins-Regular";
        }

        .dashbord_seemore_btn {
          font-size: 12px;
          font-family: "Poppins-Medium";
          color: #434DB8;
          cursor: pointer;
          margin-left: 2%;

        }
      }

      .pie-chart-btn {
        

        div {
          height: 30px;
          margin: 0% 1%;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          font-size: 12px;
          font-family: 'Poppins-Regular';
          padding: 6px;
          cursor: pointer;
          &.unselect_btn {
            border: 1px solid #434DB8;
            background: white;
            color: #434DB8;
  
          }
  
          &.select_btn {
            background: #434DB8;
            border: 1px solid #434DB8;
            color: white;
          }
        }

      }

    }

  }
}

.dashboardspanedreporttable {
  max-height: 384px;
  overflow: auto;
  font-family: "Poppins";

  .heading {
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    color: #3c3838;

  }

  thead {
    font-family: "Poppins-SemiBold";
    font-size: 12px;
    color: #434DB8
  }

  tbody [rowspan],
  tbody [rowspan]~th,
  tbody [rowspan]~td,
  tfoot {
    border-top: 1px solid #EBEBEB;
   
  }

  .defecttablelistOutline {
    background: #ffffff;
    height: auto;
    opacity: 1;

    .defectTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 441px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      table {
        border-spacing: 0;

        // border-top: 1px solid #707070;
        tbody {
          max-height: 399px;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {

          //   border-bottom: 1px solid #707070;
          //   border-right: 1px solid #707070;
          :last-child {
            border-right: 0;
          }
        }
      }
    }

    .rejectedTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 441px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      table {
        border-spacing: 0;

        // border-top: 1px solid #707070;
        tbody {
          max-height: 399px;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {

          //   border-bottom: 1px solid #707070;
          //   border-right: 1px solid #707070;
          :last-child {
            border-right: 0;
          }
        }
      }
    }
  }

}

.bar-chart-custom-tooltip {
  font-size: 12px;
  font-family: "Poppins-SemiBold";
  padding: 5px;
}
.Dashboard-expand-modal{
  .Dashboard-expand-header{
    padding:1%;
    .header_label{
      font-family: "Poppins-SemiBold";
      font-size: 24px;
      color: #3C3838;
    }
  }
}
.dashboard_project_type_icon{
  svg{
    height: 16px;
    width:16px
  }
  .mobile{
    svg{
      width:20px
    }
  }
}
.Dashboard-tabel-header{
  font-family: "Poppins-SemiBold";
  font-size: 18px;
  color: #3C3838;
  padding-left:7px
}
.DashboardSearch{
  background: #fff;
    border-radius: 5px;
    display: flex;
    border: 1px solid #EBEBEB;
    width: 199px;
    .search_icon {
      padding-left: 2%;
      padding-top: 3%;
      svg{
        height: 15px;
        width:27px;
      }
  }
}