.feedback_radioBtn_card{
    .feedback_card{
       
         width:97px;
        height:30px;
        display:flex;
        justify-content: center;
       border-radius: 5px;
       background: #DFE2FF;
       color: #434DB8;
       border: "1px solid #DFE2FF";
       font-size: 12px;
       font-family: "Poppins-Medium";
       padding:7px;
       
    
    }
    .feedback_label{
        width:64px;
        display:flex;
        justify-content: center;
        font-size: 10px;
        font-family: "Poppins-Medium";
        color: #0C090A
    }
}
.feedback_body{
     height: 77vh;
    overflow-y: scroll;
}
.feedback_label{
    font-size: 12px;
    font-family: "Poppins-Regular";
    color:#5681fb
}
.feedback_question_text{
    font-size: 12px;
    font-family: "Poppins-Regular";
    color:#0C090A
}
.feedback_answer_text{
    // font-size: 14px;
    // font-family: "Poppins-Medium";
    // color:#0C090A 
    font-size: 12px;
    font-family: "Poppins-Regular";
    color:#282828;
    opacity: 0.6;
}
.feedback_user_name{
    font-size: 12px;
    font-family: "Poppins-Medium";
    color:#282828;
   
}
.feedback_questions{
    font-size: 14px;
    font-family: "Poppins-Medium";  
}
.feedback_rating_text{
    font-size: 12px;
    font-family: "Poppins-Medium";
    color:#0C090A ; 
    padding: 9px; 
}
.feedback_text{
    font-size: 12px;
    font-family: "Poppins-Medium";  
    color:#5681fb 

}
.feedback_text_message{
    font-size: 16px;
    font-family: 'Poppins-SemiBold';
    color:#646566;
    display: flex;
    justify-content: center;
    padding-top: 30%;
}
//new code 
.feedback_question_container{
    background: #F8F9FF;
    height: auto;
    border-radius: 5px;
    padding-top:1%;
    padding-bottom: 1%;
    // min-height: 60px;
    .feedback_questions{
        font-size: 12px;
        font-family: "Poppins-Regular"; 
        color:#282828; 
    }
  
    
}
 .feedback_text_answer_container{
    max-height: 200px;

   overflow-y: scroll;
   padding-left: 5px;
//    background-color: #F8F9FF;
   padding-top: 5px;
   
}
.feedback_username{
    font-size: 12px;
    font-family: "Poppins-Medium";
    color:#282828;
}
.answer_text_feedback{
    font-size: 12px;
    font-family: "Poppins-Regular";
    color:#282828;
    opacity: 0.6;
}
.see_all_btn{
    font-size: 12px;
    font-family: "Poppins-Medium";
    color:  #5681fb ;
    cursor:pointer
}
.feedback_answer_like_icon{
    .like_icon{
        svg{
            height: 25px;
            width:35px
        }
    }
    .answer{
       font-size: 20px;
       font-family: "Poppins-Semibold"; 
       color:#282828
    }
    
}
.text_answer_container{
    height: 40vh;
    border-radius: 6px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #272D3B1A;
    border: 1px solid #F8F9FF;
     opacity: 1;
}
.Feedback_text_answer_msg{
    display: flex;
    justify-content: center;
    color: #0C090A;
    font-size: 20px;
    font-family: "Poppins-Medium";
    
}
.avatar_icon{
    svg{
        height: 20px;
        width: 35px;
    }
}