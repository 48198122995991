.testcase-templateList{
    .header {
        height: 50px;
        padding-top: 8px;
        background: #ffffff;
      }
      .heading {
        font-family: "Poppins-SemiBold";
        font-size: 18px;
        color: #3c3838;
        cursor: default;
      }
    .icon_search_manualTestcase{
        background: #ecedf8;
        border-radius: 16px;
        height: 32px;
        width: 32px;
        padding: 4px;
        margin-top: 2px;
        margin-left: 1%;
        color: #434db8;
        font-size: 12px;
    }
    .table_search_manualTestcase{
        border: 2px solid #4169e1;
        background: #ecedf8;
        border-radius: 16px;
        height: 33px;
        padding: 2px 4px;
        transition: 0.3s linear;
        position: relative;
        right: 255px;
        .search_input {
            background: #ecedf8;
            height: 27px;
            border-radius: 12px;
            font-family: OpenSans-SemiBold;
            font-size: 12px;
            width: 215px;
            &:focus {
              outline: none;
            }
          }
          .icon_close {
            background: #4169e1;
            border-radius: 12px;
            color: #fff;
            font-size: 12px;
            height: 23px;
            width: 22px;
            // margin-left: 46%;
            svg {
              height: 17px;
              width: 22px;
            }
          }
          .icon_search_input {
            background: #ecedf8;
            border-radius: 12px;
            color: #434db8;
            font-size: 12px;
            svg {
              height: 21px;
              width: 25px;
            }
          }
    }
    
  
}

// ------test case temp details page styles-------------//
.tc-template-details{
  .testStepTable {
    border: 0.05rem solid rgba(197, 197, 197, 0.486);
    border-spacing: 0rem;
    // width: 170px;
}
.testCaseDetail_Section{
  overflow-y: auto;
  overflow-x: hidden;
  height: 63vh;
}
.table_container{
  position: relative;
  // top: 80px;
  height: auto;
}
.columnResizer {
  cursor: col-resize;
  width: 1px;
  padding: 0.5px;
}

}
