@media only screen and (min-width: 768px){
  .otp-left-container {
    // width: 50%;
    // border-right: 3px solid #d2d2d2;
    display:none
  }
  .otp-right-container {
    width: 100%;
    top:-36px;
  }
  .logo_text_Image{
    display: block;
    // position: absolute;
    justify-content: center;
    display: flex;
  }
  .verificationHead{
    font-family:Poppins-SemiBold;
    font-size: 34px;
    color: var(--deepGray);
    position: relative;
    left: 30%;
  }
  .verificationSubHead {
    font-size: 14px;
    font-family: Poppins-Regular;
    color: var(--deepGray);
    display: flex;
    justify-content: center;
      
  }
  .ifThisIs {
    font-family: Poppins-Regular;
    font-size: 14px;
    color: var(--deepGray);
    display: flex;
      justify-content: center;
  }
  .otp_number_field{
    display: flex;
    justify-content: center;
  }
  .itMayTakeText {
    font-family: Poppins-Regular;
    font-size: 12px;
    color: var(--deepGray);
    
  }
  .itMayTakeText .msg1{
    display: flex;
    justify-content: center;
  }
  .itMayTakeText .msg2{
    display: flex;
    justify-content: center;
  }
  .verificatiom_btn{
    display: flex;
    justify-content: center
  }
  .verification_err_msg {
    font-size: 10px;
    font-family: Poppins-Regular;
    color: #ea0322;
    margin-left:30%;
  }
  .Phone_success_msg{
    position: absolute;
    top:6%
  }
}

@media only screen and (min-width:1200px){
  .otp-left-container {
    width: 50%;
    border-right: 3px solid #d2d2d2;
    display:block
  }
  .otp-right-container {
    width: 50%;
    top:-36px;
  }
  .logo_text_Image{
    display: none;
  }
  .verification_leftsideimg {
    margin-top: 50px;
    margin-left: 155px;
    height: 80px;
    width: 392px;
  }
  .verification_leftsideimg2 {
    width: 350px;
    height: 350px;
    position: relative;
    margin-left: 160px;
    // margin-top:127px;
  }
  .verificationHead{
    font-family:"Poppins-SemiBold";
    font-size: 30px;
    color:#000000;
    position: relative;
    left:0%
  }
  .verificationSubHead {
    font-size: 14px;
    font-family: Poppins-Regular;
    color: var(--deepGray);
    display: flex;
    justify-content: center;
      
  }
  .ifThisIs {
    font-family: "Poppins-Regular";
    font-size: 12px;
    color: #000000;
    display: flex;
    justify-content:inherit;
  }
  .otp_number_field{
    display: flex;
    justify-content: left;
    left:-3%
  }
  .itMayTakeText {
    font-family: "Poppins-Regular";
    font-size: 12px;
    color:#000000 ;
    
  }
  .itMayTakeText .msg1{
    display: flex;
    justify-content: left;
  }
  .itMayTakeText .msg2{
    display: flex;
    justify-content: left;
  }
  .verificatiom_btn{
    display: flex;
    justify-content: left
  }
  .verification_err_msg {
    font-size: 10px;
    font-family: Poppins-Regular;
    color: #ea0322;
    margin-left:0%
  }
  .Phone_success_msg{
    position: absolute;
    top:11%
  }
}











// .otp-left-container {
//   width: 50%;
//   border-right: 3px solid #d2d2d2;
// }
// .otp-right-container {
//   width: 50%;
//   top:-36px;
// }
#recaptcha-container {
  visibility: hidden;
}
// .verificationHead, .phoneChangeHead {
//   font-family:Poppins-SemiBold;
//   font-size: 34px;
//   color: var(--deepGray);
//   position: relative;
// }
.verificationSubHead {
  font-size: 12px;
  font-family: "Poppins-Regular";
  color: #000000;
  display: flex;
  justify-content: start;
    
}
// .ifThisIs {
//   font-family: Poppins-Regular;
//   font-size: 14px;
//   color: var(--deepGray);
//   display: flex;
//     justify-content: center;
// }
.veriFicationCodeSpan {
  font-size: 12px;
  color: var(--blue);
  font-family: "Poppins-SemiBold";
  border-bottom: 1px solid #ffff;
  &:hover{
    border-bottom: 1px solid var(--blue);
  }
}
// .itMayTakeText {
//   font-family: Poppins-Regular;
//   font-size: 12px;
//   color: var(--deepGray);
//   display: flex;
//     justify-content: center;
// }
.verifyContinueButton {
    font-family: "Montserrat-Medium"; 
    margin-top: 21px;
    font-size: 12px;
    position: relative;
    width: 30%;
    line-height: 10px;
    color: var(--blue);
    border: 1px solid var(--blue);
    border-radius: 4px;
    height: 29px;
    &:hover{
      background:var(--blue);
      color:#ffff ;
    }

}
.warningIcon {
  margin-left: 186px;
}
#otp input[type="text"] {
  border: none;
  border-bottom: 2px solid var(--lightGray);
  box-shadow: none;
  outline: none;
  color: var(--lm-normal-525252);
  text-align: center;
  height: 20pxthead_style;
  letter-spacing: 0px;
  color: var(--lightGray);
  font-family: Poppins-Regular;
  font-size: 12px;
  padding-bottom: 0px;
 
}
.phnValidBtn {
  // background-color: var(--blue);
  // color: var(--white);
  // font-size: 12px;
  // font-family: Poppins-SemiBold;
    font-size: 12px;
    font-family: "Montserrat-Medium";
    color: #3749C3;
    background: #ffff;
    width: auto;
    height: 29px;
    padding: 6px 11px;
    border: 1px solid #3749C3;
    border-radius: 4px;
    &:hover{
      background: #3749C3;
      color: #ffff;
    }
}
#validPhn {
  color: var(--red);
  font-size: 12px;
  font-family: "Poppins-Regular";
  height: 10px;
}
.mainContainer_activeDiv {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}
.token_expired{
  .token_expired_header{
    margin-top: -17%;
    .header_text{
      font-size: 30px;
      font-family: "Poppins-SemiBold";
      color:#454db2;
      height: 56px;
    }
  }
  .token_msg_text{
    margin-top: -20%;
    .token_msg{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #000000;
    }
  }
  .activation_link{
    margin-top: -25%;
    .activation_text{
      font-size: 12px;
      font-family: "Poppins-SemiBold";
      color:#454db2;
      span{
        &:hover{
          border-bottom: 1px solid #454db2;
        }
      }
    }

  }
  .token_expired_icon{
    .expired_icon{
      height: 200px;
      img{
        height: 150px;
      }
    }
  }
}
