.VideoTutorials-container {
    .VideoTutorials-header {
        height: 50px;
        background: #ffffff;
        padding-top: 8px;
        font-family: "Poppins-SemiBold";
        font-size: 18px;
        color: #3c3838;
    }

    .VideoTutorials-body {
        height: 85vh;
        overflow: auto;

        .VideoTutorials-card {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 12px #09105E29;
            border-radius: 4px;
            height: 210px;

            .video_layout_back {
                // width: 330px;
                // margin-top: 15px;
                height: 210px;
            }

            .video_icon_tut {
                display: flex;
                justify-content: center;
                position: relative;
                bottom: 48%;

                .icon {
                    color: #FFFFFF;
                    cursor: pointer;

                    svg {
                        height: 45px;
                        width: 55px;
                    }
                }
            }

            .video-description {
                padding: 36px 0px;

                .video-header {
                    color: #434DB8;
                    font-family: "Poppins-SemiBold";
                    font-size: 25px;
                }
                .video-line {
                    width: 50px;
                    border: 3px solid #DB7221;
                    border-radius: 10px;
                    
                }
                .video-summary{
                    color: #282828;
                    font-family: "Poppins-Regular";
                    font-size: 17px;
                }

                
            }
        }


    }
}
.video_close_btn{
    position: absolute;
    width: 20px;
    bottom:100%;
    right: 0.5%;
    color: #FFFFFF;
    cursor: pointer;
}

