:root {
  --deepGray: #3c3838;
  --lightGray: #727171;
  --bluesGray: #293241;
  --lightGreen: #79b62f;
  --blue: #1648c6;
  --skyBlue: #rgb(97, 175, 248);
  --red: #ea0322;
  --deepRed: #e63946;
  --white: #fff;

  --black: #00000029;
  --dark: #3c3838;
--muted: #727171;
}
@media only screen and (min-width: 768px) {
  .login-left-container{
    }
    .login-right-container{
      width:100%;
      display: flex;
    align-items: center;
    }
    .leftsideimg-container{
      display: flex;
      align-items: center;
      position: absolute;
     
    }
    .leftsideimg2-container{
      display: none;
    }
    .loginGrid {
      margin-right: 228px;
      background: var(--white);
      }
      .leftsideimg {
        margin-top: 50px;
        margin-left: 155px;
        height: 80px;
        width: 392px;
      }
}

@media only screen and (min-width: 1200px) {
  .login-left-container{
   width: 50%;
   display: inline-block;
   border-right: 3px solid #D2D2D2;
  }
  .login-right-container{ 
   display: inline-block;
  }
  .leftsideimg-container{
     display: block;
     opacity: 1;
     margin-top:0%;
  }
  .leftsideimg2-container{
    display: block;
    /* margin-top:140px */
  }
  .loginGrid {
    margin-right: 190px;
    padding: 43px 0px 0px 27px;
    border-radius: 10px;
    background: var(--white);
    margin-top: 85px;
    }
    .leftsideimg {
      margin-top: 50px;
      margin-left: 155px;
      height: 80px;
      width: 392px;
    }
    .leftsideimg2 {
      width: 350px;
      height: 350px;
      position: relative;
      margin-left: 160px;
      margin-top:127px;
    }
}































/* .leftsideimg {
  margin-top: 50px;
  margin-left: 155px;
  height: 80px;
  width: 392px;
}
.leftsideimg2 {
  width: 350px;
  height: 350px;
  position: relative;
  margin-left: 160px;
} */
.secondText {
  color: var(--deepGray);
  font-size: 20px;
  margin-left: 155px;
  font-family: OpenSans-Semibold ;
}
.belowText {
font-size: 34px;
color: var(--deepGray);
font-family:OpenSans-Semibold ;
width: 400px;
margin-left: 155px;
}
.lastText {
color: var(--lightGray);
font-size: 18px;
font-family: OpenSans-Semibold ;
width: 490px;
margin-left: 125px;
}
/* .login-left-container{
width: 50%;
display: inline-block;
border-right: 3px solid #D2D2D2;
}
.login-right-container{
display: inline-block;
} */
/* .loginGrid {
margin-right: 190px;
padding: 43px 0px 0px 27px;
border-radius: 10px;
background: var(--white);
margin-top: 143px;
} */
.submitButton {
font-size: 12px;
font-family:"Montserrat-Medium";
border-radius: 4px;
color:var(--blue);
background: #fff;
border: 1px solid var(--blue);
width: 30%;
height: 30px;

}
.submitButton:hover {
  background:var(--blue);
  color:#fff;

}
.gridContent > .signinUserText {
margin-bottom: 9px;
/* position: absolute;
margin-top: -120px; */
}
.gridContent > .signinUserText > h3 {
margin-top: 35px;
font-size: 30px;
}
.signinUserText > h3 {
font-family: Poppins-Medium ;
font-size: 36px;
line-height: 1.5;
color: var(--deepGray);
}
.signinUserText > span {
/* color: var(--lightGray);
font-size: 14px;*/
/* font-family: Poppins-Medium ;  */
padding-bottom: 2px;
}
.login-labels{
font-family:"Poppins-Medium";
font-size: 12px;
color: #3C3838;
}
.error_text{
font-family:Poppins-Regular;
font-size: 12px;
color:var(--red);
/* margin-left:-8px */
}
.password_error_text{
  font-family:Poppins-Regular;
font-size: 12px;
color:var(--red);
margin-left:3px
}
.error_msg{
  position: absolute;
  top: 362px;

}
#password[placeholder="Enter your password"],#email[placeholder="Enter your email"]{
font-family:Poppins-Regular;
font-size: 14px;

letter-spacing: 0px;
color: #3C3838;
opacity: 1;
}
input::placeholder {

font-family:Poppins-Regular;
font-size: 14px;

letter-spacing: 0px;
color: #3C3838;
opacity: 1;

}
.signin_header{
font-size: 30px;
font-family:"Poppins-SemiBold";
color:#000000;

}
/* #email{
border-radius: 5px;
height: 27px;  
} */
/* #password{
border-radius: 5px;
height: 32px; 
} */

.newUser{
color:#000000;
font-size: 12px;
font-family:"Poppins-Regular";

}
.create_account{
  color:#3749C3;
  font-size: 12px;
font-family:"Poppins-Regular";
}
.page-for {
font-size: 34px;
font-family: Poppins-Medium;
color: var(--dark);
top: 10px;
position: relative;
}

.page-note {
font-size: 18px;
color:var(--muted) ;
max-width: 400px;
text-align: center;
margin: 20px 0;
font-family: Poppins-Regular;
}
.logout_container{
position:fixed;
top:6%;
left:85%;
z-Index:1;
box-shadow: 0px 0px 10px #4B566B24;
border-radius: 5px;
font-size: 12px;
font-family: Poppins-Regular;
height:74px;
width:200px;
cursor: default;
}
.logout_menu:hover{
background-color: #f0f3f9;
}

#forgetPassword > a {
  /* font-size: 12px;
  font-family:Poppins-Regular;

  position: relative;
  bottom: 22px;
  left: 5px; */
  font-size: 12px;
  font-family: Poppins-Regular;
  /* right: 24%; */
  /* position: absolute; */
  /* bottom: 35%; */
  /* right: 267px;
  bottom:366px; */
}
/* .passwordField{
  top: 14px;
  position: relative;
} */