.addTempBtn {
    background: #ecedf8;
    font-family: "Poppins-Medium";
    color: #434db8;
    border-radius: 21px;
    font-size: 12px;
    text-align: center;
    height: 34px;
    border-bottom: 1px solid #ecedf8;
    border-top: 1px solid #ecedf8;
    border-right: 1px solid #ecedf8;
    position: absolute;
    width: 114px;
    right: 14px;
    border-bottom: 1px solid #ecedf8;
        border-top: 1px solid #ecedf8;
        border-right: 1px solid #ecedf8;
        &:hover {
          border-bottom: 1px solid #434db8;
          border-top: 1px solid #434db8;
          border-right: 1px solid #434db8;
        }
  }

  .test_case_details{
    .testcase_header{
    height: 50px;
    background: #ffffff;
    padding-top: 8px;
    .heading {
        font-family: "Poppins-SemiBold";
        font-size: 18px;
        color: #3c3838;
        cursor: default;
      }
      .testcase_btn{
        font-family: "Poppins-Medium";
        background: #ecedf8;
        color: #434db8;
        border-radius: 21px;
        font-size: 12px;
        text-align: center;
        height: 34px;
        border-bottom: 1px solid #ecedf8;
        border-top: 1px solid #ecedf8;
        border-right: 1px solid #ecedf8;
        position: absolute;
         width: 100px;
        right: 4%;
        }
        .close_btn{
        // right: 7px;  
        display: flex;
        justify-content: end;
        
        }
        .add_icon{
            svg {
              height: 32px;
              width: 32px;
            }
          }
    }
    .testcasedetails_container{
      overflow-y: auto;
      overflow-x: hidden;
      height: 75vh;
      
      
    }
}
.customEditTestCase{
  .MuiInputBase-root{
    font-family:"poppins-Regular";
    font-size: 14px;
    height: 40px;
    box-shadow: 0px 0px 6px #00000000;
border: 1px solid #E0E0E0;
border-radius: 5px;
opacity: 1;
  }
}
.contentDataStyle{
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    height: 40px;
  }
}

.testcase_execution{
  .testcase_header{
    height: 50px;
    background: #ffffff;
    padding-top: 8px;
    .heading {
        font-family: "Poppins-SemiBold";
        font-size: 18px;
        color: #3c3838;
        cursor: default;
      }
  }
  .testcaseExecution_container{
      min-height: 69vh;
      overflow: auto
      
  }
}
.testcase_lables{
  font-family: "Poppins-Medium";
  font-size: 14px;
  color: #434db8;
}
.testcase_values{
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #525252;
  
}
.testcase_cancle_btn{
  background: gray;
  width: 85px;
  margin-right: 3px;
  font-family: "Poppins-Medium";
  font-size: 14px;
  color: #ffffff;
  border-radius: 4px;
  height: 35px;
}
.testcase_save_btn{
  background:var(--blue);
  width: 85px;
  margin-right: 1px;
  margin-left: 5px;
  font-family: "Poppins-Medium";
  font-size: 14px;
  color: #ffffff;
  border-radius: 4px;
  height: 35px;
}
.tc_selectdropdown{
  margin-right: 3%;
  margin-left: -3%
}
.testStepTable {
  border: 0.05rem solid rgba(197, 197, 197, 0.486);
  border-spacing: 0rem;
}
#tc_result div div[aria-label="Dropdown select"] {
  min-height: 0px;
  height: 25px;
  width: 106px;
}
.primary-tab {
  padding-top: 4px;
  margin-bottom: 14px;
  &.bottom-space-sm {
    margin-bottom: 10px;
  }
  li {
    margin-right: 24px;
  
  }
    // display: flex;
    
    // line-height: 24px;
    // color: #1648c6;
    // padding-right: 2px;
    
    // &:hover {
    //   border-bottom: 1px solid #1648c6;
    // }
    // &.selected_tab_style {
    
    //   border-bottom: 2px solid #1648c6;
    // }
    a {
      display: flex;
     
      font-size: 14px;
      font-family: "poppins-Regular";
      line-height: 24px;
      color:#1648c6;
      padding-right: 2px;
      img {
        max-width: 14px;
        margin-right: 6px;
        @media (min-width:'1536px') {
            // above 1280
            max-width: 14px;
            margin-right: 6px;
            }
      }
      &:hover {
        border-bottom: 1px solid#1648c6;
      }
      &.selected_tab_style {
       font-size: 14px;
       font-family:"poppins-Semibold";
        border-bottom: 2px solid#1648c6;
      }
      @media (min-width:'1536px') {
        // above 1280
        font-size: 14px;
        line-height: 24px;
        }
    }
  
  


}
.execution_dashboard{
  .testcase_header{
    height: 50px;
    background: #ffffff;
    padding-top: 8px;
    .heading {
        font-family: "Poppins-SemiBold";
        font-size: 18px;
        color: #3c3838;
        cursor: default;
      }
  }

}

.testcase_home{
  .header{
    height: 50px;
    background: #ffffff;
    padding-top: 8px;
    .tab-lable{
      font-family: "Poppins-Medium";
      color: #434db8;
      font-size: 14px;
     
    }
    .selected-tab{
      border-bottom: 2px solid #434db8;
      padding-bottom: 5px;
    }
    
    .testcase_btn{
      font-family: "Poppins-Medium";
      background: #ecedf8;
      color: #434db8;
      border-radius: 21px;
      font-size: 12px;
      text-align: center;
      height: 34px;
      border-bottom: 1px solid #ecedf8;
      border-top: 1px solid #ecedf8;
      border-right: 1px solid #ecedf8;
      position: absolute;
       width: 100px;
      // right: 4%;
      left:-12px;
      &.execution_btn{
        left: 88px
       }
     
      }
      
      .close_btn{
      // right: 7px;  
      display: flex;
      justify-content: end;
      
      }
      .add_icon{
          svg {
            height: 32px;
            width: 32px;
          }
        }
  
  }
  .heading {
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    color: #3c3838;
    cursor: default;
  }
  .testcase_home_container{
    margin: 6px;
    // height: 84vh;
    height: 79vh;
    overflow: auto;
    padding-bottom: 5px;
   
  }
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  // min-width: 319px !important;
  // top: 116px !important;
  // left: 1005px !important;
  background: red;
}
.testcase_execution_header{
  font-size: 16px;
  font-family: "Poppins-Medium";
  color: #3c3838;
}