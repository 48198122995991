.item .MuiButtonBase-root {
  padding: 9px;
}
.item .css-i4bv87-MuiSvgIcon-root {
  height: 16px;
  width: 16px;
}
.item .css-ahj2mt-MuiTypography-root {
  font-size: 12px;
  font-family: Montserrat-Medium;
  color: #4b566b;
}
.FilterChevronIcon {
  color: #4b566b;
  height: 21px;
  width: 20px;
}
.item {
  color: #4b566b;
  font-family: OpenSans-Semibold;
  font-size: 12px;
}
.filter-header{
  color: #303AA2;
  // border-bottom: #434DB8 1px solid;
  font-family: "Poppins-medium";
 font-size: 14px;
  
}
.filter-modal-buttons {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  // background-color: #f3f3f3;
}
.clear-button{
  background-color: #B1B1B1;
  border-radius: 3px
}
.filter-box-header {
  color: #4b566b;
  font-family: "Poppins-regular";
  font-size: 12px;
}
.filter_search {
  background: #f9fafb;
  border-radius: 5px;
}
.search_input {
  background: #f9fafb;
  width: 107px;
}
.filter-box {
  border: #e0e0e0 1px solid;
  width: 145px;
}
.filter-dropdown{
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
}
.filter_search_icon > svg {
  height: 15px;
}
.search_input:focus {
  outline: #ebedf0;
}
.filter-label-close-icon {
  width: 15px !important;
  height: 13px !important;
  // margin: 3px 5px;
  margin-top: -11px;
  margin-left: 1px;
}
.filterLabel {
  font-size: 11px;
  line-height:13px;
  font-family: "Poppins-Regular";
  color: #A6A6A6;
}
.filterItem_container{
  border-radius: 39px;
    background:#FFFFFF;
    height:25px;
    // border:1px solid #b2b2b2;
    border:1px solid #A6A6A6;
}
.clearIcon{
  border:1px solid #A6A6A6;
  border-radius: 50%;
  height:19px;
  width:19px;
color:white;
  margin:2px;
  background:#A6A6A6;
  cursor: pointer;
}
.web-filter-container{
  background: #E4E4E4;
}
.filter-lables-container{
  // max-height: 300px;
  // overflow-x: scroll;
  height: 120px;
  overflow-y: scroll;
  overflow-x: scroll;
  // border: #e0e0e0 1px solid;
  // background:#F8F9FF;
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1019607843);
}
.selected-filter-header{
  color: #434DB8;
  font-family: "Poppins-medium";
  font-size: 14px;
}
.no-defects-label {
  color: #8F8E8E;
  font-size: 12px;
  font-family:"Poppins-regular";
  position:absolute;
  top:20%;
  left: 43%;
}
.filter-applied {
  color: #000;
  font-size: 10px;
  border: 1px solid #000;
  background-color: #ffffff;
  top: 98px;
  position: sticky;
  top: 0px;
  margin-left: 10px;
  margin-top: -17px;
}
.selected_filter_header{
    color: #A6A6A6;
    // border-bottom: #434DB8 1px solid;
    font-family: "Poppins-medium";
    font-size: 12px;
  
}
.testcase_filter_container{
  bottom: 96%;
  left: 30%;
  position: relative;
  height: 63vh;
  width: 70%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #272D3B1A;
 border: 2px solid #FFFFFF;
 border-radius: 16px;
 opacity: 1;
 .testcase_filter_container_header{
  font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    text-transform: capitalize;
 }
 
}
.testcase_new_filters{
  height: auto;
  min-height: 45vh;
  overflow-y: scroll;
  padding-bottom: 20%;
}