// @import "../../../../css_config/variable.scss";

$description-color: cadetblue;
$description-border: #dbd8d8;
$grey_light: #d2d2d2;
$grey_white: #e1dfdf;
$grey_dark: #727171;
.input-field {
  div {
    input[type="text"] {
      padding-bottom: 1px;
      &:focus {
        padding-bottom: 2px;
      }
    }
  }
}

.feild-height {
  height: 56px;
}

.row-cell {
  width: 250px;
}

.modal-height {
  height: 500px;
  overflow-y: auto;
}

.description-field {
  height: 210px !important;
  overflow: auto;
  resize: none;
  background: none;
  font-size: 12px;
  color: $description-color;
  border: 1px solid $description-border;
  border-radius: 4px;
}

.count-pill {
  background-color: $grey_light;
  color: $grey_white;
  margin-left: 8px;
  border-radius: 4px;
  padding: 3px 1px;
}

.select-dropdown {
  height: 1.43em;
  box-shadow: 0px 0px 6px #00000000;
  border: 1px solid #a6a6a65e;
  border-radius: 5px !important;
  opacity: 1;
  width: 100%;
  margin-left: 3px;
  color: $grey_dark;

  div {
    &.react-dropdown-select-dropdown {
      &.react-dropdown-select-dropdown-position-bottom {
        width: 100%;
        border-radius: 8px;
        max-height: 210px;
      }
    }
  }
}

.hide-characters {
  -webkit-text-security: disc;
}
