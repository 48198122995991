@import "../../../src/styles/variable.scss";

.pageImage {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(4.5 * 100% / 12);

  border-color: #d2d2d2;


  width: 452px;
  height: auto;
}
.regularFont{
  font-family:Poppins-Regular ;
}
.mediumFont{
  font-family:Poppins-Medium ;
}
.semiBoldFont{
  font-family:Poppins-SemiBold ;
}
input::placeholder {
  font-size: 12px;
  @extend .regularFont;
  
}
.haveAnaccount{
  @extend .regularFont;
  font-size:12px;
  line-height:19px;
  letter-spacing: 0px;
  color: #000000
}
.emailExist{
  @extend .regularFont;
  position: absolute;
  left: 266px;
}
.pageImageContainer {
  width: 345px;
  height: 345px;
  position: relative;
  margin-left: 77px;
}
.fireCrowdIcon {
  margin-top: 50px;
  height: 101px;
  width: 392px;
}
.phone-input-wrapper {
  :global(.react-tel-input) {
    :global {
      .form-control {
        height: 34px;
        width: 99%;
        border: 1px solid $border-gray;
        // border-bottom: 1px solid $border-gray;
        border-radius: 4px;
        // border-top: 0;
        // border-left: 0;
        // border-right: 0;
        box-shadow: 0px 0px;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 12px;
        margin-top: 6px;
      }

      .selected-flag {
        padding-left: 8px;
        top:3px
      }

      .country-list {
        width: 335px;
        height: 236px;
        border-radius: 5px;
        box-shadow: 0px 0px 6px $border-muted;
        text-align: left;
        position: relative;
        background-color: $sky-blue-light;
      }

      .search-box {
        border-bottom: 1px solid $border-gray;
        border-radius: 0px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        box-shadow: 0px 0px;
        width: 90%;
      }
    }
  }

  &.error {
    :global(.react-tel-input) {
      input {
        // border-bottom: 1px solid $danger
        border:1px solid  $danger;
        &:focus{
          outline: 1px solid  $danger;
        }
      }
    }
  }
}
.password-info-icon {
  :global {
    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }
  }
}
.submitBtn {
  font-size: 12px;
  font-family: "Montserrat-Medium";
  color: #3749C3;
  // background: $blue_dark;
  border: 1px solid var(--blue);
  width: 15%;
  height: 29px;
  border-radius: 4px;
  &:hover{
    background-color: #3749C3;
    color: #ffff;
  }
}
.rightIcons {
  position: relative;
  left: 485px;
  top: 123px;
}
.regSuccessContainer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 38.333333%;

  border-color: #d2d2d2;
}
.textContent {
  h1 {
    color: #3c3838;
    position: absolute;
    top: 267px;
    left: 773px;
    font-weight: 500;
    font-size: 43px;
    width: 454px;
  }
  .warning-text {
    color: #3c3838;
    position: absolute;
    top: 343px;
    right: 135px;
    font-size: 17px;
    font-weight: 400;
  }
  .resActLink {
    color: $blue;
    position: absolute;
    top: 378px;
    right: 270px;
  }
  .backToSuccess {
    color: $blue;
    position: absolute;
    top: 411px;
    right: 289px;
  }
}

@media only screen and (min-width: 768px){
  .activation_link {
    display: none;
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
     
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
      
    }
    
  }
  .activation_page {
    // width: 100%;
    // position: absolute;
    .mobile-screen-logo{
      display: block;
      position: absolute;
      left: 25%;
      top:14%

     }
    .heading {
      font-weight: 500;
      font-size: 36px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
      margin-top:15%;
      position: absolute;
      left: 27%;
    }
    .msg {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #727171;
    }
    .link_text {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -83px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        // margin-left: 96px;
        margin-left: 163px;
        width: 100%;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }

}

@media only screen and (min-width:1200px){
  .activation_link { 
    .activation_link_leftImage{
      .leftImage{
        .logo_Icon{
          margin-top: 20px;
          width: 392px;
          height: 80px;
        }
      }
    }
    display: block;
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
     
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
      
    }
    
  }
  .activation_page {
   .mobile-screen-logo{
    display: none;
   }
    .heading {
      
      font-size: 30px;
      font-family: "Poppins-SemiBold";
      color: #000000;
      position: absolute;
      left: 60%;
      margin-top:2%
    }
    .msg {
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #000000;
    }
    .link_text {
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #3749C3;
      cursor: pointer;
      a{
        &:hover{
          border-bottom: 1px solid #3749C3;
        }
      }
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 28px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family:"Montserrat-Medium";
      font-size: 12px;
      background: var(--white);
      color: #3749C3;
      border-radius: 4px;
      border: 1px solid #3749C3;
      width: auto;
      height: 29px;
      line-height: 13px;
      padding:8px;
      &:hover{
        background: #3749C3;
        color:var(--white)
      }
    }
  }

}



@media only screen and (min-width: 768px){
  .success_activation_link {
    display: none;

    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .success_activation_page {
    .logoimg{
      display: block;
      position: absolute;
      left:29%
    }
    .righticon{
      position: absolute;
    left: 36%;
    left: 43%;
    top: 16%;
    }
    .heading {
      font-weight: 500;
      font-size: 36px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
      position: absolute;
      left: 22%;
      top: 27%;
    }
    .msg {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #727171;
      position: absolute;
      left: 22%;
      top: 33%;
    }
    .activation_link_text {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 40%;
      top: 35%;
    }
    .Back_link_text{
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 43%;
      top: 38%;
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 25%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }
}

@media only screen and (min-width:1200px){
  .success_activation_link {
    display: block;
   .success_activation_link_leftImage{
      .leftsideLogo{
        .logo_icon{
          margin-top: 20px;
        width: 392px;
        height: 80px;
        }
      }
    }
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .success_activation_page {
    .logoimg{
      display: none;
    }
    .righticon{
      position: absolute;
  
    left: 70%;
    top: 16%;
    }
    .heading {
      font-weight: 500;
      font-size: 30px;
      font-family: "Poppins-SemiBold";
      color: #000000;
      position: absolute;
      left: 60%;
      top: 35%;
    }
    .msg {
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #000000;
      position: absolute;
      left: 60%;
      top: 43%;
    }
    .activation_link_text {
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 68%;
      top: 49%;
      span{
        &:hover{
          border-bottom: 1px solid #1648c6;
        }
      }
    }
    .Back_link_text{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 69.5%;
      top: 54%;
      span{
        &:hover{
          border-bottom: 1px solid #1648c6;
        }
      }
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 63%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }

}

@media only screen and (min-width: 768px){
  .warning_activation_link {
    display: none;
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .warning_activation_page {
    .logoimg{
      display: block;
      position: absolute;
      left:29%
    }
    .warningIcon{
      position: absolute;
    left: 36%;
    left: 43%;
    top: 16%;
    }
    .heading {
      font-weight: 500;
      font-size: 36px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
      position: absolute;
      left: 29%;
      top: 27%;
    }
    .msg_one {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #727171;
      position: absolute;
      left: 19%;
      top: 33%;
    }
    .msg_two {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #727171;
      position: absolute;
      left: 41%;
      top: 36%;
    }
    .activation_link_text {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 43%;
      top: 38%;
    }
    .Back_link_text{
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 46%;
      top: 41%;
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 25%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }
}

@media only screen and (min-width:1200px){
  .warning_activation_link {
    display: block;
    .warning_activation_link_leftImg{
      .leftImg{
        .logo_icon{
          margin-top: 20px;
          width: 392px;
          height: 80px;
        }
      }
    }
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .warning_activation_page {
    .logoimg{
      display: none;
    }
    .warningIcon{
      position: absolute;
  
    left: 72%;
    top: 18%;
    }
    .heading {
      font-size: 30px;
      font-family: "Poppins-SemiBold";
      color: #3c3838;
      position: absolute;
      left: 66%;
      top: 35%;
    }
    .msg_one {
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #727171;
      position: absolute;
      left: 58%;
      top: 43%;
    }
    .msg_two{
      font-size: 12px;
      font-family: Poppins-Regular;
      color: #727171;
      position: absolute;
      left: 70%;
      top: 47%;

    }
    .activation_link_text {
      font-size: 12px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
    left: 71%;
    top: 49%;
    span{
      &:hover{
        border-bottom: 1px solid #1648c6;
      }
    }
    }
    .Back_link_text{
      font-size: 12px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 73%;
      top: 53%;
      span{
        &:hover{
          border-bottom: 1px solid #1648c6;
        }
      }
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 63%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }

}

@media only screen and (min-width: 768px){
  .mail_send_activation_link {
    display: none;
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .mail_send_activation_page {
    .logoimg{
      display: block;
      position: absolute;
      left:29%
    }
    .righticon{
      position: absolute;
    left: 36%;
    left: 43%;
    top: 16%;
    }
    .heading {
      font-weight: 500;
      font-size: 36px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
      position: absolute;
      left: 36%;
      top: 27%;
    }
    .msg{
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #727171;
      position: absolute;
      left: 25%;
      top: 33%;
    }
    .Back_link_text{
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 43%;
      top: 36%;
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 25%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }
}

@media only screen and (min-width:1200px){
  .mail_send_activation_link {
    display: block;
    .mail_send_activation_link_leftImage{
      .leftImage{
        .logo_Icon{
          margin-top: 20px;
          width: 392px;
          height: 80px;
        }
      }
    }
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .mail_send_activation_page {
    .logoimg{
      display: none;
    }
    .righticon{
      position: absolute;
      left: 72%;
      top: 20%;
      display: flex;
      
      .succcess_msg{
      font-size: 19px;
      font-family: "Poppins-SemiBold";
      color:#78B62F;
      }
    }
    .heading {
      font-weight: 500;
      font-size: 30px;
      font-family: "Poppins-SemiBold";
      color: #000000;
      position: absolute;
      left: 70%;
      top: 38%;
    }
    .msg_one{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #000000;
      position: absolute;
      left: 65.5%;
      top: 38%; 
    }
    .msg{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #000000;
      position: absolute;
      left: 64%;
      top: 45%;
    }
    
    
    .Back_link_text{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 72%;
      top: 48%;
      span{
       &:hover{
        border-bottom: 1px solid  #1648c6;
       }
      }
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 63%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }

}

@media only screen and (min-width: 768px){
  .setpassword_activation_link {
    display: none;
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .setpassword_activation_page {
    .logoimg{
      display: block;
      position: absolute;
      left:29%
    }
    .righticon{
      position: absolute;
    left: 36%;
    left: 43%;
    top: 16%;
    }
    .heading {
      font-weight: 500;
      font-size: 36px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
      position: absolute;
      left: 23%;
      top: 27%;
    }
    .Back_link_text{
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 43%;
      top: 33%;
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 25%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }
}

@media only screen and (min-width:1200px){
  .setpassword_activation_link {
    display: block;
    .setpassword_activation_link_leftImage{
      .leftImage{
        .logo_Icon{
          margin-top: 20px;
          width: 392px;
          height: 80px;
        }
      }
    }
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .setpassword_activation_page {
    .logoimg{
      display: none;
    }
    .righticon{
      position: absolute;
      left: 66%;
      top: 28%;
      display: flex;
      img{
        height: 18px;
      }
      .succcess_msg{
      font-size: 19px;
      font-family: "Poppins-SemiBold";
      color:#78B62F;
      }
    }
    .heading {
      font-size: 12px;
      font-family: "Poppins-Regular";
      color:#000000;
      position: absolute;
      left: 61%;
      top: 38%;
      // .msg_text_one{
      //   position: relative;
      //   left: 20%;
        
      // }
      // .msg_text_two{
      //   position: relative;
      //   left: 12%;
      
      // }
    }
    
    .Back_link_text{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 72%;
      top: 37%;
      span{
        &:hover{
          border-bottom: 1px solid #1648c6;
        }
      }
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 63%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }

}
@media only screen and (min-width: 768px){
  .activation_success_link {
    display: none;
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .activation_success_page {
    .logoimg{
      display: block;
      position: absolute;
      left:29%
    }
    .righticon{
      position: absolute;
    left: 36%;
    left: 43%;
    top: 16%;
    }
    .heading {
      font-weight: 500;
      font-size: 36px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
      position: absolute;
      left: 26%;
      top: 29%;
    }
    .Back_link_text{
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 43%;
      top: 35%;
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 25%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }
}

@media only screen and (min-width:1200px){
  .activation_success_link {
    display: block;
    .activation_success_link_leftImage{
      .leftImage{
        .logo_Icon{
          margin-top: 20px;
          width: 392px;
          height: 80px;
        }
      }
    }
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .activation_success_page {
    .logoimg{
      display: none;
    }
    .righticon{
      position: absolute;
      left: 72%;
      top: 18%;
    }
    .heading {
      font-size: 30px;
      font-family: "Poppins-SemiBold";
      color: #000000;
      position: absolute;
      left: 64%;
      top: 41%;
    }
    .Back_link_text{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 72%;
      top: 50%;
      span{
        &:hover{
          border-bottom: 1px solid #1648c6;
        }
      }
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 63%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }

}

@media only screen and (min-width: 768px){
  .phone_validation_activation_link {
    display: none;
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .phone_validation_activation_page {
    .logoimg{
      display: block;
      position: absolute;
      left:29%
    }
    .warningIcon{
      position: absolute;
    left: 36%;
    left: 43%;
    top: 16%;
    }
    .heading {
      font-weight: 500;
      font-size: 36px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
      position: absolute;
      left: 29%;
      top: 27%;
    }
    .msg{
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #727171;
      position: absolute;
      left: 30%;
      top: 33%;
    }
    
    .activation_link_text {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 43%;
      top: 38%;
    }
    .Back_link_text{
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 46%;
      top: 41%;
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 25%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }
}

@media only screen and (min-width:1200px){
  .phone_validation_activation_link {
    display: block;
    .phone_validation_activation_link_leftImage{
      .leftImage{
        .logo_Icon{
          margin-top: 20px;
          width: 392px;
          height: 80px;
        }
      }
    }
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .phone_validation_activation_page {
    .logoimg{
      display: none;
    }
    .warningIcon{
      position: absolute;
  
    left: 72%;
    top: 18%;
    }
    .heading {
      font-size: 30px;
      font-family: "Poppins-SemiBold";
      color: #000000;
      position: absolute;
      left: 55%;
      top: 24%;
    }
    .msg{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #000000;
      position: absolute;
      left: 55%;
      top: 28%;
    }
    
    .activation_link_text {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
    left: 70%;
    top: 56%;
    }
    .Back_link_text{
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
    left: 72%;
    top: 61%;
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 63%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }

}





@media only screen and (min-width: 768px){
  .password_activation_link {
    display: none;
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }

  .password_activation_page {
    .logotextImage{
      display: block;
      position: absolute;
      left:29%
    }
    .warningIcon{
      position: absolute;
    left: 36%;
    left: 43%;
    top: 16%;
    }
    .heading {
      font-weight: 500;
      font-size: 36px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
      position: absolute;
      left: 29%;
      top: 27%;
    }
    .msg{
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #727171;
      position: absolute;
      left: 30%;
      top: 33%;
    }
    
    .activation_link_text {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 43%;
      top: 38%;
    }
    .Back_link_text{
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 3%;
    /* top: 94%; */
    margin-top: 80%;
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 25%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }
}

@media only screen and (min-width:1200px){
  .password_activation_link {
    display: block;
    .password_activation_link_leftImage{
      .leftImage{
        .logo_Icon{
          margin-top: 20px;
          width: 392px;
          height: 80px;
        }
      }
    }
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .password_activation_page {
    .logotextImage{
      display: none;
    }
    .warningIcon{
      position: absolute;
      left: 72%;
      top: 18%;
    }
    .heading {
      // font-weight: 500;
      font-size: 30px;
      font-family: "Poppins-SemiBold";
      color: #3c3838;
      position: absolute;
      left: 56%;
      top: 24%;
    }
    .msg{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #000000;
      position: absolute;
      left: 56%;
      top: 30%;
    }
    
    .activation_link_text {
      font-size: 14px;
      font-family: Poppins-Regular;
      color: #1648c6;
      cursor: pointer;
      position: absolute;
    left: 70%;
    top: 56%;
    }
    .Back_link_text{
    font-size: 12px;
    font-family: "Poppins-Regular";
    color: #3749C3;
    cursor: pointer;
    margin-top: 74%;
    position: absolute;
    left:0%;
    a{
      &:hover{
        border-bottom: 1px solid #3749C3;
      }
    }
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 63%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-size: 12px;
      font-family: "Montserrat-Medium";
      color: #3749C3;
      background: #ffff;
      border: 1px solid var(--blue);
      width: 32%;
      height: 29px;
      border-radius: 4px;
      line-height: 13px;
      padding:8px;
      &:hover{
      background-color: #3749C3;
      color: #ffff;
      }
    }
  }

}


// .activation_link {
//   .heading {
//     font-size: 34px;
//     font-family: Poppins-SemiBold;
//     color: #3c3838;
//   }
//   .msg {
//     font-size: 18px;
//     font-family: Poppins-Regular;
//     color: #727171;
//   }
// }
// .activation_page {
//   .heading {
//     font-weight: 500;
//     font-size: 36px;
//     font-family: Poppins-SemiBold;
//     color: #3c3838;
//   }
//   .msg {
//     font-size: 14px;
//     font-family: Poppins-Regular;
//     color: #727171;
//   }
//   .link_text {
//     font-size: 14px;
//     font-family: Poppins-Regular;
//     color: #1648c6;
//     cursor: pointer;
//   }
//   .warning-division {
//     width: 400px;
//     .goodPass {
//       font-size: 10px;
//       font-family: Poppins-Regular;
//       color: #79b62f;
//     }
//     .err_msg {
//       font-size: 10px;
//       font-family: Poppins-Regular;
//       color: #ea0322;
//     }
//   }
//   .activation_text {
//     .warningAlert {
//       background-color: transparent !important;
//       margin-top: -64px;
//       padding-bottom: 5px;
//       color: var(--lightGreen);
//       font-size: 18px;
//       font-family: Poppins-SemiBold;
//     }
//     .alertIconActivationLink {
//       color: #79b62f;
//       margin-left: -13px;
//       position: relative;
//       left: 7px;
//       width: 30px !important;
//       height: 30px !important;
//     }
//   }
//   .password_change_mail_alert {
//     .warningAlert {
//       background-color: transparent !important;
//       margin-top: -64px;
//       padding-bottom: 5px;
//       font-family: Poppins-SemiBold;
//       margin-left: 96px;
//       .alertHeading {
//         color: #79b62f;
//         font-size: 18px;
//       }
//       .alertMsg {
//         color: #727171;
//         font-size: 15px;
//       }
//     }
//     .alertIconActivationLink {
//       color: #79b62f;
//       margin-left: -13px;
//       position: relative;
//       left: 7px;
//       width: 45px !important;
//       height: 45px !important;
//     }
//   }

//   .forgotPassButton {
//     font-family: Poppins-Regular;
//     font-size: 14px;
//     background: var(--blue);
//     color: var(--white);
//     border-radius: 2px;
//     width: 203px;
//     height: 40px;
//     line-height: 19px;
//   }
// }
.existEmailSignIn {
  margin-left: 108px;
  @extend .regularFont;
}
.error_msg {
  @extend .regularFont;
  color: #EA0322;
  font-size: 12px;
  letter-spacing: 0.03333em;
  line-height: 1.66;
  margin-left: -8px;
}
.error_message{
  @extend .regularFont;
  color: #EA0322;
  font-size: 12px;
  letter-spacing: 0.03333em;
  line-height: 1.66;
  
}

.page-for {
  font-family: Poppins-SemiBold;
  color: $dark;
  top: 10px;
  position: relative;
}
.codeVerifyText {
  font-family: Poppins-SemiBold;
  color: $dark;
  top: 10px;
  position: relative;
  font-size: 34px;
  line-height: 46px;
}
.page-note {
  @extend .regularFont;
  font-size: 18px;
  color: $muted;
  max-width: 400px;
  text-align: center;
  margin: 20px 0;
}
.craeteAccount{
  // @extend .regularFont;
  @extend .semiBoldFont;
  font-size: 30px;
  // font-weight: 600;
  line-height: 41px;
  margin-left: -5px;
  color:#000000
}


// @media only screen and (max-width: 1142px) and (min-height: 630px) {
//   .mainAccountDiv {
//     position: absolute;
//     left: 417px;
//     top: -4px;
//     display: block;

//     height: fit-content;

//     width: fit-content;
//   }
//   .pageImage {
//     position: fixed;
//     width: 425px;
//     height: 641px;
//   }
//   .pageImageContainer {
//     width: 322px;
//     height: 345px;
//     position: relative;
//     margin-left: 56px;
//   }
// }
// @media only screen and (max-width: 765px) and (min-height: 630px) {
//   .mainAccountDiv {
//     position: fixed;

//     left: 29px;
//     top: 12px;
//     display: block;

//     height: fit-content;

//     width: 773px;
//   }

//   .pageImage {
//     .pageImageContainer {
//       display: none;
//     }
//     border: none;
//     z-index: 0;
//     .page-for,
//     .page-note {
//       display: none;
//     }
//     .fireCrowdIcon {
//       width: 228px;
//       position: relative;
//       top: -67px;
//       left: 85px;
//     }
//   }
//   .pageImageContainer {
//     width: 322px;
//     height: 345px;
//     position: relative;
//     margin-left: 56px;
//   }
// }
@media only screen and (max-width: 688px) and (min-height: 630px) {
  .mainAccountDiv {
    width: auto;
    position: absolute;
  }
  .formDiv {
    flex-direction: column;
  }

  .pageImage {
    .pageImageContainer {
      display: none;
    }
    border: none;
    z-index: 0;
    .page-for,
    .page-note {
      display: none;
    }
    .fireCrowdIcon {
      width: 415px;
      top: 171px;
      left: 48px;
      opacity: 0.1;
    }
  }
}
.infoErrorIcon{
  position: absolute;
  right: 2%;
  top: 36%;
}

@media only screen and (min-height: 768px) {
  .mainAccountDiv {
    position: fixed;

    left: 22%;
    top: 23px;
    display: block;

    height: fit-content;

    width: 773px;
  }

  .pageImage {
    .pageImageContainer {
      display: none;
    }
    border: none;
    z-index: 0;
    .page-for,
    .page-note {
      display: none;
    }
    .fireCrowdIcon {
      width: 228px;
      position: relative;
      top: -67px;
      left: 78%;
    }
  }
  .pageImageContainer {
    width: 322px;
    height: 345px;
    position: relative;
    margin-left: 56px;
  }
  
}

@media only screen and (min-width: 1200px) {
  .mainAccountDiv {
    position: absolute;
    left: 417px;
    top: -4px;
    display: block;

    height: fit-content;

    width: fit-content;
  }
  .pageImage {
    position: fixed;
    width: 425px;
    height: 641px;
  }
  .pageImageContainer {
    width: 322px;
    height: 345px;
    position: relative;
    margin-left: 56px;
  }
}

@media only screen and (min-width:1200px){
  .mail_send_activation_link {
    display: block;
    .mail_send_activation_link_leftImage{
      .leftImage{
        .logo_Icon{
          margin-top: 20px;
          width: 392px;
          height: 80px;
        }
      }
    }
    .heading {
      font-size: 34px;
      font-family: Poppins-SemiBold;
      color: #3c3838;
    }
    .msg {
      font-size: 18px;
      font-family: Poppins-Regular;
      color: #727171;
    }
  }
  .reset-password-page {
    .logoimg{
      display: none;
    }
    .righticon{
      position: absolute;
      left: 66%;
      top: 27%;
      display: flex;
      img{
        height: 18px;
      }
      .succcess_msg{
      font-size: 19px;
      font-family: "Poppins-SemiBold";
      color:#78B62F;
      }
    }
    .heading {
      font-weight: 500;
      font-size: 30px;
      font-family: "Poppins-SemiBold";
      color: #000000;
      position: absolute;
      left: 70%;
      top: 38%;
    }
    .msg_one{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #000000;
      position: absolute;
      left: 65.5%;
      top: 38%; 
    }
    .msg{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #000000;
      position: absolute;
      left: 64%;
      top: 41%;
    }
    
    
    .Back_link_text{
      font-size: 12px;
      font-family: "Poppins-Regular";
      color: #1648c6;
      cursor: pointer;
      position: absolute;
      left: 72%;
      top: 48%;
      span{
       &:hover{
        border-bottom: 1px solid  #1648c6;
       }
      }
    }
    .warning-division {
      width: 400px;
      .goodPass {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #79b62f;
      }
      .err_msg {
        font-size: 10px;
        font-family: Poppins-Regular;
        color: #ea0322;
      }
    }
    .activation_text {
      position: absolute;
      left: 63%;
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        color: var(--lightGreen);
        font-size: 18px;
        font-family: Poppins-SemiBold;
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 30px !important;
        height: 30px !important;
      }
    }
    .password_change_mail_alert {
      .warningAlert {
        background-color: transparent !important;
        margin-top: -64px;
        padding-bottom: 5px;
        font-family: Poppins-SemiBold;
        margin-left: 96px;
        .alertHeading {
          color: #79b62f;
          font-size: 18px;
        }
        .alertMsg {
          color: #727171;
          font-size: 15px;
        }
      }
      .alertIconActivationLink {
        color: #79b62f;
        margin-left: -13px;
        position: relative;
        left: 7px;
        width: 45px !important;
        height: 45px !important;
      }
    }
  
    .forgotPassButton {
      font-family: Poppins-Regular;
      font-size: 14px;
      background: var(--blue);
      color: var(--white);
      border-radius: 2px;
      width: 203px;
      height: 40px;
      line-height: 19px;
    }
  }

}