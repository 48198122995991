.progress-container {
    opacity: 0.5;
    // position: relative;
    z-index: 0;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 10;
    }
  }