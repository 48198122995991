.left-menu {
  background-color: #434db8;
  color: #ffffff;
  height: 100vh;
  z-index: 99;
  position: absolute;
  top: 0px;
  width: 200px;
}

.dropdownList {
  width: 15rem;
  margin-left: 12.8rem;
  margin-top: 1.2rem;
  background: #434db8;
  position: absolute;
  transition: all 0.2s linear;
  border-radius: 5px;
  z-index: 5;
}

.project-row-hover {
  background-color: #434db8;
  width: 100%;
  padding: 0px 15px;
  height: 36px;
  position: relative;
  user-select: none;
  cursor: pointer;
  color: #ffffff;
}

.input-field-font-style-head::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input-field-font-style-head {
  text-align: left;
  font: normal normal normal 14px/19px Segoe UI;
  letter-spacing: 0px;

  margin-top: 2px !important;
  opacity: 1;
  font-size: 14px;
  font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  margin-top: 4px;
  width: 100%;
  outline: none;
  position: relative;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  padding-top: 8.5px;
  padding-bottom: 12.5px;
  padding-left: 43px;
  border-bottom: 2px solid #ffffff;
  color: #ffffff;

}

#journal-scroll::-webkit-scrollbar,
.journal-scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  height: 4px;
}

#journal-scroll::-webkit-scrollbar-track,
.journal-scroll::-webkit-scrollbar-track {
  // background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  background-color: #e5e7eb;
}

#journal-scroll::-webkit-scrollbar-thumb,
.journal-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
}

::-webkit-scrollbar {
  width: 4px;
}

.searchedIcon {

  position: absolute;
  left: 11px;
  top: 11px;
  height: 15px !important;
  width: 19px !important;
  color: #fff !important;


}

.ChevronIcon {
  height: 23px !important;
  position: absolute;
  left: 168px;
  top: 74px;
}

.collapsed_ChevronIcon {
  height: 23px !important;
  position: absolute;
  left: 43px;
  top: 72px;
}


@keyframes slide-from-left {
  0% {
    margin-left: 10%;
    opacity: 0;
  }

  100% {
    margin-left: 0;
    opacity: 1;
  }
}

.liftCircle {
  // height: 36px;
  // position: absolute;
  // right: -17px;
  // top: 6px;
  height: 36px;
  position: fixed;
  left: 180px;
  top: 6px;
  animation: slide-from-left 0.1s ease-in;

  svg {
    height: 34px;
    width: 39px;
  }
}

.active-drawer-close {
  float: left !important;
  padding: 10px 13px !important;
  height: 46px !important;
  width: 190px;
  // width: 205px;
  position: relative !important;
  background: #fff !important;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  filter: drop-shadow(0px 0px 1px white) !important;
  margin-left: 10px;

  .Defect_icon {
    svg {
      height: 28px;

      path {
        fill: #434DB8
      }

    }
  }

  .config_icon {
    padding-top:4px;
    svg {
      height: 20px;




      line {
        stroke: #434DB8;
      }

      path {
        fill: #434DB8;
      }
    }
  }

  .user_icon {
    svg {
      height: 28px;
      width: 23px;
    }
  }
  .dashboard_icon{
    svg{
      height: 23px;
      width: 28px;
    }
  }

  .all_project_icon{
    svg {
      // height: 17px;
      // width: 25px;
      height: 30px;
     width: 30px;
      margin-top: -7px;

      


      line {
        // stroke: #434DB8;
      }

      path {
         fill: #434DB8;
      }
    }
    
  }


  .overview_icon {
    svg {
      height: 17px;
      width: 25px;
     

      


      line {
        // stroke: #434DB8;
      }

      path {
         fill: #434DB8;
      }
    }
  }

  .user_icon {
    svg {
      height: 28px;
      width: 23px;
      path{
        stroke: #434DB8;
      }
    }
  }

  .testcase_icon {
    svg {
      height: 25px;
    }

    path {
      fill: #434DB8;

    }

    line {
      stroke: #434DB8;
    }
  }
}

.report_icon {
  svg {
    height: 25px;
    width: 24px;

  }

}

// .active-drawer-close {
//     @extend .active-drawer;
//     width: 54px !important;
//     left: 11px !important;
// }

.active-drawer-close:before,
.active-drawer-close:after {
  content: "";
  position: absolute;
  height: 20px;
  width: 22px;
  bottom: 0;
  background: radial-gradient(circle at top left, #fff0 72%, #fff 75%);
}

.active-drawer-close:after {
  right: 0;
  top: 100%;
  background: radial-gradient(circle at bottom left, #fff0 70%, #fff 75%);
}

.active-drawer-close:before {
  bottom: 100%;
  right: 0;
}

.dropdownList2 {
  width: 15rem;
  margin-left: 4.5rem;
  margin-top: 1.3rem;
  background: #434db8;
  position: absolute;
  transition: all 0.2s linear;
  border-radius: 5px;
  z-index: 5
}

.dropdownList3 {
  //  transition: all 0.2s linear;
}

.dropdownList4 {
  transition: all 0.2s linear;
  border-radius: 5px;
}

.active-drawer-close2 {
  float: left !important;
  padding: 10px 13px !important;
  height: 46px !important;
  // width: 55px;
  width: 60px;
  margin-left: 8px;
  position: relative !important;
  background: #fff !important;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  filter: drop-shadow(0px 0px 1px white) !important;
  transition: all 0.2s ease-in-out;
  transition-delay: 0.1s;
  transition-duration: 0.1s;

  .Defect_icon {
    width: 40px;

    svg {
      height: 28px;

      path {
        fill: #434DB8;
      }
    }
  }

  .config_icon {
    svg {
      height: 20px;

      path {
        fill: #434DB8
      }
    }
  }

  .testcase_icon {
    width: 40px;
  }

  .report_icon {
    width: 42px;
  }
  .user_icon {
    width:38px;
    svg {
      height: 28px;
      width: 23px;
      path{
        stroke: #434DB8;
      }
    }
  }




}

.active-drawer-close2:before,
.active-drawer-close2:after {
  content: "";
  position: absolute;
  height: 30px;
  width: 32px;
  bottom: 0;
  background: radial-gradient(circle at top left, #fff0 72%, #fff 75%);


}

.active-drawer-close2:after {
  right: 0;
  top: 100%;
  background: radial-gradient(circle at bottom left, #fff0 70%, #fff 75%);

}

.active-drawer-close2:before {
  bottom: 100%;
  right: 0;

}




.overView {
  position: relative;
  top: -22px;
  left: 23px;
}

.allProjectDrawer {
  margin-left: 2px;
  width: 205px;
}

.allProject {

  padding: 9px 15px;
  background: #303AA2;

  width: 207px;

  &.expand_menu {
    padding: 9px 15px;
  }

  &.collapse_menu {
    padding: 9px;
  }
}


@keyframes slide-from-right {
  0% {
    left: 100%;
    opacity: 0;
  }

  100% {
    left: 50%;
    opacity: 1;
  }
}

.collapsedCircle {
  position: fixed;
  // top: 6px;
  // left: 36px;
  top: 6px;
  left: 48px;
  animation: slide-from-right 0.2s ease-in;

  svg {
    height: 34px;
    width: 39px;
  }

}

.arrowRight {
  margin-top: -7px;
  height: 19px !important;
  margin-left: -1px;
}

.left-menu-items-unselect {
  // font-family:"Poppins-SemiBold";
  // color:#FFFFFF;
  // font-size: 16px;
  font-family: "Poppins-Regular";
  color: #FFFFFF;
  font-size: 14px;
  float: left !important;
  padding: 10px 25px !important;
  height: 46px !important;
  // width: 196px;
  width: 185px;
  position: relative !important;
  background: #434DB8 !important;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  // margin-left:-2px;

  .label {
    padding-top: 2px
  }

  .Defect_icon {

    svg {
      height: 28px;

    }
  }



  .user_icon {
    svg {
      height: 28px;
      width: 23px;
    }
  }
  .dashboard_icon{
    svg{
      height: 23px;
      width: 22px; 
      path:nth-child(2){
        fill:white
      }
    }
  }

  .all_project_icon{
    svg {
      height: 30px;
      width: 30px;
      margin-top:-6px;
      margin-left: -4px;
      


      line {
        // stroke: #434DB8;
      }

      path {
         fill:#fff;
      }
    }
    
  }


  .overview_icon {
    svg {
      height: 17px;
      width: 25px;
      path {
         fill: white;
      }
    }
  }
  .config_icon{
    padding-top: 4px;
    
  }
  .config_label{
    padding-top:3px
  }
  .testcase_icon {
    svg {
      height: 25px;
    }

    path {
      fill: white;

    }

    line {
      stroke: white;
    }
  }

  &:hover {
    color: #FFFFFF;
    font-size: 14px;
    font-family: "Poppins-Regular";
    width: 200px;

    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

    background: #303aa2 !important;

    // filter: drop-shadow(0px 0px 1px white) !important;
    &::after,
    &::before {
      content: "";
      position: absolute;
      height: 20px;
      width: 22px;
      bottom: 0;

    }

    &::after {
      right: 0;
      top: 100%;
    }

    &::before {
      bottom: 100%;
      right: 0;
      ;

    }

    .user_icon {
      svg {
        height: 28px;
        width: 23px;
      }
    }
    .dashboard_icon{
      svg{
        height: 23px;
        width: 22px;
        path:nth-child(2){
          fill:white
        }
      }
    }

    .all_project_icon{
      svg {
        height: 30px;
        width: 30px;
        margin-top:-6px;
  
        
  
  
        line {
          // stroke: #434DB8;
        }
  
        path {
           fill: #fff;
        }
      }
      
    }


    .overview_icon {
      svg {
        height: 17px;
        width: 25px;
      
        


        line {
          // stroke: #FFFF;
        }

        path {
           fill: #FFFF;
        }
      }
    }

    .testcase_icon {
      svg {
        height: 25px;
      }

      path {
        fill: #FFFF;

      }

      line {
        stroke: #FFFF;
      }
    }

    .report_icon {
      svg {
        height: 24px;
        width: 25px;

      }
    }
  }


}

.left-menu-items-select {
  font-family: "Poppins-Regular";
  color: #434DB8;
  // font-size: 16px;
  font-size: 14px;

}

.project-dropdown {
  // font-family:"Poppins-SemiBold";
  // font-size: 15px;
  font-family: "Poppins-Regular";
  font-size: 14px;
}

#serachInputField::placeholder {
  font-family: "Poppins-Regular";
  font-size: 14px;
  // color:#FFFFFF;
  color: #7079D6;
}

.project_name {
  font-family: "Poppins-SemiBold";
  color: #FFFFFF;
  font-size: 16px;
  padding-top: 2px;

}

.testcase_icon {
  svg {
    height: 25px;
  }

  path {
    fill: #434DB8;

  }

  line {
    stroke: #434DB8;
  }
}

.config_icon {
  svg {
    height: 20px;
    // stroke:#434DB8;


    line {
      stroke: #434DB8;
    }

    path {
      fill: #FFFFFF;
    }
  }
}

.user_icon {
  svg {
    height: 28px;
    width: 23px;
  }
}
.dashboard_icon{
  svg{
    height: 23px;
    width: 28px;
  }
}

.all_project_icon{
  svg {
    height: 30px;
    width: 30px;
    margin-top:-6px;

    


    line {
      // stroke: #434DB8;
    }

    path {
       fill: #434DB8;
    }
  }
  
}

.all_project_icon{
  svg {
    height: 30px;
    width: 30px;
    margin-top:-6px;

    


    line {
      // stroke: #434DB8;
    }

    path {
       fill: #434DB8;
    }
  }
  
}
.all_project_icon{
  svg {
    height: 30px;
    width: 30px;
    margin-top:-6px;

    


    line {
      // stroke: #434DB8;
    }

    path {
       fill: #434DB8;
    }
  }
  
}

.overview_icon {
  svg {
    height: 17px;
    width: 25px;


    line {
      // stroke: #434DB8;
    }

    path {
       fill: #434DB8;
    }
  }
}

.report_icon {
  svg {
    height: 24px;
    width: 25px;

  }

}

.projectname-trimmer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}

.configSubMenu {
  width: 15rem;
  background: #434db8;
  position: absolute;
  transition: all 0.2s linear;
  border-radius: 5px;
  margin-top: 20%;
}

.configMenuMinimized {
  width: 37px !important;
  text-align: start;
  margin-left: -5px;

  &:hover {
    width: 70px !important;
    text-align: start;
  }
}

.config_text {
  position: relative;
  top: -19px;
  left: 2px
}

.configImportSubMenu {
  @extend .configSubMenu;
  left: 452px;
  top: 154px;
}

.configImportSubMenu_Minimized {
  left: 303px;
  top: 148px;
}

#serachInputField::placeholder {
  color: #fff;
}


.left-menu-unselect-items {
  font-family: "Poppins-Regular";
  color: #FFFFFF;
  font-size: 14px;
  float: left !important;
  padding: 10px 20px !important;
  height: 46px !important;
  // width: 196px;
  width: 37px;
  position: relative !important;
  background: #434DB8 !important;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  // margin-left:-2px;

  .label {
    padding-top: 2px
  }

  .config_icon {
    svg {
      height: 20px;


      path {
        // fill: white;
        stroke: white;
      }
    }
  }

  .Defect_icon {
    svg {
      height: 28px;

    }
  }

  .user_icon {
    svg {
      height: 28px;
      width: 23px;
    }
  }
  .dashboard_icon{
    svg{
      height: 23px;
      width: 28px;
      path:nth-child(2){
        fill:white
      }
    }
  }

  .all_project_icon{
    svg {
      height: 30px;
      width: 30px;
      margin-top:-6px;

      


      line {
        // stroke: #434DB8;
      }

      path {
         fill: #434DB8;
      }
    }
    
  }


  .overview_icon {
    svg {
      height: 17px;
      width: 25px;
      
     

      path {
         fill: white;
      }
    }
  }

  .testcase_icon {
    svg {
      height: 25px;
    }

    path {
      fill: white;

    }

    line {
      stroke: white;
    }
  }

  .report_icon {
    svg {
      height: 24px;
      width: 25px;

    }
  }

  &:hover {
    color: #FFFFFF;
    font-size: 14px;
    font-family: "Poppins-Regular";
    width: 200px;

    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

    background: #303aa2 !important;

    // filter: drop-shadow(0px 0px 1px white) !important;
    &::after,
    &::before {
      content: "";
      position: absolute;
      height: 20px;
      width: 22px;
      bottom: 0;

    }

    &::after {
      right: 0;
      top: 100%;
    }

    &::before {
      bottom: 100%;
      right: 0;
      ;

    }

    .config_icon {
      svg {
        height: 20px;

        path {
          // fill: #434DB8;
          stroke: #FFFF;
        }
      }
    }

    .user_icon {
      svg {
        height: 28px;
        width: 23px;
      }
    }
    .dashboard_icon{
      svg{
        height: 23px;
        width: 28px;
        path:nth-child(2){
          fill:white
        }
      }
    }
    .all_project_icon{
      svg {
        height: 30px;
        width: 30px;
        margin-top:-6px;
  
        
  
  
        line {
          // stroke: #434DB8;
        }
  
        path {
           fill: #434DB8;
        }
      }
      
    }

    .overview_icon {
      svg {
        height: 17px;
        width: 25px;
      
        

        path {
           fill: #FFFF;
        }
      }
    }

    .testcase_icon {
      svg {
        height: 25px;
      }

      path {
        fill: #FFFF;

      }

      line {
        stroke: #FFFF;
      }
    }

    .report_icon {
      svg {
        height: 24px;
        width: 25px;

      }
    }
  }


}

.fire_crowd_logo {
  svg {
    height: auto;
    width: 40px;
    margin-top: 5px;
    margin-left: 11px;
    padding-top: 4px;

  }
}

.fireflink_rights {
  letter-spacing: 0;
  background: #303aa2;
  bottom: 0;
  position: fixed;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  font-family: "Poppins-Regular";

  &.all_label {
    width: 200px;
    transition: all 0.2s ease-in-out;
  transition-delay: 0.1s;
  transition-duration: 0.1s;
  }

  &.logo_label {
    width: 65px;
    transition: all 195ms ease-in-out;
   transition-delay: 0s;
  // transition-duration: 0.1s;
  }
}