.logo-background {
  background-color: #434db8;
  .fireflink_crowd_logo{
    svg{
      
    height: auto;
    width:90%;
    margin-top:5px;
    }
  }
}
.header {
  background-color: #f2f3f3;
  // background-color: #ffff;
  // box-shadow: 0px 7px 6px #0000001A;
  height: 41px;
}
.bell-icon > svg {
  height: 25px;
}
#user-icon >.MuiIconButton-label >svg{
  height: 31px;
}
.logout_username{
  font-size: 15px;
  font-family: 'Poppins-Medium';
  color:#525252
}
.username_datatrimmer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  
}
.user_profile{
  font-size: 15px;
  font-family: 'Poppins-Medium';
  color:#525252;
  width:100%;
  height: 46px;
  &:hover{
    background: #434db8;
    color:#f2f3f3;
    path{
      fill:#f2f3f3
    }
  }
}
.userProfile_icon{
  svg{
    height: 21px;
    width:20px;
  }
}