.userTemplate_data {
  font-family: "Poppins-SemiBold";
  color: #434db8;
  background: white;
}
.user_template_page {
  
    height: 93vh;
    overflow:hidden;
  
  .header {
    height: 45px;
    background: #ffffff;
    padding-top: 8px;
  }
  .heading {
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    color: #3c3838;
    text-transform: capitalize;
  }
  .user_template_heading {
    .items {
      .each_hierarchy {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 15px #0000000d;
        border: 1px solid #70707017;
        border-radius: 5px;
        .each_role {
          width: 80%;
          margin-top: 5px;
          margin-bottom: 5px;
          padding: 5px;
          height: 71px;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 6px rgb(0 0 0 / 16%);
          border-radius: 7px;
          .role_name {
            text-align: center;
            font-size: 15px;
            letter-spacing: 0px;
            color: #434db8;
            opacity: 1;
          }
        }
      }
      .hierarchy_desc {
        text-align: center;
        font-size: 15px;
        font-family: Poppins;
        letter-spacing: 0px;
        color: #3c3838;
        opacity: 1;
      }
    }
  }
  .chart-container {
    width: 100%;
    height: 1000px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    margin-top: 50px;
  }
}
.actionIcons {
  visibility: hidden;
}
.configHover:hover .actionIcons {
  visibility: visible;
}
.addTempBtn {
  background: #ecedf8;
  font-family: "Poppins-Medium";
  color: #434db8;
  border-radius: 21px;
  font-size: 12px;
  text-align: center;
  height: 34px;
  border-bottom: 1px solid #ecedf8;
  border-top: 1px solid #ecedf8;
  border-right: 1px solid #ecedf8;
  position: absolute;
  width: 130px;
  right: 7px;
}
.dataShowText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
}
.editIcon {
  svg {
    height: 15px;
    width: 15px;
  }
  & :hover {
    cursor: pointer;
    path {
      fill: #434db8;
    }
  }
}
.deleteIcon {
  svg {
    height: 15px;
    width: 15px;
  }
  & :hover {
    cursor: pointer;
    path {
      fill: #434db8;
    }
  }
}
.filed_btn {
  height: 24px;
  width: 67px;
  border: 1px solid #ffffff;
  padding: 1px;
  border-radius: 3px;
  color: #ffffff;
  font-family: "Poppins-regular";
  font-size: 12px;
}
.defect-template-modal-heading {
  background-color: #434db8;
  color: #ffffff;
  font-family: "Poppins-Medium";
  font-size: 16px;
}
.defect-filter-modal-heading{
  background-color: #434db8;
  color: #ffffff;
  font-family: "Poppins-Medium";
  font-size: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

}
.defect-template-modal-values {
  color: #8f8e8e;
  font-family: "Poppins-regular";
  font-size: 14px;
}
.errorMessage {
  font-family: "Poppins-Regular";
  color: #c50303;
  font-size: 12px;
}
#templateName {
  height: 6px;
}
#selectCustomField {
  font-size: 12px;
  color: #8f8e8e;
  font-family: "Poppins-regular";
}
#textfield::placeholder {
  font-size: 12px;
  color: #8f8e8e;
  font-family: "Poppins-regular";
}
.defect-template-btn-label {
  color: #8f8e8e;
  font-family: "Poppins-regular";
  font-size: 14px;
}
//text editor css
.public-DraftEditor-content:focus {
  border: 2px solid #1648c6;
}
.public-DraftEditor-content {
  border: 1px solid #989797;
  border-radius: 5px;
  // min-height: 133px;
  padding: 0 8px;
  height: 150px;
  overflow-y:scroll;
}
.rdw-link-modal {
  height: 230px;
}
.heading-TextEditor-style {
  h1 {
    font-size: 2em;
    font-weight: bolder;
  }
  h2 {
    font-size: 1.5em;
    font-weight: bolder;
  }
  h3 {
    font-size: 1.17em;
    font-weight: bolder;
  }
  h4 {
    font-size: 1em;
    font-weight: bolder;
  }
  h5 {
    font-size: 0.83em;
    font-weight: bolder;
  }
  h6 {
    font-size: 0.67em;
    font-weight: bolder;
  }

  .rdw-right-aligned-block.public-DraftStyleDefault-orderedListItem:before {
    position: relative !important;
    left: 0 !important;
  }
  .rdw-left-aligned-block.public-DraftStyleDefault-orderedListItem:before {
    position: relative !important;
    left: 0 !important;
  }
  .rdw-center-aligned-block.public-DraftStyleDefault-orderedListItem:before {
    position: relative !important;
    left: 0 !important;
  }
  .rdw-justify-aligned-block.public-DraftStyleDefault-orderedListItem:before {
    position: relative !important;
    left: 0 !important;
  }
  .rdw-right-aligned-block.public-DraftStyleDefault-unorderedListItem {
    list-style-position: inside;
  }
  .rdw-left-aligned-block.public-DraftStyleDefault-unorderedListItem {
    list-style-position: inside;
  }
  .rdw-center-aligned-block.public-DraftStyleDefault-unorderedListItem {
    list-style-position: inside;
  }
  .rdw-justify-aligned-block.public-DraftStyleDefault-unorderedListItem {
    list-style-position: inside;
  }
}

.toolbarClass {
  font-size: 12px;
  font-family: OpenSans-Regular, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif;
}

.editorClass {
  font-size: 12px;
  font-family: OpenSans-Regular;
  // height:auto;
}
.main-content-defect {
  overflow: scroll;
  max-height: 565px;
  min-height: 400px;
}
.textarea_content_style_Defect {
  border: 1px solid #989797;
  border-radius: 5px;
  height: 133px !important;
  max-height: 133px;
  resize: none;
  overflow: scroll !important;
}
.platformTableText {
  font-size: 12px;
  font-family: "Poppins-Medium";
  color: #525252;
}
.icon_search_platform {
  background: #ecedf8;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  padding: 4px;
  margin-top: 2px;
  margin-left: 94%;
  color: #434db8;
  font-size: 12px;
}

.table_search_platform {
  border: 2px solid #4169e1;
  background: #ecedf8;
  border-radius: 16px;
  height: 33px;
  padding: 2px 4px;
  transition: 0.3s linear;
  position: relative;
  left: -51px;
  .search_input {
    background: #ecedf8;
    width: 94%;
    height: 27px;
    border-radius: 12px;
    // padding-left: 2px;
    font-family: OpenSans-SemiBold;
    font-size: 12px;
    &:focus {
      outline: none;
    }
  }
  .icon_close {
    background: #4169e1;
    border-radius: 12px;
    color: #fff;
    font-size: 12px;
    height: 23px;
    width: 22px;
    svg {
      height: 17px;
      width: 22px;
    }
  }
  .icon_search_input {
    background: #ecedf8;
    border-radius: 12px;
    color: #434db8;
    font-size: 12px;
    svg {
      height: 21px;
      width: 25px;
    }
  }
}
.project_list_body_platform{
  // height: auto;
  // overflow: hidden;
  height: 85vh;
  overflow: scroll;
}
.platFormContainer{
  height:auto;
  background: #ffffff;
  box-shadow: 0px 0px 15px #0000001a;
  border-radius: 5px;
  padding: 10px;
  font-family: "Poppins-Medium";
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
    .platFormNameDiv{
      border-right-width: 2px;
      padding: 16px;
      width: 270px;
     .platFormName{
      justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-family: "Poppins-Regular"; 
    overflow-wrap: anywhere;

      
      }
      .editPlatformIcon{
        display: flex;
        justify-items: end;
        align-items: start;
        margin-left: auto;
        // margin-top: -12px;
        
      }
      .nameTextField{
        margin-top: 46px;
        margin-left: 5px;
        font-size: 14px;
      
        .css-1x51dt5-MuiInputBase-input-MuiInput-input{
          font-size: 14px;
          font-family: "poppins-Regular";
        }
      }
      .nameTextFieldMobile{
        margin-top: 28px;
        margin-left: 5px;
        font-size: 14px;
        .css-1x51dt5-MuiInputBase-input-MuiInput-input{
          font-size: 14px;
          font-family: "poppins-Regular";
        }
      }
    }
    .platFormDetailsDiv{
      width: 100%;
      padding: 10px;
      .label {
        color: #434db8;
    }
    .value {
      color: #525252;
    }





    }
}
.noDefectsIcon{
  top: 55%;
  left: 57%;
  transform: translate3d(-50%,-50%, 0);
  position: absolute;
}

// testcase template deatils css
.tc-template-details{
  .test_case {
    height: 50px;
    background: #ffffff;
    padding-top: 8px;
    .heading {
      font-family: "Poppins-SemiBold";
      font-size: 18px;
      color: #3c3838;
      cursor: default;
    }
    .testcase_btn{
    font-family: "Poppins-Medium";
    background: #ecedf8;
    color: #434db8;
    border-radius: 21px;
    font-size: 12px;
    text-align: center;
    height: 34px;
    border-bottom: 1px solid #ecedf8;
    border-top: 1px solid #ecedf8;
    border-right: 1px solid #ecedf8;
    position: absolute;
    // width: 85px;
    // right: 7px;
    }
    .back_btn{
      width: 85px;
    // right: 7px;
    right: 1%;
    }
    .addfield_btn{
      width: 113px;
      right: 7px;
    }
    .add_icon{
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }

}
.testcase_details{
  // height: 250px;
  height: auto;
  overflow-y: auto;
  margin-bottom: 10px;
}
.contentDataStyle {
  width: 100%;
  letter-spacing: 0px;

}
.tc_subheading{
  font-family: "Poppins-SemiBold" ;
  font-size:18px ;
  color:#3C3838
}
.tc_lables{
  font-family: "Poppins-Regular" ;
  font-size:14px ;
  color:#8F8E8E
}
.defect-template-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 350px;
}