.reportDetails {
  font-family: "Poppins";

  .heading {
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    color: #3c3838;
    margin-bottom: 10px;
  }

  .defecttableOutline {
    background: #ffffff;
    border: 1px solid #434db8;
    border-radius: 10px;
    height: auto;
    opacity: 1;

    .reportTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      height: auto;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      table {
        border-spacing: 0;
        border-top: 1px solid #707070;

        tbody {
          max-height: 399px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          overflow-y: auto !important;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;

          :last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}

.spanedreportDetails {
  max-height: 521px;
  overflow: auto;
  font-family: "Poppins";

  .heading {
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    color: #3c3838;
    margin-bottom: 10px;
  }

  .defecttableOutline {
    background: #ffffff;
    border: 1px solid #434db8;
    border-radius: 10px;
    max-height: 500px;
    overflow: auto;
    opacity: 1;

    .reportTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 440px;
      overflow: auto;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      table {
        border-spacing: 0;
        border-top: 1px solid #707070;

        // tbody {
        //   max-height: 399px;
        // }

        // tr {

        //   :last-child {
        //     border-right: 0px;
        //   }
        // }
        // th,
        // td {
        //   border-bottom: 1px solid #707070;
        //   border-right: 1px solid #707070;
        //   :last-child {
        //     border-right: 0;
        //   }
        // }
        tbody [rowspan],
        tbody [rowspan]~th,
        tbody [rowspan]~td,
        tfoot {
          border-bottom: 1px solid #707070;
        }

      }
    }
  }
}

.defectDetails {
  font-family: "Poppins";

  .heading {
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    color: #3c3838;
    margin-bottom: 10px;
  }

  .defecttablelistOutline {
    background: #ffffff;
    height: auto;
    opacity: 1;

    .defectTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 441px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      table {
        border-spacing: 0;

        // border-top: 1px solid #707070;
        tbody {
          max-height: 399px;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {

          // border-bottom: 1px solid #707070;
          // border-right: 1px solid #707070;
          :last-child {
            border-right: 0;
          }
        }
      }
    }

    .rejectedTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 441px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      table {
        border-spacing: 0;

        // border-top: 1px solid #707070;
        tbody {
          max-height: 399px;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;

          :last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}

.tcDetails {
  font-family: "Poppins";

  .heading {
    // font-family: "Poppins-SemiBold";
    // font-size: 18px;
    // color: #3c3838;
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    margin-top: -10px;
  }

  .sub-heading {
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    color: #3c3838;
    margin-top: 15px;
    margin-left: -134px;
  }

  .defecttablelistOutline {
    background: #ffffff;
    height: auto;
    opacity: 1;
    border-radius: 4px;

    .defectTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 441px;
      margin: 3px;

      .testcase_log_theader {
        background: #F2F2F2;
      }

      // border-top: 1px solid #000000;

      table {
        border-spacing: 0;

        // border-top: 1px solid #707070;
        tbody {
          max-height: 399px;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {

          // border-bottom: 1px solid #000000;
          // border-right: 1px solid #000000;
          :last-child {
            border-right: 0;
          }
        }
      }
    }

    .rejectedTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 441px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      table {
        border-spacing: 0;

        // border-top: 1px solid #707070;
        tbody {
          max-height: 399px;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;

          :last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  .reporttablelistOutline {
    background: #ffffff;
    height: auto;
    opacity: 1;

    .reportTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 410px;
      border: 1px solid #707070;
      overflow: scroll;

      table {
        border-spacing: 0;

        // border-top: 1px solid #707070;
        tbody {
          max-height: 399px;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;
          color: #525252;
          ;

          :last-child {
            border-right: 0;
          }
        }
      }
    }

    .rejectedTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 441px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      table {
        border-spacing: 0;

        // border-top: 1px solid #707070;
        tbody {
          max-height: 399px;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;

          :last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}

.darkBlue {
  color: #434db8;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}

.filterIcon {
  background: white;
  height: 31px;
  width: 32px;
  border-radius: 30px;
  padding: 5px;
  margin-right: 5px;
  color: #434db8;
  // margin-top: -14px;
  // margin-left:48px;

  &:hover {
    background: white;
    height: 31px;
    width: 32px;
    border-radius: 30px;
    padding: 5px;
    margin-right: 5px;
    color: #434db8;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    // margin-top: -14px;
    // margin-left:48px;
  }
}

.testcasefilterIcon {
  background: white;
  height: 31px;
  width: 32px;
  border-radius: 30px;
  padding: 5px;
  margin-right: 5px;
  color: #434db8;
  margin-top: -14px;
  margin-left: 48px;

  &:hover {
    background: white;
    height: 31px;
    width: 32px;
    border-radius: 30px;
    padding: 5px;
    margin-right: 5px;
    color: #434db8;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    margin-top: -14px;
    margin-left: 48px;
  }
}

.downloadIcon {
  background: white;
  width: 31px;
  height: 32px;
  border-radius: 17px;
  margin-right: 5px;
  padding-left: 3px;
  margin-top: 6px;
  padding-top: 2px;

  &:hover {
    background: white;
    width: 31px;
    height: 32px;
    border-radius: 17px;
    margin-right: 5px;
    margin-top: 6px;
    padding-left: 3px;
    margin-top: 6px;
    padding-top: 2px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  }
}

.text-2xII {
  top: 176px;
  left: 506px;
  width: 79px;
  /* UI Properties */
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-32) / var(--unnamed-line-spacing-48) var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-434db8);
  text-align: left;
  font: normal normal 600 20px Poppins-SemiBold;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
}

.darkBlue-icon {
  width: 53px;
  height: 64px;
  margin-top: 13px;
  opacity: 1;

}

.common_darkBlue_icon {
  width: 53px;
  height: 38px;
  margin-top: 4px;
  opacity: 1;
}

.darkBlue-expand {
  color: #434db8;
  width: 12px;
  height: 12px;
  margin-top: 5px;
}

.selectedDefects {
  border-bottom: 1px solid #434db8;
  font-family: "Poppins-Regular";
}

.reportBorder {
  border: 1px solid #707070;
}

.reportBugIdColumn {
  text-align: center;
  letter-spacing: 0px;
  color: #3c3838;
  opacity: 1;
}

.reportTableheader {
  letter-spacing: 0px;
  font-size: 12px;
  // color: #3c3838;
  color: #8F8F8F;
  opacity: 1;
}

.reportTablecell {
  letter-spacing: 0px;
  font-size: 12px;
  opacity: 1;
}

.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px !important;
}

.popins {
  font-family: "Poppins-Regular";
}


.card {
  height: 105px;
  //  width: 185px;
  // width:214px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #434db8;
  border-radius: 10px;
  opacity: 1;
}

.image_card {
  margin-top: 14px;
  margin-left: 15px;
}

.decription_card {
  text-align: right;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
  margin: -1px;
  // width:152px


}

.description_details {
  margin-top: -63px;
}

.content_card {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
  text-align: right;
}

.column {
  float: left;
  width: 50%;
  height: 100px;
}

.total-defects {
  top: 844px;
  left: 506px;
  width: 103px;
  height: 35px;
  font-size: 20px;
  font-family: "Poppins-Medium";
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-434db8);
  text-align: left;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
  margin-left: 120px;
}

.donutChart_lables {
  font-size: 20px;
  font-family: "Poppins-Medium";
  color: #434db8;
}

.row-style {
  margin-top: 230px;
}

.column-icon {
  width: 12px;
  // margin-left: 500px;
}

.row-top {
  margin-top: 10px;
}

.chart-align {
  margin-top: 30px;
}

.column-iconlocation {
  // width: 12px;
  margin-left: 61%;
  // margin-top: 0px;
}

.select-preference {
  top: 384px;
  left: 456px;
  width: 95px;
  height: 35px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-25) / var(--unnamed-line-spacing-38) var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-434db8);
  text-align: left;
  font: normal normal bold 20px/38px Poppins;
  letter-spacing: 0px;
  color: #434db8;
  opacity: 1;
}

.expantdonutchart_label {
  font-size: 40px;
  color: #434db8;
  font-family: "Poppins-Regular";
  padding-left: 39%;
}

.Defect-Log {
  top: 1429px;
  left: 260px;
  width: 100%;
  height: auto;
  /* UI Properties */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-707070);
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top: 1px solid #707070;
  border-left: 1px solid #707070;
  border-right: 1px solid #707070;
  opacity: 1;
  margin-top: 50px;

  .search-icon {
    margin-left: 950px;
  }

  .tablecontainer {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-707070);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
  }
}

.TCReport-Log {
  top: 1429px;
  left: 260px;
  width: 100%;
  height: auto;
  /* UI Properties */
  // background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  // border: 1px solid var(--unnamed-color-707070);
  // background: #ffffff 0% 0% no-repeat padding-box;

  // border: 1px solid #000000;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #EBEBEB;
  border-radius: 5px;
  opacity: 1;
  margin-top: 11px;

  .search-icon {
    margin-left: 950px;
  }

  .tablecontainer {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-707070);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
  }
}

.revert {
  display: revert !important;
}

.executive-report {
  top: 1429px;
  left: 260px;
  // width: 100%;
  height: auto;
  /* UI Properties */
  // background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  // border: 1px solid var(--unnamed-color-707070);
  // background: #ffffff 0% 0% no-repeat padding-box;
  // border: 1px solid #707070;
  background: #F8F9FF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #272D3B1A;
  border: 2px solid #FFFFFF;
  border-radius: 8px;
  opacity: 1;
  margin-top: 50px;
  margin-right: 1%;

  .executive-report-content {
    min-height: 215px;

    .executivereports_header-style {
      font-size: 14px;
      font-family: "Poppins-Medium";
      color: #333333;

      .header_text {
        width: 97%
      }

      // .expand_icon {
      //   background: blue;

      //   border-radius: 15px;
      //   height: 26px;
      //   width: 25px;
      //   color: white;
      // }
    }

    .modal {
      display: block;

      position: absolute;
      z-index: 1;
      padding-top: 100px;
      left: 28%;
      top: 0;
      width: 76.5%;
      height: 23.5%;

      margin-left: 12%;
      border-radius: 5px;
    }


    .modal-content {
      background: #DFDED4;
      border-radius: 41px;
      height: 40px;

      display: flex;
      justify-content: center;
      padding-top: 9px;


      border: 1px solid #DFDED4;;
      width: 26%;

      margin-top: -2%;
      cursor: pointer;
      font-family: "Poppins-Regular";
      font-size: 14px;

    }




    .public-DraftEditor-content {
      border: 1px solid #C1C1C1;
      border-radius: 5px;
      min-height: 133px;
      padding: 0 8px;
      background: #FFFFFF;
    }
  }

  .expand_executive-report-content {
    min-height: 215px;

    .executivereports_header-style {
      font-size: 14px;
      font-family: "Poppins-Medium";
      color: #333333;

      .header_text {
        width: 97%
      }

      .expand_icon {
       
        color: #5681fb;
        border-radius: 15px;
        height: 26px;
        width: 26px;
        cursor: pointer;

        

      }
    }

    .modal {
      display: none;
      position: absolute;
      z-index: 1;
      padding-top: 100px;
      left: 24%;
      top: 0;
      width: 76.5%;
      height: 23.5%;
      margin-left: 12%;
      border-radius: 5px;
    }

    /* Modal Content */
    .modal-content {
      border: 1px solid #F8F3E8;
      width: 26%;
      margin-top: -2%;
      background: #F8F3E8;
      border-radius: 41px;
      height: 40px;
      display: flex;
      justify-content: center;
      padding-top: 1%;
      cursor: pointer;

    }



    .public-DraftEditor-content {
      border: 1px solid #C1C1C1;
      border-radius: 5px;
      min-height: 500px;
      padding: 0 8px;
      background: #FFFFFF;
    }

  }
}

.space-content {
  margin-left: 20px;
}

.header-style {
  width: 200px;
}

.report-container {
  left: 434px;
  width: 966px;
  height: 298px;
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-3e3e3e);
  text-align: left;
  letter-spacing: 0px;
  color: #3e3e3e;
  opacity: 1;
  margin-left: 38px;
  margin-top: 50px;
}

.dropdownList3 {
  margin-bottom: 0px !important;
}

.pt-5.pb-6.pl-3.flex.items-center {
  height: 44px;
}

.pt-5.pb-6.pl-3.flex.tc-center {
  height: 40px;
}

.report-capsule {
  width: 82px;
  height: 25px;
  background: #ebebeb 0% 0% no-repeat padding-box;
  border: 1px solid #434db8;
  border-radius: 20px;

  .selected {
    background: #434db8 0% 0% no-repeat padding-box;
    border-radius: 20px;
    color: #FFFFFF;
  }
}

.reportTableScrollbar table tbody tr {
  width: calc(100% + 4px);
}

.noDefects_defectlist {
  // top: 200%;
  // left: 57%;
  // transform: translate3d(-50%,-50%, 0);
  // position: absolute;
  left: 83%;
  transform: translate3d(-50%, -50%, 0);
  position: relative;
  bottom: -236px;
}

.defectTablecontainer table tbody tr {
  width: calc(100% + 4px);
}

.reportTablecontainer table tbody::-webkit-scrollbar-track {
  margin-bottom: 10px;
}

.noreportIconCenter {
  margin: auto;
  margin-top: 75px;
  margin-bottom: 50px;
  width: 20%;

  &.nodefect {
    margin-top: 125px;
  }

  .text_msg {
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    margin-left: -9%;
  }
}

.nofeebackIconCenter {
  margin: auto;
  margin-top: 38px;
  margin-bottom: 50px;
  width: 20%;

  &.nodefect {
    margin-top: 125px;
  }

  .text_msg {
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    margin-left: -9%;
  }
}

.reportDefectsMargin {
  margin-top: 7%;
}

.cardAlignment {
  padding-left: 0px !important;
  padding-right: 8px !important;
  padding-top: 16px !important;
}

.rowSpanedTableCell {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.downloadIconSize {
  height: 20px !important;
  width: 20px !important;
}

.reportsIconHover {
  height: 31px;
  width: 31px;
}

.pr-icon {
  padding-right: 8.75rem;
}

.dropdownList4 {
  margin-bottom: 0px !important;
  // margin-left: -60px;
  margin-left: -3px;
  margin-top: -51px;
}

.nodefect_container {
  margin-top: 80px;
  margin-bottom: 80px
}

.mobileFilter {
  width: 57%;
}

span.name-value {
  margin-left: 10px;
}

.commonDefectTable {
  font-family: "Poppins";

  .heading-tabel {
    font-family: "Poppins-SemiBold";
    font-size: 18px;
    color: #3c3838;
    margin-bottom: 10px;
    margin-bottom: 0px;
    margin-top: 7px;
  }

  .defecttablelistOutline {
    background: #ffffff;
    height: auto;
    opacity: 1;
    border-left: 1px solid #000000;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;

    .defectTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 441px;
      border-top: 1px solid #000000;
      border-bottom: 1px solid #000000;

      table {
        border-spacing: 0;

        // border-top: 1px solid #707070;
        tbody {
          max-height: 320px;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;

          :last-child {
            border-right: 0;
          }
        }

        .nodefect_data {
          padding: 50px
        }
      }
    }

    .rejectedTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 441px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      table {
        border-spacing: 0;

        // border-top: 1px solid #707070;
        tbody {
          max-height: 399px;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;

          :last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}

.text-align {
  margin-top: 10px;
}

.text-align-left {
  margin-top: 10px;
  margin-left: 10px;
}

.tableFixHead thead {
  position: sticky;
  top: 0;
  background-color: white;
}

.tab-label {
  // font-family: "Poppins-Medium";
  // color: #434db8;
  // font-size: 14px;
  font-family: "Poppins-Regular";
  color: #969696;
  font-size: 12px;
}

.tab-label.react-tabs__tab--selected {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #434db8;
  outline: none;
  padding-bottom: 5px;
  font-family: "Poppins-Regular";
  color: #434db8;
  font-size: 12px;
}

.testcaselog_search {
  margin-top: -15px;
  display: flex;
  // margin-right: -42px;
  justify-content: end;
}

// new reports designd
.reports_toggle_btn {
  position: absolute;
  right: 3%;

  .text {
    color: #3e3e3e;
    font-size: 12px;
    font-family: "Poppins-Medium";
  }
}

.reports_section {
  margin-top: 1%;
  // margin-bottom: 1%;
  height: 89vh;
  // background: #ffffff 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0000001A;
  border-radius: 16px;
  opacity: 1;
  padding: 20px;

  .tab_section {
    border-bottom: 1px solid #434db8;
    padding-left: 16px;
  }
}

.reports_card {
  height: 105px;
  border-radius: 10px;
  opacity: 1;

  .cards_icon {
    height: 30px;
    width: 43px
  }

  .cards_count {
    font-size: 32px;
    font-family: "Poppins-Semibold";
  }

  .cards_label {
    font-size: 12px;
    font-family: "Poppins-Regular";
    display: flex;
    justify-content: center;
  }

  &.defect_selected {
    background: #FB5951;
    border: 1px solid #FB5951;

    .cards_count {
      color: #FFFFFF
    }

    .cards_label {
      color: #FFFFFF
    }
  }

  &.defect_unselected {
    background: #ffffff;
    border: 1px solid #FB5951;

    .cards_count {
      color: #282828;
    }

    .cards_label {
      color: #667C8C;
    }

  }

  &.OS_selected {
    background: #F89751;
    border: 1px solid #F89751;

    .cards_count {
      color: #FFFFFF
    }

    .cards_label {
      color: #FFFFFF
    }
  }

  &.OS_unselected {
    background: #ffffff;
    border: 1px solid #F89751;

    .cards_count {
      color: #282828;
    }

    .cards_label {
      color: #667C8C;
    }

  }

  &.browser_selected {
    background: #048FFF;
    border: 1px solid #048FFF;

    .cards_count {
      color: #FFFFFF
    }

    .cards_label {
      color: #FFFFFF
    }
  }

  &.browser_unselected {
    background: #ffffff;
    border: 1px solid #048FFF;

    .cards_count {
      color: #282828;
    }

    .cards_label {
      color: #667C8C;
    }

  }

  &.device_selected {
    background: #3B36DC;
    border: 1px solid #3B36DC;

    .cards_count {
      color: #FFFFFF
    }

    .cards_label {
      color: #FFFFFF
    }
  }

  &.device_unselected {
    background: #ffffff;
    border: 1px solid #3B36DC;

    .cards_count {
      color: #282828;
    }

    .cards_label {
      color: #667C8C;
    }

  }

  &.days_selected {
    background: #DC5BEF;
    border: 1px solid #DC5BEF;

    .cards_count {
      color: #FFFFFF
    }

    .cards_label {
      color: #FFFFFF
    }
  }

  &.days_unselected {
    background: #ffffff;
    border: 1px solid #DC5BEF;

    .cards_count {
      color: #282828;
    }

    .cards_label {
      color: #667C8C;
    }

  }

  &.tester_selected {
    background: #F6B960;
    border: 1px solid #F6B960;

    .cards_count {
      color: #FFFFFF
    }

    .cards_label {
      color: #FFFFFF
    }
  }

  &.tester_unselected {
    background: #ffffff;
    border: 1px solid #F6B960;

    .cards_count {
      color: #282828;
    }

    .cards_label {
      color: #667C8C;
    }

  }
}

.reports_segemnts_container {
  height: 78vh;
  margin-top: 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #EBEBEB;
  border-radius: 4px;
  opacity: 1;
  margin-right: 2.5%;
  padding: 5px;

  .segment_header {
    font-size: 14px;
    font-family: "Poppins-Medium";
    color: #333333;
  }

  .segment_tab {
    font-size: 12px;
    font-family: "Poppins-Medium";

    .selected_tab {
      color: #1592E6;
      cursor: pointer
    }

    .unselected_tab {
      color: #A6A6A6;
      cursor: pointer
    }


  }
}

.segment-divider {
  // border-top:2px solid #EBEBEB;
  border-bottom: 2px solid #EBEBEB;

}

.client_reports_tab {
  font-size: 12px;
  font-family: "Poppins-Medium";

  &.selected_tab {
    color: #1592E6;
  }

  &.unselcted_tab {
    color: #A6A6A6;
  }
}

.commonExecutionTable {
  font-family: "Poppins";
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 0.5px solid #EBEBEB;
  border-radius: 5px;

  .heading-tabel {
    // font-family: "Poppins-SemiBold";
    // font-size: 18px;
    // color: #3c3838;
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
    margin-bottom: 0px;
    margin-top: 7px;
  }

  .defecttablelistOutline {
    height: auto;
    opacity: 1;

    .defectTablecontainer {
      opacity: 1;
      max-height: 441px;
      padding: 5px;
      margin-top: -1%;

      .execution_table_header {
        background: #F2F2F2;
      }

      table {
        border-spacing: 0;

        tbody {
          max-height: 320px;
          height: auto;
          overflow-y: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {

          :last-child {
            border-right: 0;
          }
        }

        .nodefect_data {
          padding: 50px
        }
      }
    }

    .ExecutionreportsTablecontainer {
      opacity: 1;
      max-height: 441px;
      padding: 5px;
      margin-top: -1%;

      .execution_table_header {
        background: #F2F2F2;
      }

      table {
        border-spacing: 0;

        tbody {
          max-height: 230px;
          height: auto;
          overflow-y: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {

          :last-child {
            border-right: 0;
          }
        }

        .nodefect_data {
          padding: 50px
        }
      }
    }

    .rejectedTablecontainer {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
      max-height: 441px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      table {
        border-spacing: 0;

        // border-top: 1px solid #707070;
        tbody {
          max-height: 399px;
          height: auto;

          :last-child {
            td {
              border-bottom: 0px;
            }
          }
        }

        tr {
          :last-child {
            border-right: 0px;
          }
        }

        th,
        td {
          border-bottom: 1px solid #707070;
          border-right: 1px solid #707070;

          :last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}

.execution_analytics_search {
  background: #fff;
  border-radius: 5px;
  display: flex;
  // margin-right: -43px;
  border: 1px solid #EBEBEB;
  width: 211px;

  .searchfield {
    border-radius: 5px;
    color: #707070;
    font-family: "Poppins-Regular";
    font-size: 12px;
    height: 26px;
    outline: none;
    padding-left: 4%;
    width: 183px;

  }

  .search_icon {
    padding-left: 0%;
    padding-top: 2%;

    svg {
      height: 15px;
      width: 27px;
    }
  }
}

.testcaselog_filter_btn {
  button {
    width: 82px;
    border: 1px solid #EBEBEB;
    background: #FFFFFF;
    font-size: 12px;
    color: #282828;
    font-family: "Poppins-Regular";
    border-radius: 4px;

    &:hover {
      background: #434DB8;
      border: 1px solid #434DB8;
      color: #FFFFFF;
    }
  }

}

.no_defect_reports_msg {
  display: flex;
  justify-content: center;
  font-size: 30px;
  color: #434DB8;
  font-family: "Poppins-Medium";
}

.testcase_seemore_btn {
  position: relative;
  left: 26%;
  font-size: 12px;
  font-family: "Poppins-Medium";
  color: #434DB8;
  cursor: pointer;
}